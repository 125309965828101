import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAbout } from '../../../redux/actions/aboutActions';
import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const About = () => {
  const dispatch = useDispatch();

  const aboutGet = useSelector((state) => state.aboutGet);
  const { loading, error, aboutData } = aboutGet;

  useEffect(() => {
    dispatch(getAbout());
  }, [dispatch]);

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img
              src='/assets/images/mississippi-112000_1280.jpg'
              alt='About Banner'
            />
          </div>
          <h2 className='innerpagetitle'>About Us</h2>
          <div
            className='innerpagecontent'
            dangerouslySetInnerHTML={{ __html: aboutData.pagecontent }}
          ></div>
        </>
      )}
    </div>
  );
};

export default About;
