import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  listSuccessStories,
  deleteSuccessStory,
} from '../../../redux/actions/successstoryActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const SuccessStoriesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const successStoryList = useSelector((state) => state.successStoryList);
  const { loading, error, successstories } = successStoryList;

  const successStoryDelete = useSelector((state) => state.successStoryDelete);
  const { success: successDelete } = successStoryDelete;

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteSuccessStory(id));
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listSuccessStories());
    } else {
      navigate('/admin');
    }
  }, [dispatch, navigate, successDelete, userInfo]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>Success Stories</h2>
          <Link to='/admin/successstories/add'>
            <button>
              <i className='fa fa-plus'></i> Add New Success Story
            </button>
          </Link>
        </div>
        <div className='screenlist'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>Business Name</th>
                  <th>Point of Contact</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>

              <tbody>
                {successstories.map((successstory) => (
                  <tr key={successstory._id}>
                    <>
                      <td>{successstory.businessname}</td>
                      <td>{successstory.pointofcontact}</td>
                      <td>{successstory.businessphone}</td>
                      <td>{successstory.businessemail}</td>
                      <td>
                        <Link
                          to={`/admin/successstory/${successstory._id}/edit`}
                        >
                          <button className='small'>
                            <i className='fa fa-edit'></i>
                          </button>
                        </Link>
                      </td>
                      <td>
                        <button
                          className='small'
                          onClick={() => deleteHandler(successstory._id)}
                        >
                          <i className='fa fa-trash'></i>
                        </button>
                      </td>
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessStoriesList;
