import {
  CS_DATA_REQUEST,
  CS_DATA_SUCCESS,
  CS_DATA_FAIL,
  CS_DETAILS_REQUEST,
  CS_DETAILS_SUCCESS,
  CS_DETAILS_FAIL,
  CS_DETAILS_RESET,
  CS_UPDATE_REQUEST,
  CS_UPDATE_SUCCESS,
  CS_UPDATE_FAIL,
  CS_UPDATE_RESET,
} from '../constants/cybersecurityConstants';

export const csGetReducer = (state = { csData: {} }, action) => {
  switch (action.type) {
    case CS_DATA_REQUEST:
      return { loading: true };
    case CS_DATA_SUCCESS:
      return { loading: false, csData: action.payload };
    case CS_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const csDetailsReducer = (state = { cybersecurity: {} }, action) => {
  switch (action.type) {
    case CS_DETAILS_REQUEST:
      return { ...state, loading: true };
    case CS_DETAILS_SUCCESS:
      return { loading: false, cybersecurity: action.payload };
    case CS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CS_DETAILS_RESET:
      return { cybersecurity: {} };
    default:
      return state;
  }
};

export const csUpdateReducer = (state = { cybersecurity: {} }, action) => {
  switch (action.type) {
    case CS_UPDATE_REQUEST:
      return { loading: true };
    case CS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case CS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CS_UPDATE_RESET:
      return {
        cybersecurity: {},
      };
    default:
      return state;
  }
};
