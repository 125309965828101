import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

import {
  getCNewsDetails,
  updateCNews,
} from '../../../redux/actions/contractingnewsActions';
import { CNEWS_UPDATE_RESET } from '../../../redux/constants/contractingnewsConstants';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const ContractingNewsEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const cnewsId = params.id;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const [heading, setHeading] = useState('');
  const [category, setCategory] = useState('');
  const [contractingnewscontent, setContractingNewsContent] = useState('');

  const cnewsDetails = useSelector((state) => state.cnewsDetails);
  const { loading, error, cnews } = cnewsDetails;

  const cnewsUpdate = useSelector((state) => state.cnewsUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = cnewsUpdate;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateCNews({ _id: cnewsId, category, heading, contractingnewscontent })
    );
  };

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: CNEWS_UPDATE_RESET });
      navigate('/admin/contracting-news');
    } else {
      if (cnews._id !== cnewsId) {
        dispatch(getCNewsDetails(cnewsId));
      } else {
        setCategory(cnews.category);
        setHeading(cnews.heading);
        setContractingNewsContent(cnews.contractingnewscontent);
      }
    }
  }, [dispatch, navigate, cnewsId, cnews, successUpdate]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Add Contracting News</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='dataitems'>
              <div>
                <label htmlFor='cnewscategory'>Contracting News Category</label>
                <input
                  type='text'
                  id='cnewscategory'
                  placeholder='Enter contracting news category'
                  required
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                ></input>
              </div>
              <div>
                <label htmlFor='cnewsheading'>Contracting News Title</label>
                <input
                  type='text'
                  id='cnewsheading'
                  placeholder='Enter contracting news title'
                  required
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='newscontent'>Contracting News Content</label>
                <CKEditor
                  editor={Editor}
                  data={contractingnewscontent}
                  config={EDITOR_CONFIGURATION}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContractingNewsContent(data);
                  }}
                />
              </div>

              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContractingNewsEdit;
