import AdContainer from '../../components/adcontainer/AdContainer';
import FaqList from '../../components/faqs/FaqList';

const FaqListScreen = () => {
  return (
    <AdContainer>
      <FaqList />
    </AdContainer>
  );
};

export default FaqListScreen;
