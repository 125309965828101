import ContactUs from '../components/contactus/ContactUs';

import FeContainer from '../components/fecontainer/FeContainer';

const ContactUsPage = () => {
  return (
    <FeContainer>
      <ContactUs />
    </FeContainer>
  );
};

export default ContactUsPage;
