import UpcomingEventDetails from '../components/upcomingevents/UpcomingEventDetails';

import FeContainer from '../components/fecontainer/FeContainer';

const UpcomingEventDetailsPage = () => {
  return (
    <FeContainer>
      <UpcomingEventDetails />
    </FeContainer>
  );
};

export default UpcomingEventDetailsPage;
