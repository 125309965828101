import AdContainer from '../../components/adcontainer/AdContainer';
import CustomerFeedbackArchived from '../../components/customerfeedback/CustomerFeedbackArchived';

const CustomerFeedbackArchivedScreen = () => {
  return (
    <AdContainer>
      <CustomerFeedbackArchived />
    </AdContainer>
  );
};

export default CustomerFeedbackArchivedScreen;
