import AdContainer from '../../components/adcontainer/AdContainer';
import ContractingNewsList from '../../components/news/ContractingNewsList';

const ContractingNewsListScreen = () => {
  return (
    <AdContainer>
      <ContractingNewsList />
    </AdContainer>
  );
};

export default ContractingNewsListScreen;
