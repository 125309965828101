import AdContainer from '../../components/adcontainer/AdContainer';
import NewsEdit from '../../components/news/NewsEdit';

const NewsEditScreen = () => {
  return (
    <AdContainer>
      <NewsEdit />
    </AdContainer>
  );
};

export default NewsEditScreen;
