import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import DOMPurify from 'dompurify';

import { getEventDetails } from '../../../redux/actions/eventActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './upcomingevents.css';

const UpcomingEventDetails = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const eventId = params.id;

  const eventDetails = useSelector((state) => state.eventDetails);
  const { loading, error, event } = eventDetails;

  useEffect(() => {
    if (event._id !== eventId) dispatch(getEventDetails(eventId));
  }, [dispatch, eventId]);

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img src='/assets/images/construction01.jpg' alt='About Banner' />
          </div>
          <h2 className='innerpagetitle'>Event Details</h2>
          <div className='innerpagecontent'>
            <div className='eventcontainer'>
              <div className='eventtitle'>Event Name</div>
              <div className='eventdetail'>{event.eventtitle}</div>
              <div className='eventtitle'>Event Schedule</div>
              <div className='eventdetail'>
                {' '}
                On {moment(event.starttime).format('MMMM Do YYYY, h:mm a')}
              </div>
              <div className='eventtitle'>Event Venue</div>
              <div className='eventdetail'>{event.eventvenue}</div>
              {event.addressline1 !== '' ? (
                <div className='eventdetail'>{event.addressline1}</div>
              ) : null}
              {event.addressline2 !== '' ? (
                <div className='eventdetail'>{event.addressline2}</div>
              ) : null}
              {event.addressline3 !== '' ? (
                <div className='eventdetail'>{event.addressline3}</div>
              ) : null}
              {event.addressline4 !== '' ? (
                <div className='eventdetail'>{event.addressline4}</div>
              ) : null}
              {event.state !== '' ? (
                <div className='eventdetail'>{event.state}</div>
              ) : null}
              {event.city !== '' ? (
                <div className='eventdetail'>{event.city}</div>
              ) : event.zip !== 0 ? (
                event.zip
              ) : null}

              <div className='eventtitle'>Event Cost</div>
              <div className='eventdetail'>
                {event.eventcost === 0 ? 'Free' : event.eventcost}
              </div>

              {event.contactperson !== '' ? (
                <>
                  <div className='eventtitle'>Contact Person</div>
                  <div className='eventdetail'>{event.eventtitle}</div>
                </>
              ) : null}

              {event.contactphone !== '' ? (
                <>
                  <div className='eventtitle'>Contact Phone</div>
                  <div className='eventdetail'>{event.contactphone}</div>
                </>
              ) : null}

              {event.contactemail !== '' ? (
                <>
                  <div className='eventtitle'>Contact Email</div>
                  <div className='eventdetail'>{event.contactemail}</div>
                </>
              ) : null}

              {event.eventwebsite !== '' && event.eventwebsite !== undefined ? (
                <>
                  <div className='eventtitle'>Contact Website</div>
                  <div className='eventdetail'>
                    <a
                      href={event.eventwebsite}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {event.eventwebsite}
                    </a>
                  </div>
                </>
              ) : null}

              {event.mptapcenter !== '' ? (
                <>
                  <div className='eventtitle'>APEX Center</div>
                  <div className='eventdetail'>{event.mptapcenter}</div>
                </>
              ) : null}

              {event.resource !== '' ? (
                <>
                  <div className='eventtitle'>Download Additional Details</div>
                  <div className='eventdetail'>
                    <a
                      href={event.resource}
                      target='_blank'
                      rel='noreferrer'
                      download
                    >
                      <div className='eventdetail'>
                        <button>Click to download information</button>
                      </div>
                    </a>
                  </div>
                </>
              ) : null}

              <div className='eventtitle'>Event Details</div>
              <p
                className='storydetail'
                dangerouslySetInnerHTML={{
                  __html: event.eventdetails,
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UpcomingEventDetails;
