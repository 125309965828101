import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

import {
  getServices,
  updateServices,
} from '../../../redux/actions/servicesActions';

import { logout } from '../../../redux/actions/userActions';

import { SERVICES_UPDATE_RESET } from '../../../redux/constants/servicesConstants';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const Services = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pagetitle, setPageTitle] = useState('');
  const [pagecontent, setPageContent] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const servicesGet = useSelector((state) => state.servicesGet);
  const { loading, error, servicesData } = servicesGet;

  const servicesUpdate = useSelector((state) => state.servicesUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = servicesUpdate;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateServices({ pagetitle, pagecontent }));
  };

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: SERVICES_UPDATE_RESET });
      navigate('/admin/screens');
    } else {
      if (
        typeof servicesData !== 'undefined' &&
        Object.keys(servicesData).length === 0
      ) {
        dispatch(getServices());
      }

      if (
        typeof servicesData !== 'undefined' &&
        Object.keys(servicesData).length > 0
      ) {
        setPageTitle(servicesData.pagetitle);
        setPageContent(servicesData.pagecontent);
      }
    }
  }, [dispatch, navigate, servicesData, successUpdate]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Edit Services</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='dataitems'>
              <div>
                <label htmlFor='pagetitle'>Page Title</label>
                <input
                  type='text'
                  id='pagetitle'
                  placeholder='Enter page title'
                  required
                  value={pagetitle}
                  onChange={(e) => setPageTitle(e.target.value)}
                ></input>
              </div>
              <div>
                <label htmlFor='pagecontent'>Page Content</label>
                <CKEditor
                  editor={Editor}
                  data={pagecontent}
                  config={EDITOR_CONFIGURATION}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setPageContent(data);
                  }}
                />
              </div>
              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Services;
