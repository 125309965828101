export const TR_ADD_REQUEST = 'TR_ADD_REQUEST';
export const TR_ADD_SUCCESS = 'TR_ADD_SUCCESS';
export const TR_ADD_FAIL = 'TR_ADD_FAIL';
export const TR_ADD_RESET = 'TR_ADD_RESET';

export const TR_LIST_REQUEST = 'TR_LIST_REQUEST';
export const TR_LIST_SUCCESS = 'TR_LIST_SUCCESS';
export const TR_LIST_FAIL = 'TR_LIST_FAIL';
export const TR_LIST_RESET = 'TR_LIST_RESET';

export const TR_DETAILS_REQUEST = 'TR_DETAILS_REQUEST';
export const TR_DETAILS_SUCCESS = 'TR_DETAILS_SUCCESS';
export const TR_DETAILS_FAIL = 'TR_DETAILS_FAIL';
export const TR_DETAILS_RESET = 'TR_DETAILS_RESET';

export const TR_UPDATE_REQUEST = 'TR_UPDATE_REQUEST';
export const TR_UPDATE_SUCCESS = 'TR_UPDATE_SUCCESS';
export const TR_UPDATE_FAIL = 'TR_UPDATE_FAIL';
export const TR_UPDATE_RESET = 'TR_UPDATE_RESET';

export const TR_DELETE_REQUEST = 'TR_DELETE_REQUEST';
export const TR_DELETE_SUCCESS = 'TR_DELETE_SUCCESS';
export const TR_DELETE_FAIL = 'TR_DELETE_FAIL';
