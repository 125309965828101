export const LIBRARY_ADD_REQUEST = 'LIBRARY_ADD_REQUEST';
export const LIBRARY_ADD_SUCCESS = 'LIBRARY_ADD_SUCCESS';
export const LIBRARY_ADD_FAIL = 'LIBRARY_ADD_FAIL';
export const LIBRARY_ADD_RESET = 'LIBRARY_ADD_RESET';
export const LIBRARY_LIST_REQUEST = 'LIBRARY_LIST_REQUEST';
export const LIBRARY_LIST_SUCCESS = 'LIBRARY_LIST_SUCCESS';
export const LIBRARY_LIST_FAIL = 'LIBRARY_LIST_FAIL';
export const LIBRARY_LIST_RESET = 'LIBRARY_LIST_RESET';
export const LIBRARY_DETAILS_REQUEST = 'LIBRARY_DETAILS_REQUEST';
export const LIBRARY_DETAILS_SUCCESS = 'LIBRARY_DETAILS_SUCCESS';
export const LIBRARY_DETAILS_FAIL = 'LIBRARY_DETAILS_FAIL';
export const LIBRARY_DETAILS_RESET = 'LIBRARY_DETAILS_RESET';
export const LIBRARY_UPDATE_REQUEST = 'LIBRARY_UPDATE_REQUEST';
export const LIBRARY_UPDATE_SUCCESS = 'LIBRARY_UPDATE_SUCCESS';
export const LIBRARY_UPDATE_FAIL = 'LIBRARY_UPDATE_FAIL';
export const LIBRARY_UPDATE_RESET = 'LIBRARY_UPDATE_RESET';
export const LIBRARY_DELETE_REQUEST = 'LIBRARY_DELETE_REQUEST';
export const LIBRARY_DELETE_SUCCESS = 'LIBRARY_DELETE_SUCCESS';
export const LIBRARY_DELETE_FAIL = 'LIBRARY_DELETE_FAIL';
