import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DOMPurify from 'dompurify';

import { getHomePage } from '../../../redux/actions/homepageActions';
import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import MainBanner from './mainbanner/MainBanner';

import './home.css';
import SamGov from './samgov/SamGov';
import MscpcOffices from './mscpcoffices/MscpcOffices';

const Home = () => {
  const dispatch = useDispatch();

  const homepageGet = useSelector((state) => state.homepageGet);
  const { loading, error, homepageData } = homepageGet;

  useEffect(() => {
    dispatch(getHomePage());
  }, [dispatch]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <MainBanner />
          <div className='maingrid'>
            <div className='aboutshort'>
              <div className='sectiontitle'>
                <h2>
                  Mississippi Procurement Technical Assistance Program (MPTAP)
                  Is Now APEX
                </h2>
              </div>
              <div>
                <p
                  className='aboutshort-content'
                  dangerouslySetInnerHTML={{
                    __html: homepageData.aboutsummary,
                  }}
                ></p>
                <button className='btn'>
                  <a
                    href='https://youtu.be/_mX88Sr18x8?t=63'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Learn More
                  </a>
                </button>
              </div>

              <div className='aboutshort-images'>
                <div className='aboutshort-imageone'>
                  <img src='assets/images/business-assistance.jpg' alt='' />
                  <div className='aboutshort-imagecaption'>
                    <Link to='/services'>
                      <p>Services</p>
                    </Link>
                  </div>
                </div>
                <div className='aboutshort-imagetwo'>
                  <img src='assets/images/workshop-event.jpg' alt='' />
                  <div className='aboutshort-imagecaption'>
                    <Link to='/upcoming-events'>
                      <p>Upcoming Events</p>
                    </Link>
                  </div>
                </div>

                <div className='aboutshort-imagethree'>
                  <img src='assets/images/compliance-03-min.jpg' alt='' />
                  <div className='aboutshort-imagecaption'>
                    <a
                      href='https://www.dcsa.mil/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <p>FOCI Compliance</p>
                    </a>
                  </div>
                </div>

                <div className='aboutshort-imagefour'>
                  <img src='assets/images/cybersecurity-vpn.jpg' alt='' />
                  <div className='aboutshort-imagecaption'>
                    <Link to='/cybersecurity'>
                      <p>Small Business Cybersecurity</p>
                    </Link>
                  </div>
                </div>

                <div className='aboutshort-imagefive'>
                  <img src='assets/images/dpat3.jpg' alt='' />
                  <div className='aboutshort-imagecaption'>
                    <a
                      href='https://www.businessdefense.gov'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <p>Defense Production Act Title III</p>
                    </a>
                  </div>
                </div>

                <div className='aboutshort-imagesix'>
                  <img src='assets/images/boardroom-meeting.jpg' alt='' />
                  <div className='aboutshort-imagecaption'>
                    <Link to='/bid-opportunities'>
                      <p>Bid Opportunities</p>
                    </Link>
                  </div>
                </div>
                <div className='aboutshort-imageseven'>
                  <img src='assets/images/application-form.jpeg' alt='' />
                  <div className='aboutshort-imagecaption'>
                    <Link to='/new-client-application'>
                      <p>New Client Application</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SamGov />

          <div className='maingrid'>
            <div className='naepanel'>
              <div className='naetitle'>
                <h2>News and upcoming event</h2>
              </div>
              <div className='newsandevents'>
                <div className='news'>
                  <h3 className='newstitle'>News</h3>
                  <div className='newscontent'>
                    <div className='title'>
                      <h3>{homepageData.latestnewsheading}</h3>
                    </div>
                    <p>
                      {moment(homepageData.latestnewsdate).format(
                        'MMMM Do YYYY'
                      )}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          homepageData.latestnewscontent
                        )
                          .split(' ')
                          .slice(0, 50)
                          .join(' '),
                      }}
                    ></p>
                    <div className='morebuttons'>
                      <div className='buttonleft'>
                        <Link to={`/news/${homepageData.latestnewsid}`}>
                          <button>More details</button>
                        </Link>
                      </div>
                      <div className='buttonright'>
                        <Link to='/news'>
                          <button>All News</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='contractingnews'>
                  <h3 className='contractingnewstitle'>Contracting News</h3>
                  <div className='contractingnewscontent'>
                    <div className='title'>
                      <h3>{homepageData.latestcontractingnewsheading}</h3>
                    </div>
                    <p>
                      {moment(homepageData.latestnewsdate).format(
                        'MMMM Do YYYY'
                      )}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          homepageData.latestcontractingnewscontent
                        )
                          .split(' ')
                          .slice(0, 50)
                          .join(' '),
                      }}
                    ></p>
                    <div className='morebuttons'>
                      <div className='buttonleft'>
                        <Link
                          to={`/contracting-news/${homepageData.latestcontractingnewsid}`}
                        >
                          <button>More details</button>
                        </Link>
                      </div>
                      <div className='buttonright'>
                        <Link to='/contracting-news'>
                          <button>All Contracting News</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='upcomingevent'>
                  <h3 className='upcomingeventtitle'>Upcoming Event</h3>
                  <div className='upcomingeventcontent'>
                    <div className='title'>
                      <h3>{homepageData.eventtitle}</h3>
                    </div>
                    <p>
                      {moment(homepageData.eventstarttime).format(
                        'MMMM Do YYYY, h:mm a'
                      )}
                    </p>
                    <p>
                      Cost:{' '}
                      {homepageData.eventcost === '0'
                        ? 'Free'
                        : homepageData.eventcost}
                    </p>

                    <p>Venue: {homepageData.eventvenue}</p>

                    <div className='morebuttons'>
                      <div className='buttonleft'>
                        <Link
                          to={`/upcoming-event-details/${homepageData.eventid}`}
                        >
                          <button>More details</button>
                        </Link>
                      </div>
                      <div className='buttonright'>
                        <Link to='/upcoming-events'>
                          <button>All Events</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <MscpcOffices />
        </>
      )}
    </div>
  );
};

export default Home;
