import AdContainer from '../../components/adcontainer/AdContainer';
import SuccessStoriesAdd from '../../components/successstories/SuccessStoriesAdd';

const SuccessStoriesAddScreen = () => {
  return (
    <AdContainer>
      <SuccessStoriesAdd />
    </AdContainer>
  );
};

export default SuccessStoriesAddScreen;
