import Services from '../components/services/Services';

import FeContainer from '../components/fecontainer/FeContainer';

const ServicesPage = () => {
  return (
    <FeContainer>
      <Services />
    </FeContainer>
  );
};

export default ServicesPage;
