export const ABOUT_DATA_REQUEST = 'ABOUT_DATA_REQUEST';
export const ABOUT_DATA_SUCCESS = 'ABOUT_DATA_SUCCESS';
export const ABOUT_DATA_FAIL = 'ABOUT_DATA_FAIL';

export const ABOUT_DETAILS_SUCCESS = 'ABOUT_DETAILS_SUCCESS';
export const ABOUT_DETAILS_REQUEST = 'ABOUT_DETAILS_REQUEST';
export const ABOUT_DETAILS_FAIL = 'ABOUT_DETAILS_FAIL';
export const ABOUT_DETAILS_RESET = 'ABOUT_DETAILS_RESET';

export const ABOUT_UPDATE_REQUEST = 'ABOUT_UPDATE_REQUEST';
export const ABOUT_UPDATE_SUCCESS = 'ABOUT_UPDATE_SUCCESS';
export const ABOUT_UPDATE_FAIL = 'ABOUT_UPDATE_FAIL';
export const ABOUT_UPDATE_RESET = 'ABOUT_UPDATE_RESET';
