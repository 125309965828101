import FindUs from '../components/findus/FindUs';

import FeContainer from '../components/fecontainer/FeContainer';

const FindUsPage = () => {
  return (
    <FeContainer>
      <FindUs />
    </FeContainer>
  );
};

export default FindUsPage;
