import AdContainer from '../../components/adcontainer/AdContainer';
// import BidOpportunitiesAdd from '../../components/bidopportunities/BidOpportunitesAdd';
import BidOpportunitiesAdd from '../../components/bidopportunities/BidOpportunitiesAdd';

const BidOpportunitiesAddScreen = () => {
  return (
    <AdContainer>
      <BidOpportunitiesAdd />
    </AdContainer>
  );
};

export default BidOpportunitiesAddScreen;
