import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

import { getFaqDetails, updateFaq } from '../../../redux/actions/faqActions';
import { FAQ_UPDATE_RESET } from '../../../redux/constants/faqConstants';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const FaqEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const faqId = params.id;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const [thequery, setThequery] = useState('');
  const [theresponse, setTheresponse] = useState('');

  const faqDetails = useSelector((state) => state.faqDetails);
  const { loading, error, faq } = faqDetails;

  const faqUpdate = useSelector((state) => state.faqUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = faqUpdate;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateFaq({ _id: faqId, thequery, theresponse }));
  };

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: FAQ_UPDATE_RESET });
      navigate('/admin/faqs');
    } else {
      if (faq._id !== faqId) {
        dispatch(getFaqDetails(faqId));
      } else {
        setThequery(faq.thequery);
        setTheresponse(faq.theresponse);
      }
    }
  }, [dispatch, navigate, faqId, faq, successUpdate]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Edit FAQ</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='dataitems'>
              <div>
                <label htmlFor='thequery'>The Query</label>
                <input
                  type='text'
                  id='thequery'
                  placeholder='Enter New Query'
                  required
                  value={thequery}
                  onChange={(e) => setThequery(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='theresponse'>The Response</label>
                <CKEditor
                  editor={Editor}
                  data={theresponse}
                  config={EDITOR_CONFIGURATION}
                  onChange={(event, editor) => {
                    const responsedata = editor.getData();
                    setTheresponse(responsedata);
                  }}
                />
              </div>

              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default FaqEdit;
