import './mainbanner.css';
const MainBanner = () => {
  return (
    <div className='maingrid'>
      <div className='mainbanner'>
        <div className='bannerimage'>
          <img
            src='assets/images/usflagandhelicopter.jpg'
            alt='MSCPC Home Page Banner'
          />
        </div>

        <div className='slogans'>
          <div className='sloganone'>
            <h1>
              Connecting Mississippi small businesses with millions of dollars
              in Government Procurement Contracts since 1998
            </h1>
            <button className='btn'>
              <a href='/new-client-application'>Connect Now</a>
            </button>
          </div>

          <div className='slogantwo'>
            <h1>
              Mississippi Businesses won more than 20,000 Government Contracts -
              worth $900 million - in 2020 with Mississippi Development
              Authority PTAC help
            </h1>
            <button className='btn'>
              <a href='/faq'>MS APEX Accelerators FAQs.</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
