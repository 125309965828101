import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import moment from 'moment';

import { listSuccessStories } from '../../../redux/actions/successstoryActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './successstories.css';

const SuccessStories = () => {
  const dispatch = useDispatch();

  const successStoryList = useSelector((state) => state.successStoryList);
  const { loading, error, successstories } = successStoryList;

  useEffect(() => {
    dispatch(listSuccessStories());
  }, [dispatch]);

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img src='/assets/images/success02a.jpg' alt='About Banner' />
          </div>
          <h2 className='innerpagetitle'>Success Stories</h2>
          <div className='innerpagecontent'>
            <Link to='/success-stories/success-story-new'>
              <button className='successStoryButton'>
                <i className='fa fa-plus'></i> Add Your Own Success Story
              </button>
            </Link>
            <>
              {successstories.map((successstory) => (
                <div key={successstory._id}>
                  {successstory.authorizedtopublish &&
                  successstory.showsuccessstory ? (
                    <div className='storycontainer'>
                      <h3>Business Name </h3>
                      <p className='storydetail'>{successstory.businessname}</p>
                      <h3>Contract Details</h3>
                      <p className='storydetail'>{successstory.businessname}</p>
                      <h3>Issues Faced</h3>
                      <p
                        className='storydetail'
                        dangerouslySetInnerHTML={{
                          __html: successstory.issuedescription,
                        }}
                      />
                      <h3>Date of Submission</h3>
                      <p className='storydetail'>
                        {moment(successstory.createdAt).format('MMMM Do YYYY')}
                      </p>
                    </div>
                  ) : null}
                </div>
              ))}
            </>
          </div>
        </>
      )}
    </div>
  );
};

export default SuccessStories;
