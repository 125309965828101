import axios from 'axios';
import {
  FEEDBACK_ADD_REQUEST,
  FEEDBACK_ADD_SUCCESS,
  FEEDBACK_ADD_FAIL,
  NEW_FEEDBACK_LIST_REQUEST,
  NEW_FEEDBACK_LIST_SUCCESS,
  NEW_FEEDBACK_LIST_FAIL,
  ARCHIVED_FEEDBACK_LIST_REQUEST,
  ARCHIVED_FEEDBACK_LIST_SUCCESS,
  ARCHIVED_FEEDBACK_LIST_FAIL,
  FEEDBACK_DETAILS_REQUEST,
  FEEDBACK_DETAILS_SUCCESS,
  FEEDBACK_DETAILS_FAIL,
  FEEDBACK_UPDATE_REQUEST,
  FEEDBACK_UPDATE_SUCCESS,
  FEEDBACK_UPDATE_FAIL,
} from '../constants/customerfeedbackConstants';
import { logout } from './userActions';

export const addFeedback =
  (
    businessname,
    pointofcontact,
    businessaddress,
    businesscity,
    businessstate,
    businesszip,
    businessphone,
    businessfax,
    businessemail,
    mptapassisted,
    mptapcounsellorsresponsive,
    mptapcounsellorsavailable,
    mptapcomments,
    websitevaluable,
    websitelogical,
    websiteavailable,
    websitecomments,
    seminarinstrumental,
    seminargoodtiming,
    seminarcomments,
    mptapbidmatchinguseful,
    mptapbidmatchingtailored,
    mptapbidmatchinggivetime,
    mptapbidmatchingcomments,
    servicerating,
    overallcomments,
    archive
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: FEEDBACK_ADD_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        '/api/customer-feedback',
        {
          businessname,
          pointofcontact,
          businessaddress,
          businesscity,
          businessstate,
          businesszip,
          businessphone,
          businessfax,
          businessemail,
          mptapassisted,
          mptapcounsellorsresponsive,
          mptapcounsellorsavailable,
          mptapcomments,
          websitevaluable,
          websitelogical,
          websiteavailable,
          websitecomments,
          seminarinstrumental,
          seminargoodtiming,
          seminarcomments,
          mptapbidmatchinguseful,
          mptapbidmatchingtailored,
          mptapbidmatchinggivetime,
          mptapbidmatchingcomments,
          servicerating,
          overallcomments,
          archive,
        },
        config
      );

      dispatch({
        type: FEEDBACK_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: FEEDBACK_ADD_FAIL,
        payload: message,
      });
    }
  };

export const listNewFeedbacks = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEW_FEEDBACK_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      '/api/customer-feedback/feedback/new',
      config
    );

    dispatch({
      type: NEW_FEEDBACK_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: NEW_FEEDBACK_LIST_FAIL,
      payload: message,
    });
  }
};

export const listArchivedFeedbacks = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ARCHIVED_FEEDBACK_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      '/api/customer-feedback/feedback/archived',
      config
    );

    dispatch({
      type: ARCHIVED_FEEDBACK_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: ARCHIVED_FEEDBACK_LIST_FAIL,
      payload: message,
    });
  }
};

export const getFeedbackDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEEDBACK_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/customer-feedback/${id}`, config);

    dispatch({
      type: FEEDBACK_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: FEEDBACK_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateFeedback = (contact) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEEDBACK_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/customer-feedback/${contact._id}`,
      contact,
      config
    );

    dispatch({
      type: FEEDBACK_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: FEEDBACK_UPDATE_FAIL,
      payload: message,
    });
  }
};
