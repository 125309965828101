import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { listNews } from '../../../redux/actions/newsActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './news.css';

const News = () => {
  const dispatch = useDispatch();

  const newsList = useSelector((state) => state.newsList);
  const { loading, error, news } = newsList;

  useEffect(() => {
    dispatch(listNews());
  }, []);

  let sno = 0;

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img src='/assets/images/news.jpg' alt='About Banner' />
          </div>
          <h2 className='innerpagetitle'>News</h2>

          <div className='innerpagecontent'>
            {news.map((newsItem) => (
              <div className='thenews' key={newsItem._id}>
                <div className='thenewsitem'>
                  <div className='thesno'>{(sno += 1)}.</div>
                  <div className='thenewsheading'>
                    <Link to={`/news/${newsItem._id}`}>
                      {newsItem.heading} - dated{' '}
                      {moment(newsItem.createdAt).format('MMMM Do YYYY')}.
                    </Link>
                  </div>
                </div>
                <div
                  className='thenewscontent'
                  dangerouslySetInnerHTML={{
                    __html: newsItem.newscontent
                      .split(' ')
                      .slice(0, 50)
                      .join(' '),
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default News;
