import {
  SERVICES_DATA_REQUEST,
  SERVICES_DATA_SUCCESS,
  SERVICES_DATA_FAIL,
  SERVICES_DETAILS_REQUEST,
  SERVICES_DETAILS_SUCCESS,
  SERVICES_DETAILS_FAIL,
  SERVICES_DETAILS_RESET,
  SERVICES_UPDATE_REQUEST,
  SERVICES_UPDATE_SUCCESS,
  SERVICES_UPDATE_FAIL,
  SERVICES_UPDATE_RESET,
} from '../constants/servicesConstants';

export const servicesGetReducer = (state = { servicesData: {} }, action) => {
  switch (action.type) {
    case SERVICES_DATA_REQUEST:
      return { loading: true };
    case SERVICES_DATA_SUCCESS:
      return { loading: false, servicesData: action.payload };
    case SERVICES_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const servicesDetailsReducer = (state = { services: {} }, action) => {
  switch (action.type) {
    case SERVICES_DETAILS_REQUEST:
      return { ...state, loading: true };
    case SERVICES_DETAILS_SUCCESS:
      return { loading: false, services: action.payload };
    case SERVICES_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case SERVICES_DETAILS_RESET:
      return { services: {} };
    default:
      return state;
  }
};

export const servicesUpdateReducer = (state = { services: {} }, action) => {
  switch (action.type) {
    case SERVICES_UPDATE_REQUEST:
      return { loading: true };
    case SERVICES_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case SERVICES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SERVICES_UPDATE_RESET:
      return {
        services: {},
      };
    default:
      return state;
  }
};
