export const CNEWS_ADD_REQUEST = 'CNEWS_ADD_REQUEST';
export const CNEWS_ADD_SUCCESS = 'CNEWS_ADD_SUCCESS';
export const CNEWS_ADD_FAIL = 'CNEWS_ADD_FAIL';
export const CNEWS_ADD_RESET = 'CNEWS_ADD_RESET';

export const CNEWS_LIST_REQUEST = 'CNEWS_LIST_REQUEST';
export const CNEWS_LIST_SUCCESS = 'CNEWS_LIST_SUCCESS';
export const CNEWS_LIST_FAIL = 'CNEWS_LIST_FAIL';
export const CNEWS_LIST_RESET = 'CNEWS_LIST_RESET';

export const CNEWS_DETAILS_REQUEST = 'CNEWS_DETAILS_REQUEST';
export const CNEWS_DETAILS_SUCCESS = 'CNEWS_DETAILS_SUCCESS';
export const CNEWS_DETAILS_FAIL = 'CNEWS_DETAILS_FAIL';
export const CNEWS_DETAILS_RESET = 'CNEWS_DETAILS_RESET';

export const CNEWS_UPDATE_REQUEST = 'CNEWS_UPDATE_REQUEST';
export const CNEWS_UPDATE_SUCCESS = 'CNEWS_UPDATE_SUCCESS';
export const CNEWS_UPDATE_FAIL = 'CNEWS_UPDATE_FAIL';
export const CNEWS_UPDATE_RESET = 'CNEWS_UPDATE_RESET';

export const CNEWS_DELETE_REQUEST = 'CNEWS_DELETE_REQUEST';
export const CNEWS_DELETE_SUCCESS = 'CNEWS_DELETE_SUCCESS';
export const CNEWS_DELETE_FAIL = 'CNEWS_DELETE_FAIL';
