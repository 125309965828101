import axios from 'axios';
import {
  ABOUT_DATA_REQUEST,
  ABOUT_DATA_SUCCESS,
  ABOUT_DATA_FAIL,
  ABOUT_DETAILS_REQUEST,
  ABOUT_DETAILS_SUCCESS,
  ABOUT_DETAILS_FAIL,
  ABOUT_UPDATE_REQUEST,
  ABOUT_UPDATE_SUCCESS,
  ABOUT_UPDATE_FAIL,
} from '../constants/aboutConstants';

import { logout } from './userActions';

export const getAbout = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ABOUT_DATA_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/about`, config);

    dispatch({
      type: ABOUT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: ABOUT_DATA_FAIL,
      payload: message,
    });
  }
};

export const getAboutDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ABOUT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/about', config);

    dispatch({
      type: ABOUT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: ABOUT_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateAbout = (about) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ABOUT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put('/api/about', about, config);

    dispatch({ type: ABOUT_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: ABOUT_UPDATE_FAIL,
      payload: message,
    });
  }
};
