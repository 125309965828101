export const CONTACT_ADD_REQUEST = 'CONTACT_ADD_REQUEST';
export const CONTACT_ADD_SUCCESS = 'CONTACT_ADD_SUCCESS';
export const CONTACT_ADD_FAIL = 'CONTACT_ADD_FAIL';
export const CONTACT_ADD_RESET = 'CONTACT_ADD_RESET';

export const NEW_CONTACT_LIST_REQUEST = 'NEW_CONTACT_LIST_REQUEST';
export const NEW_CONTACT_LIST_SUCCESS = 'NEW_CONTACT_LIST_SUCCESS';
export const NEW_CONTACT_LIST_FAIL = 'NEW_CONTACT_LIST_FAIL';
export const NEW_CONTACT_LIST_RESET = 'NEW_CONTACT_LIST_RESET';

export const ARCHIVED_CONTACT_LIST_REQUEST = 'ARCHIVED_CONTACT_LIST_REQUEST';
export const ARCHIVED_CONTACT_LIST_SUCCESS = 'ARCHIVED_CONTACT_LIST_SUCCESS';
export const ARCHIVED_CONTACT_LIST_FAIL = 'ARCHIVED_CONTACT_LIST_FAIL';
export const ARCHIVED_CONTACT_LIST_RESET = 'ARCHIVED_CONTACT_LIST_RESET';

export const CONTACT_DETAILS_REQUEST = 'CONTACT_DETAILS_REQUEST';
export const CONTACT_DETAILS_SUCCESS = 'CONTACT_DETAILS_SUCCESS';
export const CONTACT_DETAILS_FAIL = 'CONTACT_DETAILS_FAIL';
export const CONTACT_DETAILS_RESET = 'CONTACT_DETAILS_RESET';

export const CONTACT_UPDATE_REQUEST = 'CONTACT_UPDATE_REQUEST';
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_FAIL = 'CONTACT_UPDATE_FAIL';
export const CONTACT_UPDATE_RESET = 'CONTACT_UPDATE_RESET';
