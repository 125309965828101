export const CS_DATA_REQUEST = 'CS_DATA_REQUEST';
export const CS_DATA_SUCCESS = 'CS_DATA_SUCCESS';
export const CS_DATA_FAIL = 'CS_DATA_FAIL';

export const CS_DETAILS_SUCCESS = 'CS_DETAILS_SUCCESS';
export const CS_DETAILS_REQUEST = 'CS_DETAILS_REQUEST';
export const CS_DETAILS_FAIL = 'CS_DETAILS_FAIL';
export const CS_DETAILS_RESET = 'CS_DETAILS_RESET';

export const CS_UPDATE_REQUEST = 'CS_UPDATE_REQUEST';
export const CS_UPDATE_SUCCESS = 'CS_UPDATE_SUCCESS';
export const CS_UPDATE_FAIL = 'CS_UPDATE_FAIL';
export const CS_UPDATE_RESET = 'CS_UPDATE_RESET';
