import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

import { addNews } from '../../../redux/actions/newsActions';
import { NEWS_ADD_RESET } from '../../../redux/constants/newsConstants';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const NewsAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const [heading, setHeading] = useState('');
  const [newscontent, setNewsContent] = useState('');

  const newsAdd = useSelector((state) => state.newsAdd);
  const { loading, error, newNews } = newsAdd;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addNews(heading, newscontent));
  };

  useEffect(() => {
    dispatch({ type: NEWS_ADD_RESET });
    if (newNews) {
      navigate('/admin/news');
    }
  }, [dispatch, navigate, newNews]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Add New News Item</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='dataitems'>
              <div>
                <label htmlFor='newsheading'>News Title</label>
                <input
                  type='text'
                  id='newsheading'
                  placeholder='Enter News Title'
                  required
                  onChange={(e) => setHeading(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='newscontent'>News Content</label>
                <CKEditor
                  editor={Editor}
                  data={newscontent}
                  config={EDITOR_CONFIGURATION}
                  onChange={(event, editor) => {
                    const responsedata = editor.getData();
                    setNewsContent(responsedata);
                  }}
                />
              </div>

              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default NewsAdd;
