import {
  LIBRARY_ADD_REQUEST,
  LIBRARY_ADD_SUCCESS,
  LIBRARY_ADD_FAIL,
  LIBRARY_ADD_RESET,
  LIBRARY_LIST_REQUEST,
  LIBRARY_LIST_SUCCESS,
  LIBRARY_LIST_FAIL,
  LIBRARY_DETAILS_REQUEST,
  LIBRARY_DETAILS_SUCCESS,
  LIBRARY_DETAILS_FAIL,
  LIBRARY_DETAILS_RESET,
  LIBRARY_UPDATE_REQUEST,
  LIBRARY_UPDATE_SUCCESS,
  LIBRARY_UPDATE_FAIL,
  LIBRARY_UPDATE_RESET,
  LIBRARY_DELETE_REQUEST,
  LIBRARY_DELETE_SUCCESS,
  LIBRARY_DELETE_FAIL,
} from '../constants/libraryConstants';

export const libraryAddReducer = (state = {}, action) => {
  switch (action.type) {
    case LIBRARY_ADD_REQUEST:
      return { loading: true };
    case LIBRARY_ADD_SUCCESS:
      return { loading: false, newLibraryItem: action.payload };
    case LIBRARY_ADD_FAIL:
      return { loading: false, error: action.payload };
    case LIBRARY_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const libraryListReducer = (state = { libraryitems: [] }, action) => {
  switch (action.type) {
    case LIBRARY_LIST_REQUEST:
      return { loading: true };
    case LIBRARY_LIST_SUCCESS:
      return { loading: false, libraryitems: action.payload };
    case LIBRARY_LIST_FAIL:
      return { loading: false, error: action.payload };
    // case TR_LIST_RESET:
    //   return { trs: [] };
    default:
      return state;
  }
};

export const libraryitemUpdateReducer = (
  state = { libraryitem: {} },
  action
) => {
  switch (action.type) {
    case LIBRARY_UPDATE_REQUEST:
      return { loading: true };
    case LIBRARY_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case LIBRARY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case LIBRARY_UPDATE_RESET:
      return {
        libraryitem: {},
      };
    default:
      return state;
  }
};

export const libraryitemDetailsReducer = (
  state = { libraryitem: {} },
  action
) => {
  switch (action.type) {
    case LIBRARY_DETAILS_REQUEST:
      return { ...state, loading: true };
    case LIBRARY_DETAILS_SUCCESS:
      return { loading: false, tr: action.payload };
    case LIBRARY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case LIBRARY_DETAILS_RESET:
      return { libraryitem: {} };
    default:
      return state;
  }
};

export const libraryitemDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LIBRARY_DELETE_REQUEST:
      return { loading: true };
    case LIBRARY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case LIBRARY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
