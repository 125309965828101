import AdContainer from '../../components/adcontainer/AdContainer';
import TechnicalResourcesAdd from '../../components/technicalresources/TechnicalResourcesAdd';

const TechnicalResourcesAddScreen = () => {
  return (
    <AdContainer>
      <TechnicalResourcesAdd />
    </AdContainer>
  );
};

export default TechnicalResourcesAddScreen;
