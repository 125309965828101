import Faq from '../components/faq/Faq';

import FeContainer from '../components/fecontainer/FeContainer';

const FAQPage = () => {
  return (
    <FeContainer>
      <Faq />
    </FeContainer>
  );
};

export default FAQPage;
