import { useState } from 'react';
import './newclientapplication.css';

const NewClientApplication = () => {
  const [thelink, setThelink] = useState('');

  return (
    <div className='maingrid'>
      <>
        <div className='innerbannerimage'>
          <img
            src='/assets/images/new-client-application.jpg'
            alt='About Banner'
          />
        </div>
        <h2 className='innerpagetitle'>New Client Application</h2>
        <div className='innerpagecontent'>
          <p className='nca'>
            Please complete the form below to apply for services rendered by the
            Mississippi Procurement Technical Assistance Program. A program
            representative will contact you to complete the registration
            process.
          </p>
          <p className='nca'>
            These services are provided to MISSISSIPPI businesses only and are
            provided at no charge. Please note that the Mississippi Procurement
            Technical Assistance Program advisors and counselors are prohibited
            from: (1) soliciting or accepting a fee from any source for services
            rendered; (2) recommending the purchase of goods and services from
            sources in which they have an interest or represent; (3) accepting
            fees or commissions from third parties which have supplied goods or
            services on the recommendation of the advisors and counselors; and
            (4) recommending a specific value added network (VAN).
          </p>
          <p className='nca'>
            Before your application can be completed, you must agree to:
          </p>
          <p className='nca'>
            (1) Report quarterly the number and dollar value of contracts the
            Mississippi Procurement Technical Assistance Program assisted you in
            receiving. Such assistance includes but is not limited to bid
            matching, providing technical assistance with registrations and
            certifications, obtaining technical documents, assisting with bid
            proposal preparations ,etc; and{' '}
          </p>

          <p className='nca'>
            (2) Waive all claims rising therefrom against the Mississippi
            Procurement Technical Assistance Program, it’s Directors, Officers,
            Counselors and Advisors.
          </p>

          <div className='nca-site-link'>
            <a
              className='gobutton'
              // href={thelink}
              href='https://mscpc.ecenterdirect.com/'
              target='_blank'
              rel='noreferrer'
            >
              Click here to submit your application
              {/* Please select your region as per following to fill application: */}
            </a>
          </div>
          {/*
          <h3 className='nca'>
            Central Mississippi Procurement Office includes following counties:
          </h3> */}

          {/* <p className='nca'>
            Adams, Claiborne, Copiah, Franklin, Hinds, Jefferson,
            Jefferson-Davis, Lawrence, Lincoln, Madison, Rankin, Simpson,
            Warren, Yazoo
          </p> */}

          {/* <h3 className='nca'>
            NE MS Contract Procurement Office includes following counties:
          </h3> */}

          {/* <p className='nca'>
            Alcorn, Attala, Benton, Calhoun, Chickasaw, Chocktaw, Clay,
            Itawamba, Lafayette, Lee, Lowndes, Marshall, Monroe, Montgomery,
            Noxubee, Oktibbeha, Pontotoc, Prentiss, Tippah, Tishomingo, Union,
            Webster, Winston
          </p> */}

          {/* <h3 className='nca'>
            South Mississippi Contract Procurement Office includes following
            counties:
          </h3> */}

          {/* <p className='nca'>
            Amite, Forrest, George, Greene, Hancock, Harrison, Jackson, Lamar,
            Marion, Pearl River, Perry, Pike, Stone, Walthall, Wilkinson
          </p> */}

          {/* <h3 className='nca'>
            Northwest MS Contract Procurement Office includes following
            counties:
          </h3> */}

          {/* <p className='nca'>
            Bolivar, Carroll, Coahoma, DeSoto, Grenada, Holmes, Humphreys,
            Issequena, LeFlore, Panola, Quitman, Sharkey, Sunflower,
            Tallahatchie, Tate, Tunica, Washington, Yalobusha
          </p>

          <h3 className='nca'>
            East Central Contract Procurement Office includes following
            counties:
          </h3> */}

          {/* <p className='nca'>
            Clarke, Covington, Jasper, Jones, Kemper, Lauderdale, Leake,
            Neshoba, Newton, Scott, Smith, Wayne
          </p> */}

          {/* <select
            className='nca'
            value={thelink}
            onChange={(e) => setThelink(e.target.value)}
          >
            <option className='nca' value=''>
              Select Your Region
            </option>
            <option
              className='nca'
              value='https://center-gateway.com/2/gateway/02500J/application_form'
            >
              Central Mississippi Procurement Office
            </option>
            <option
              className='nca'
              value='https://center-gateway.com/2/gateway/02500C/application_form'
            >
              NE MS Contract Procurement Office
            </option>
            <option
              className='nca'
              value='https://center-gateway.com/2/gateway/02500G/application_form'
            >
              South Mississippi Contract Procurement Office
            </option>
            <option
              className='nca'
              value='https://center-gateway.com/2/gateway/02500K/application_form'
            >
              Northwest MS Contract Procurement Office
            </option>
            <option
              className='nca'
              value='https://center-gateway.com/2/gateway/02500L/application_form'
            >
              East Central Contract Procurement Office
            </option>
          </select> */}

          {/* <a
            className='gobutton'
            href={thelink}
            target='_blank'
            rel='noreferrer'
          >
            Go
          </a> */}
        </div>
      </>
    </div>
  );
};

export default NewClientApplication;
