export const FEEDBACK_ADD_REQUEST = 'FEEDBACK_ADD_REQUEST';
export const FEEDBACK_ADD_SUCCESS = 'FEEDBACK_ADD_SUCCESS';
export const FEEDBACK_ADD_FAIL = 'FEEDBACK_ADD_FAIL';
export const FEEDBACK_ADD_RESET = 'FEEDBACK_ADD_RESET';

export const NEW_FEEDBACK_LIST_REQUEST = 'NEW_FEEDBACK_LIST_REQUEST';
export const NEW_FEEDBACK_LIST_SUCCESS = 'NEW_FEEDBACK_LIST_SUCCESS';
export const NEW_FEEDBACK_LIST_FAIL = 'NEW_FEEDBACK_LIST_FAIL';
export const NEW_FEEDBACK_LIST_RESET = 'NEW_FEEDBACK_LIST_RESET';

export const ARCHIVED_FEEDBACK_LIST_REQUEST = 'ARCHIVED_FEEDBACK_LIST_REQUEST';
export const ARCHIVED_FEEDBACK_LIST_SUCCESS = 'ARCHIVED_FEEDBACK_LIST_SUCCESS';
export const ARCHIVED_FEEDBACK_LIST_FAIL = 'ARCHIVED_FEEDBACK_LIST_FAIL';
export const ARCHIVED_FEEDBACK_LIST_RESET = 'ARCHIVED_FEEDBACK_LIST_RESET';

export const FEEDBACK_DETAILS_REQUEST = 'FEEDBACK_DETAILS_REQUEST';
export const FEEDBACK_DETAILS_SUCCESS = 'FEEDBACK_DETAILS_SUCCESS';
export const FEEDBACK_DETAILS_FAIL = 'FEEDBACK_DETAILS_FAIL';
export const FEEDBACK_DETAILS_RESET = 'FEEDBACK_DETAILS_RESET';

export const FEEDBACK_UPDATE_REQUEST = 'FEEDBACK_UPDATE_REQUEST';
export const FEEDBACK_UPDATE_SUCCESS = 'FEEDBACK_UPDATE_SUCCESS';
export const FEEDBACK_UPDATE_FAIL = 'FEEDBACK_UPDATE_FAIL';
export const FEEDBACK_UPDATE_RESET = 'FEEDBACK_UPDATE_RESET';
