import './cybersecurity.css';

const CyberSecurity = () => {
  return (
    <div className='maingrid'>
      <div className='innerbannerimage'>
        <img src='/assets/images/cybersecurity-vpn.jpg' alt='About Banner' />
      </div>

      <h2 className='innerpagetitle'>Small Business Cyber Security</h2>

      <div className='innerpagecontent'>
        <div className='csinnerpagecontent'>
          <h2>Project Spectrum</h2>
          <p>
            Project Spectrum is a comprehensive, proven, and cost-effective
            digital platform that provides cybersecurity information, tools,
            resources, and training to organizations including small businesses
            in the defense industrial base. The program mission is to improve
            cybersecurity readiness, resiliency, and compliance for small and
            medium-sized businesses and the federal manufacturing supply chain.
          </p>

          <a
            className='project-spectrum'
            href='https://www.projectspectrum.io/#/contact'
          >
            https://www.projectspectrum.io/#/contact
          </a>

          <h2>Cybersecurity Resources for Manufacturers</h2>

          <a
            className='csrm'
            href='https://www.nist.gov/blogs/manufacturing-innovation-blog/cybersecurity-risk-mitigation-small-manufacturers'
          >
            https://www.nist.gov/blogs/manufacturing-innovation-blog/cybersecurity-risk-mitigation-small-manufacturers
          </a>

          <a className='csrm' href='https://www.nist.gov/mep/about-nist-mep'>
            https://www.nist.gov/mep/about-nist-mep
          </a>
        </div>
      </div>
    </div>
  );
};

export default CyberSecurity;
