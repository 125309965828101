import {
  ABOUT_DATA_REQUEST,
  ABOUT_DATA_SUCCESS,
  ABOUT_DATA_FAIL,
  ABOUT_DETAILS_REQUEST,
  ABOUT_DETAILS_SUCCESS,
  ABOUT_DETAILS_FAIL,
  ABOUT_DETAILS_RESET,
  ABOUT_UPDATE_REQUEST,
  ABOUT_UPDATE_SUCCESS,
  ABOUT_UPDATE_FAIL,
  ABOUT_UPDATE_RESET,
} from '../constants/aboutConstants';

export const aboutGetReducer = (state = { aboutData: {} }, action) => {
  switch (action.type) {
    case ABOUT_DATA_REQUEST:
      return { loading: true };
    case ABOUT_DATA_SUCCESS:
      return { loading: false, aboutData: action.payload };
    case ABOUT_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const aboutDetailsReducer = (state = { about: {} }, action) => {
  switch (action.type) {
    case ABOUT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ABOUT_DETAILS_SUCCESS:
      return { loading: false, about: action.payload };
    case ABOUT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ABOUT_DETAILS_RESET:
      return { about: {} };
    default:
      return state;
  }
};

export const aboutUpdateReducer = (state = { about: {} }, action) => {
  switch (action.type) {
    case ABOUT_UPDATE_REQUEST:
      return { loading: true };
    case ABOUT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ABOUT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ABOUT_UPDATE_RESET:
      return {
        about: {},
      };
    default:
      return state;
  }
};
