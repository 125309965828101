import './findus.css';

const FindUs = () => {
  return (
    <div className='findus'>
      <div className='innerbannerimage'>
        <img src='assets/images/construction01.jpg' alt='About Banner' />
      </div>

      <h2 className='innerpagetitle'>Find Us</h2>

      <div className='officelocations'>
        <div className='oltitle'>
          <h2>APEX Network - Hover over to see related locations</h2>
        </div>

        <div className='mptapnetwork'>
          <div className='msmap'>
            <img src='assets/images/mississippi-county-map.jpg' alt='' />
          </div>

          <ul className='panels'>
            <li className='officeone'>
              <div className='front'>
                <h3 className='officetitle'>Central MS APEX Office</h3>
                <p>Woolfolk Building 4th Floor</p>
                <p>501 North West Street</p>
                <p>Jackson, MS 39201</p>
                <p>Phone (601) 359-3448</p>
                <p>Fax (601) 359-5290</p>
              </div>
              <div className='back'>
                {/* <h3 className='officetitle'>PROGRAM OFFICE</h3> */}
                <p className='counties'>
                  Adam, Claiborne, Copiah, Franklin, Greene, Hinds, Jefferson,
                  Jefferson Davis, Lawrence, Lincoln, Madison, Rankin, Simpson,
                  Warren, Yazoo
                </p>
              </div>
            </li>
            <li className='officetwo'>
              <div className='front'>
                <h3 className='officetitle'>NORTHWEST MS APEX OFFICE</h3>
                <p>7075 Golden Oaks Loop W., Suite 12</p>
                <p>Southaven, MS 38671</p>
                <p>Phone (662) 349-2379</p>
                <p>Fax (662) 349-3988</p>
                <p>Mobile (662) 209-0412</p>
              </div>
              <div className='back'>
                {/* <h3 className='officetitle'>
                NORTHWEST MS CONTRACT PROCUREMENT OFFICE
              </h3> */}
                <p className='counties'>
                  Bolivar, Carroll, Coahoma, DeSoto, Grenada, Holmes, Humphreys,
                  Issequena, LeFlore, Panola, Quitman, Sharkey, Sunflowe, Yazoo
                </p>
              </div>
            </li>
            <li className='officethree'>
              <div className='front'>
                <h3 className='officetitle'>NORTHEAST MS APEX OFFICE</h3>
                <p>318 7th Street North</p>
                <p>Columbus, MS 39703</p>
                <p>Phone (662) 329-1077</p>
                <p>Fax (662) 327-6600</p>
              </div>
              <div className='back'>
                {/* <h3 className='officetitle'>
                NORTHEAST MS CONTRACT PROCUREMENT OFFICE
              </h3> */}
                <p className='counties'>
                  Alcorn, Attala, Benton, Calhoun, Chickasaw, Chocktaw, Clay,
                  Itawamba, Lafayette, Lee, Lowndes, Marshall, Monroe,
                  Montgomery, Noxubee, Oktibbeha, Pontotoc, Prentiss, Tippah,
                  Tishomingo, Union, Webster, Winston
                </p>
              </div>
            </li>
            <li className='officefour'>
              <div className='front'>
                <h3 className='officetitle'>EAST MS APEX OFFICE</h3>
                <p>723 23rd Avenue</p>
                <p>Meridian, MS 39301</p>
                <p>Phone (601) 934-5975</p>
              </div>
              <div className='back'>
                {/* <h3 className='officetitle'>EAST MS CONTRACT PROCUREMENT OFFICE</h3> */}
                <p className='counties'>
                  Clarke, Covington, Jasper, Jones, Kemper, Lauderdale, Leake,
                  Neshoba, Newton, Scott, Smith, Wayne Issequena, LeFlore,
                  Panola, Quitman, Sharkey, Sunflower
                </p>
              </div>
            </li>
            <li className='officefive'>
              <div className='front'>
                <h3 className='officetitle'>SOUTH MS APEX OFFICE</h3>
                <p>2500 14th St., Eigth Floor</p>
                <p>Gulfport, MS 39501</p>
                <p>Phone (228) 396-1288</p>
              </div>
              <div className='back'>
                {/* <h3 className='officetitle'>SOUTH MS CONTRACT PROCUREMENT OFFICE</h3> */}
                <p className='counties'>
                  Amite, Forrest, George, Greene, Hancock, Harrison, Jackson,
                  Lamar, Marion, Pearl River, Perry, Pike, Stone, Walthall,
                  Wilkinson
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FindUs;
