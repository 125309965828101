import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listFaqs } from '../../../redux/actions/faqActions';
import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './faq.css';

const Faq = () => {
  const dispatch = useDispatch();

  const faqList = useSelector((state) => state.faqList);
  const { loading, error, faqs } = faqList;

  useEffect(() => {
    dispatch(listFaqs());
  }, [dispatch]);

  let sno = 0;

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img src='/assets/images/faq.jpg' alt='About Banner' />
          </div>

          <h2 className='innerpagetitle'>FAQs</h2>

          <div className='innerpagecontent'>
            {faqs.map((faq) => (
              <div className='thefaqs' key={faq._id}>
                <div className='thequery'>
                  <div className='thesno'>{(sno += 1)}.</div>
                  <h3>{faq.thequery}</h3>
                </div>
                <div
                  className='theresponse'
                  dangerouslySetInnerHTML={{ __html: faq.theresponse }}
                ></div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Faq;
