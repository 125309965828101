import './logobar.css';

const LogoBar = () => {
  return (
    <div className='maingrid'>
      <div className='logobar'>
        <div className='logoleft'>
          <a href='/'>
            <img src='/assets/images/mdanew.jpg' alt='MSCPC PTAC logo' />
            {/* <img src='/assets/images/mdadark.jpg' alt='MSCPC PTAC logo' /> */}
          </a>
        </div>
        <div className='logoright'>
          <a href='/'>
            <img
              src='/assets/images/Logo_APEX+Accelerators_01092023_page-0001.jpg'
              alt='MDA logo'
            />
            {/* <img src='/assets/images/mighty-mississippi.jpg' alt='MDA logo' /> */}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LogoBar;
