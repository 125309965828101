import SuccessStories from '../components/successstories/SuccessStories';

import FeContainer from '../components/fecontainer/FeContainer';

const SuccessStoriesPage = () => {
  return (
    <FeContainer>
      <SuccessStories />
    </FeContainer>
  );
};

export default SuccessStoriesPage;
