import AdContainer from '../../components/adcontainer/AdContainer';
import ContactRequestList from '../../components/contact/ContactRequestList';

const ContactRequestListScreen = () => {
  return (
    <AdContainer>
      <ContactRequestList />
    </AdContainer>
  );
};

export default ContactRequestListScreen;
