import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { addTR } from '../../../redux/actions/technicalresrourcesActions';
import { TR_ADD_RESET } from '../../../redux/constants/technicalresourcesConstants';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const TechnicalResourcesAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  const trAdd = useSelector((state) => state.trAdd);
  const { loading, error, newTR } = trAdd;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addTR(category, title, link));
  };

  useEffect(() => {
    dispatch({ type: TR_ADD_RESET });
    if (newTR) {
      navigate('/admin/technical-resources');
    }
  }, [dispatch, navigate, newTR]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Add New Technical Resource</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='dataitems'>
              <div>
                <label htmlFor='category'>Technical Resource Category</label>
                <input
                  type='text'
                  id='category'
                  placeholder='Enter New Technical Resource'
                  required
                  onChange={(e) =>
                    setCategory(e.target.value.trim().toUpperCase())
                  }
                ></input>
              </div>

              <div>
                <label htmlFor='title'>Technical Resource Title</label>
                <input
                  type='text'
                  id='title'
                  placeholder='Enter New Technical Resource Title'
                  required
                  onChange={(e) => setTitle(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='link'>Bid Link</label>
                <input
                  type='text'
                  id='link'
                  placeholder='Enter New Technical Resource Link'
                  required
                  onChange={(e) => setLink(e.target.value)}
                ></input>
              </div>

              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default TechnicalResourcesAdd;
