export const CP_DATA_REQUEST = 'CP_DATA_REQUEST';
export const CP_DATA_SUCCESS = 'CP_DATA_SUCCESS';
export const CP_DATA_FAIL = 'CP_DATA_FAIL';

export const CP_DETAILS_SUCCESS = 'CP_DETAILS_SUCCESS';
export const CP_DETAILS_REQUEST = 'CP_DETAILS_REQUEST';
export const CP_DETAILS_FAIL = 'CP_DETAILS_FAIL';
export const CP_DETAILS_RESET = 'CP_DETAILS_RESET';

export const CP_UPDATE_REQUEST = 'CP_UPDATE_REQUEST';
export const CP_UPDATE_SUCCESS = 'CP_UPDATE_SUCCESS';
export const CP_UPDATE_FAIL = 'CP_UPDATE_FAIL';
export const CP_UPDATE_RESET = 'CP_UPDATE_RESET';
