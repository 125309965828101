import {
  FEEDBACK_ADD_REQUEST,
  FEEDBACK_ADD_SUCCESS,
  FEEDBACK_ADD_FAIL,
  FEEDBACK_ADD_RESET,
  NEW_FEEDBACK_LIST_REQUEST,
  NEW_FEEDBACK_LIST_SUCCESS,
  NEW_FEEDBACK_LIST_FAIL,
  NEW_FEEDBACK_LIST_RESET,
  ARCHIVED_FEEDBACK_LIST_REQUEST,
  ARCHIVED_FEEDBACK_LIST_SUCCESS,
  ARCHIVED_FEEDBACK_LIST_FAIL,
  ARCHIVED_FEEDBACK_LIST_RESET,
  FEEDBACK_DETAILS_REQUEST,
  FEEDBACK_DETAILS_SUCCESS,
  FEEDBACK_DETAILS_FAIL,
  FEEDBACK_DETAILS_RESET,
  FEEDBACK_UPDATE_REQUEST,
  FEEDBACK_UPDATE_SUCCESS,
  FEEDBACK_UPDATE_FAIL,
  FEEDBACK_UPDATE_RESET,
} from '../constants/customerfeedbackConstants';

export const feedbackAddReducer = (state = {}, action) => {
  switch (action.type) {
    case FEEDBACK_ADD_REQUEST:
      return { loading: true };
    case FEEDBACK_ADD_SUCCESS:
      return { loading: false, newFeedback: action.payload };
    case FEEDBACK_ADD_FAIL:
      return { loading: false, error: action.payload };
    case FEEDBACK_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const newFeedbackListReducer = (
  state = { newFeedbacks: [] },
  action
) => {
  switch (action.type) {
    case NEW_FEEDBACK_LIST_REQUEST:
      return { loading: true };
    case NEW_FEEDBACK_LIST_SUCCESS:
      return { loading: false, newFeedbacks: action.payload };
    case NEW_FEEDBACK_LIST_FAIL:
      return { loading: false, error: action.payload };
    case NEW_FEEDBACK_LIST_RESET:
      return { newFeedbacks: [] };
    default:
      return state;
  }
};

export const archivedFeedbackListReducer = (
  state = { archivedFeedbacks: [] },
  action
) => {
  switch (action.type) {
    case ARCHIVED_FEEDBACK_LIST_REQUEST:
      return { loading: true };
    case ARCHIVED_FEEDBACK_LIST_SUCCESS:
      return { loading: false, archivedFeedbacks: action.payload };
    case ARCHIVED_FEEDBACK_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ARCHIVED_FEEDBACK_LIST_RESET:
      return { archivedFeedbacks: [] };
    default:
      return state;
  }
};

export const feedbackDetailsReducer = (state = { feedback: {} }, action) => {
  switch (action.type) {
    case FEEDBACK_DETAILS_REQUEST:
      return { ...state, loading: true };
    case FEEDBACK_DETAILS_SUCCESS:
      return { loading: false, feedback: action.payload };
    case FEEDBACK_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case FEEDBACK_DETAILS_RESET:
      return { feedback: {} };
    default:
      return state;
  }
};

export const feedbackUpdateReducer = (state = { feedback: {} }, action) => {
  switch (action.type) {
    case FEEDBACK_UPDATE_REQUEST:
      return { loading: true };
    case FEEDBACK_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case FEEDBACK_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case FEEDBACK_UPDATE_RESET:
      return { feedback: {} };
    default:
      return state;
  }
};
