import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listTRs } from '../../../redux/actions/technicalresrourcesActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './technicalresources.css';

const TechnicalResources = () => {
  const dispatch = useDispatch();

  const trList = useSelector((state) => state.trList);
  const { loading, error, trs } = trList;

  useEffect(() => {
    dispatch(listTRs());
  }, [dispatch]);

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img
              src='/assets/images/technical-resources.jpg'
              alt='About Banner'
            />
          </div>
          <h2 className='innerpagetitle'>Technical Resources</h2>

          <div className='innerpagecontent'>
            <div className='techresgrid'>
              <>
                <div className='trset'>
                  <h2 className='trhead'>Technical Information</h2>
                  {trs.map((tr) => (
                    <div className='trlink' key={tr._id}>
                      {tr.category === 'TECHNICAL INFORMATION' ? (
                        <a href={tr.link} target='_blank' rel='noreferrer'>
                          <i className='fa fa-external-link'></i> {tr.title}
                        </a>
                      ) : null}
                    </div>
                  ))}
                </div>

                <div className='trset'>
                  <h2 className='trhead'>Laws & Regulations</h2>
                  {trs.map((tr) => (
                    <div className='trlink' key={tr._id}>
                      {tr.category === 'LAWS & REGULATIONS' ? (
                        <a href={tr.link} target='_blank' rel='noreferrer'>
                          <i className='fa fa-external-link'></i> {tr.title}
                        </a>
                      ) : null}
                    </div>
                  ))}
                </div>

                <div className='trset'>
                  <h2 className='trhead'>Registrations</h2>
                  {trs.map((tr) => (
                    <div className='trlink' key={tr._id}>
                      {tr.category === 'REGISTRATIONS' ? (
                        <a href={tr.link} target='_blank' rel='noreferrer'>
                          <i className='fa fa-external-link'></i> {tr.title}
                        </a>
                      ) : null}
                    </div>
                  ))}
                </div>

                <div className='trset'>
                  <h2 className='trhead'>Resource Partners</h2>
                  {trs.map((tr) => (
                    <div className='trlink' key={tr._id}>
                      {tr.category === 'RESOURCE PARTNERS' ? (
                        <a href={tr.link} target='_blank' rel='noreferrer'>
                          <i className='fa fa-external-link'></i> {tr.title}
                        </a>
                      ) : null}
                    </div>
                  ))}
                </div>

                <div className='trset'>
                  <h2 className='trhead'>Education & Training</h2>
                  {trs.map((tr) => (
                    <div className='trlink' key={tr._id}>
                      {tr.category === 'EDUCATION & TRAINING' ? (
                        <a href={tr.link} target='_blank' rel='noreferrer'>
                          <i className='fa fa-external-link'></i> {tr.title}
                        </a>
                      ) : null}
                    </div>
                  ))}
                </div>
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TechnicalResources;
