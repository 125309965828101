import axios from 'axios';

import {
  HOMEPAGE_DATA_REQUEST,
  HOMEPAGE_DATA_SUCCESS,
  HOMEPAGE_DATA_FAIL,
} from '../constants/homepageConstants';

export const getHomePage = () => async (dispatch) => {
  console.log('reached homepage actions');
  dispatch({
    type: HOMEPAGE_DATA_REQUEST,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const { data } = await axios.get('/api', config);

  dispatch({
    type: HOMEPAGE_DATA_SUCCESS,
    payload: data,
  });

  try {
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: HOMEPAGE_DATA_FAIL,
      payload: message,
    });
  }
};
