import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import { CKEditor } from '@ckeditor/ckeditor5-react';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

import { addFeedback } from '../../../redux/actions/customerfeedbackActions';
import { FEEDBACK_ADD_RESET } from '../../../redux/constants/customerfeedbackConstants';

// import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

import './customerfeedback.css';

const CustomerFeedback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [businessname, setBusinessname] = useState('');
  const [pointofcontact, setPointofcontact] = useState('');
  const [businessaddress, setBusinessaddress] = useState('');
  const [businesscity, setBusinesscity] = useState('');
  const [businessstate, setBusinessstate] = useState('');
  const [businesszip, setBusinesszip] = useState('');
  const [businessphone, setBusinessphone] = useState('');
  const [businessfax, setBusinessfax] = useState('');
  const [businessemail, setBusinessemail] = useState('');
  const [mptapassisted, setMptapassisted] = useState('');
  const [mptapcounsellorsresponsive, setMptapcounsellorsresponsive] =
    useState('');
  const [mptapcounsellorsavailable, setMptapcounsellorsavailable] =
    useState('');
  const [mptapcomments, setMptapcomments] = useState('');
  const [websitevaluable, setWebsitevaluable] = useState('');
  const [websitelogical, setWebsitelogical] = useState('');
  const [websiteavailable, setWebsiteavailable] = useState('');
  const [websitecomments, setWebsitecomments] = useState('');
  const [seminarinstrumental, setSeminarinstrumental] = useState('');
  const [seminargoodtiming, setSeminargoodtiming] = useState('');
  const [seminarcomments, setSeminarcomments] = useState('');
  const [mptapbidmatchinguseful, setMptapbidmatchinguseful] = useState('');
  const [mptapbidmatchingtailored, setMptapbidmatchingtailored] = useState('');
  const [mptapbidmatchinggivetime, setMptapbidmatchinggivetime] = useState('');
  const [mptapbidmatchingcomments, setMptapbidmatchingcomments] = useState('');
  const [servicerating, setServicerating] = useState('');
  const [overallcomments, setOverallcomments] = useState('');

  const feedbackAdd = useSelector((state) => state.feedbackAdd);
  const { loading, error, newFeedback } = feedbackAdd;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      addFeedback(
        businessname,
        pointofcontact,
        businessaddress,
        businesscity,
        businessstate,
        businesszip,
        businessphone,
        businessfax,
        businessemail,
        mptapassisted,
        mptapcounsellorsresponsive,
        mptapcounsellorsavailable,
        mptapcomments,
        websitevaluable,
        websitelogical,
        websiteavailable,
        websitecomments,
        seminarinstrumental,
        seminargoodtiming,
        seminarcomments,
        mptapbidmatchinguseful,
        mptapbidmatchingtailored,
        mptapbidmatchinggivetime,
        mptapbidmatchingcomments,
        servicerating,
        overallcomments
      )
    );
  };

  useEffect(() => {
    if (newFeedback) {
      navigate(-1);
    }
  });

  return (
    <div className='maingrid'>
      <div className='innerbannerimage'>
        <img src='/assets/images/feedback01.jpg' alt='About Banner' />
      </div>
      <h2 className='innerpagetitle'>Customer Feedback</h2>
      <div className='innerpagecontent'>
        <form className='form' id='addss' onSubmit={submitHandler}>
          <div className='dataitems'>
            <div>
              <label htmlFor='businessname'>
                Business Name <span>*</span>
              </label>
              <input
                type='text'
                id='businessname'
                placeholder='Enter Business Name'
                required
                // value={businessname}
                onChange={(e) => setBusinessname(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='pointofcontact'>
                Name of Contact Person <span>*</span>
              </label>
              <input
                type='text'
                id='pointofcontact'
                placeholder='Enter name of Contact Person'
                required
                // value={name}
                onChange={(e) => setPointofcontact(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='address'>Business Address</label>
              <CKEditor
                editor={Editor}
                data={''}
                config={EDITOR_CONFIGURATION}
                onChange={(event, editor) => {
                  const addressdata = editor.getData();
                  setBusinessaddress(addressdata);
                }}
              />
            </div>

            <div>
              <label htmlFor='city'>
                Name of City <span>*</span>
              </label>
              <input
                type='text'
                id='city'
                placeholder='Enter City'
                required
                // value={name}
                onChange={(e) => setBusinesscity(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='state'>
                Name of State <span>*</span>
              </label>
              <input
                type='text'
                id='state'
                placeholder='Enter State'
                required
                // value={name}
                onChange={(e) => setBusinessstate(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='businesszip'>
                Zip Code <span>*</span>
              </label>
              <input
                type='text'
                id='businesszip'
                placeholder='Enter Zip Code'
                required
                // value={name}
                onChange={(e) => setBusinesszip(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='businessphone'>
                Contact Phone Number <span>*</span>
              </label>
              <input
                type='text'
                id='businessphone'
                placeholder='Enter Contact Phone Number'
                required
                // value={name}
                onChange={(e) => setBusinessphone(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='businessfax'>Fax Number</label>
              <input
                type='text'
                id='businessfax'
                placeholder='Enter Fax Number'
                // value={name}
                onChange={(e) => setBusinessfax(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='businessemail'>
                Email Id <span>*</span>
              </label>
              <input
                type='email'
                id='businessemail'
                placeholder='Enter Email Id'
                required
                // value={name}
                onChange={(e) => setBusinessemail(e.target.value)}
              ></input>
            </div>

            <div>
              <label>
                1. The APEX services assisted my business in indentifying and
                accessing new government and commercial contracting
                opportunities. Please select your response from below.
              </label>
              <select
                value={mptapassisted}
                onChange={(e) => setMptapassisted(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label>
                2. The APEX counselors/advisors are responsive and flexible in
                meeting my company's needs. Please select your response from
                below.
              </label>
              <select
                value={mptapcounsellorsresponsive}
                onChange={(e) => setMptapcounsellorsresponsive(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label>
                3. The APEX counselors/advisers are available when my company
                needs them. Please select your response from below.
              </label>
              <select
                value={mptapcounsellorsavailable}
                onChange={(e) => setMptapcounsellorsavailable(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label htmlFor='mptapcomments'>
                Please provide any comments on how we may improve our customer
                service.
              </label>
              <CKEditor
                editor={Editor}
                data={''}
                config={EDITOR_CONFIGURATION}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setMptapcomments(data);
                }}
              />
            </div>

            <div>
              <label>
                1. The APEX website is a valuable "One-stop" resource for
                procurement and small business information. Please select your
                response from below.
              </label>
              <select
                value={websitevaluable}
                onChange={(e) => setWebsitevaluable(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label>
                2. The APEX website is logically designed and easy to navigate.
                Please select your response from below.
              </label>
              <select
                value={websitelogical}
                onChange={(e) => setWebsitelogical(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label>
                3. The APEX website is available when my business needs it.
                Please select your response from below.
              </label>
              <select
                value={websiteavailable}
                onChange={(e) => setWebsiteavailable(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label htmlFor='websitecomments'>
                What additional information or features should be added to the
                website?
              </label>
              <CKEditor
                editor={Editor}
                data={''}
                config={EDITOR_CONFIGURATION}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setWebsitecomments(data);
                }}
              />
            </div>

            <div>
              <label>
                1. The seminars/workshops offered by the APEX have been
                instrumental in increasing the knowledge and/or productivity of
                my company. Please select your response from below.
              </label>
              <select
                value={seminarinstrumental}
                onChange={(e) => setSeminarinstrumental(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label>
                2. The seminars and workshops offered by the APEX are scheduled
                at a reasonable time and/or location. Please select your
                response from below.
              </label>
              <select
                value={seminargoodtiming}
                onChange={(e) => setSeminargoodtiming(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label htmlFor='seminarcomments'>
                What seminar and/or workshop topic do you suggest for future
                training sessions?
              </label>
              <CKEditor
                editor={Editor}
                data={''}
                config={EDITOR_CONFIGURATION}
              />
            </div>

            <div>
              <label>
                1. The APEX Bid Management Program notifies my company of
                procurement opportunities that are useful to my company. Please
                select your response from below.
              </label>
              <select
                value={mptapbidmatchinguseful}
                onChange={(e) => setMptapbidmatchinguseful(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label>
                2. The APEX bid notifications are tailored to my products and
                services. Please select your response from below.
              </label>
              <select
                value={mptapbidmatchingtailored}
                onChange={(e) => setMptapbidmatchingtailored(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label>
                3. The APEX bid notifications provide sufficient lead-time for
                my company to make a bid/no-bid decision. Please select your
                response from below.
              </label>
              <select
                value={mptapbidmatchinggivetime}
                onChange={(e) => setMptapbidmatchinggivetime(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Strongly Agree'>Strongly Agree</option>
                <option value='More than agree'>More than agree</option>
                <option value='Agree'>Agree</option>
                <option value='Marginally Disagree'>Marginally Disagree</option>
                <option value='Strongly Disagree'>Strongly Disagree</option>
              </select>
            </div>

            <div>
              <label htmlFor='mptapbidmatchingcomments'>
                How may we improve our bid notification services?
              </label>
              <CKEditor
                editor={Editor}
                data={''}
                config={EDITOR_CONFIGURATION}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setMptapbidmatchingcomments(data);
                }}
              />
            </div>

            <div>
              <label>
                How would you rate our services overall? Please select your
                response from below.
              </label>
              <select
                value={servicerating}
                onChange={(e) => setServicerating(e.target.value)}
              >
                <option value=''>Please Select</option>
                <option value='Outstanding'>Outstanding</option>
                <option value='Excellent'>Excellent</option>
                <option value='Satisfactory'>Satisfactory</option>
                <option value='Marginal'>Marginal</option>
                <option value='Unsatisfactory'>Unsatisfactory</option>
              </select>
            </div>

            <div>
              <label htmlFor='overallcomments'>
                Please provide any additional comments on the quality of our
                services
              </label>
              <CKEditor
                editor={Editor}
                data={''}
                config={EDITOR_CONFIGURATION}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setOverallcomments(data);
                }}
              />
            </div>
          </div>
          <div>
            <label />
            <button className='submitform' type='submit'>
              Submit Feedback
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerFeedback;
