import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

import { addEvent } from '../../../redux/actions/eventActions';
import { EVENT_ADD_RESET } from '../../../redux/constants/eventConstants';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './upcomingevents.css';

const UpcomingEventsAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const [eventtitle, setEventTitle] = useState('');
  const [mptapcenter, setMptapcenter] = useState('');
  const [starttime, setStarttime] = useState(null);
  const [endtime, setEndtime] = useState(null);
  const [eventvenue, setEventvenue] = useState('');
  const [addressline1, setAddressline1] = useState('');
  const [addressline2, setAddressline2] = useState('');
  const [addressline3, setAddressline3] = useState('');
  const [addressline4, setAddressline4] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [eventdetails, setEventdetails] = useState('');
  const [eventwebsite, setEventwebsite] = useState('');
  const [eventcost, setEventcost] = useState(0);
  const [contactperson, setContactperson] = useState('');
  const [contactphone, setContactphone] = useState('');
  const [contactemail, setContactemail] = useState('');
  const [eventtags, setEventtags] = useState('');
  const [resource, setResource] = useState('');
  const [uploading, setUploading] = useState(false);

  const eventAdd = useSelector((state) => state.eventAdd);
  const { loading, error, newEvent } = eventAdd;

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setUploading(true);

    try {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const { data } = await axios.post('/api/upload', formData, config);

      setResource(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      addEvent(
        eventtitle,
        mptapcenter,
        starttime,
        endtime,
        eventvenue,
        addressline1,
        addressline2,
        addressline3,
        addressline4,
        state,
        city,
        zip,
        eventdetails,
        eventwebsite,
        eventcost,
        contactperson,
        contactphone,
        contactemail,
        eventtags,
        resource
      )
    );
  };

  useEffect(() => {
    dispatch({ type: EVENT_ADD_RESET });
    if (newEvent) {
      navigate('/admin/events');
    }
  }, [dispatch, newEvent, navigate]);

  return (
    <div className='maingrid'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Add New Event</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='dataitems'>
              <div>
                <label htmlFor='eventtitle'>Event Title</label>
                <input
                  type='text'
                  id='eventtitle'
                  placeholder='Enter Event Title'
                  required
                  onChange={(e) => setEventTitle(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='mptapcenter'>APEX Center</label>
                <input
                  type='text'
                  id='mptapcenter'
                  placeholder="Enter APEX Center ('ALL', 'PO', 'MSBDD', 'NECPC', 'NWCPC', 'SCPC')"
                  required
                  onChange={(e) => setMptapcenter(e.target.value.toUpperCase())}
                ></input>
              </div>

              <div className='date-styles'>
                <label htmlFor='starttime'>Event Start Time</label>
                <DatePicker
                  selected={starttime}
                  minDate={new Date()}
                  dateFormat='MMMM d, yyyy h:mm aa'
                  placeholderText='Select Start Date and Time'
                  showTimeSelect
                  selectsStart
                  startDate={starttime}
                  endDate={endtime}
                  onChange={(date) => setStarttime(date)}
                />

                <label htmlFor='endtime'>Event End Time</label>
                <DatePicker
                  selected={endtime}
                  minDate={starttime}
                  dateFormat='MMMM d, yyyy h:mm aa'
                  placeholderText='Select End Date and Time'
                  showTimeSelect
                  selectsEnd
                  startDate={starttime}
                  endDate={endtime}
                  onChange={(date) => setEndtime(date)}
                />
              </div>

              <div>
                <label htmlFor='eventvenue'>Event Venue</label>
                <input
                  type='text'
                  id='eventvenue'
                  placeholder='Enter Event Venue'
                  required
                  onChange={(e) => setEventvenue(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='addressline1'>Event Venue Address Line 1</label>
                <input
                  type='text'
                  id='addressline1'
                  placeholder='Enter Event Venue Address Line 1'
                  onChange={(e) => setAddressline1(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='addressline2'>Event Venue Address Line 2</label>
                <input
                  type='text'
                  id='addressline2'
                  placeholder='Enter Event Venue Address Line 2'
                  onChange={(e) => setAddressline2(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='addressline3'>Event Venue Address Line 3</label>
                <input
                  type='text'
                  id='addressline3'
                  placeholder='Enter Event Venue Address Line 3'
                  onChange={(e) => setAddressline3(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='addressline4'>Event Venue Address Line 4</label>
                <input
                  type='text'
                  id='addressline4'
                  placeholder='Enter Event Venue Address Line 4'
                  onChange={(e) => setAddressline4(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='state'>Event Venue State</label>
                <input
                  type='text'
                  id='state'
                  placeholder='Enter Event Venue State'
                  onChange={(e) => setState(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='city'>Event Venue City</label>
                <input
                  type='text'
                  id='city'
                  placeholder='Enter Event Venue City'
                  onChange={(e) => setCity(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='zip'>Event Venue Zip Code</label>
                <input
                  type='text'
                  id='zip'
                  placeholder='Enter Event Venue Zip Code'
                  onChange={(e) => setZip(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='eventdetails'>Event Details</label>
                <CKEditor
                  editor={Editor}
                  data={eventdetails}
                  config={EDITOR_CONFIGURATION}
                  onChange={(event, editor) => {
                    const responsedata = editor.getData();
                    setEventdetails(responsedata);
                  }}
                />
              </div>

              <div>
                <label htmlFor='eventwebsite'>Event Website</label>
                <input
                  type='text'
                  id='eventwebsite'
                  placeholder='Enter Event Website'
                  onChange={(e) => setEventwebsite(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='eventcost'>Event Cost</label>
                <input
                  type='text'
                  id='eventcost'
                  placeholder='Enter Event Cost (Default Free)'
                  value={eventcost}
                  onChange={(e) => setEventcost(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='contactperson'>Contact Person</label>
                <input
                  type='text'
                  id='contactperson'
                  placeholder='Enter Contact Person'
                  onChange={(e) => setContactperson(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='contactphone'>Contact Phone</label>
                <input
                  type='text'
                  id='contactphone'
                  placeholder='Enter Contact Phone'
                  onChange={(e) => setContactphone(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='contactemail'>Contact Email</label>
                <input
                  type='text'
                  id='contactemail'
                  placeholder='Enter Contact Email'
                  onChange={(e) => setContactemail(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='eventtags'>Contact Event Tags</label>
                <input
                  type='text'
                  id='eventtags'
                  placeholder='Enter Contact Event Tags'
                  onChange={(e) => setEventtags(e.target.value)}
                ></input>
              </div>

              <div>
                <label>Attachment</label>
                <input
                  type='text'
                  value={resource}
                  onChange={(e) => setResource(e.target.value)}
                />
              </div>

              <div>
                <label>Choose File</label>
                <input type='file' value={''} onChange={uploadFileHandler} />
              </div>

              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default UpcomingEventsAdd;
