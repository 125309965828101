import axios from 'axios';
import {
  TR_ADD_REQUEST,
  TR_ADD_SUCCESS,
  TR_ADD_FAIL,
  TR_LIST_FAIL,
  TR_LIST_REQUEST,
  TR_LIST_SUCCESS,
  TR_DETAILS_REQUEST,
  TR_DETAILS_SUCCESS,
  TR_DETAILS_FAIL,
  TR_UPDATE_REQUEST,
  TR_UPDATE_SUCCESS,
  TR_UPDATE_FAIL,
  TR_DELETE_REQUEST,
  TR_DELETE_SUCCESS,
  TR_DELETE_FAIL,
} from '../constants/technicalresourcesConstants';

import { logout } from './userActions';

export const addTR = (category, title, link) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TR_ADD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      '/api/technical-resources',
      { category, title, link },
      config
    );

    dispatch({
      type: TR_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: TR_ADD_FAIL,
      payload: message,
    });
  }
};

export const listTRs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TR_LIST_REQUEST,
    });

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/technical-resources', config);

    dispatch({
      type: TR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: TR_LIST_FAIL,
      payload: message,
    });
  }
};

export const getTRDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TR_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/technical-resources/${id}`, config);

    dispatch({
      type: TR_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: TR_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateTRs = (technicalresource) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TR_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/technical-resources/${technicalresource.id}`,
      technicalresource,
      config
    );

    dispatch({
      type: TR_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: TR_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const deleteTR = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TR_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/technical-resources/${id}`, config);

    dispatch({ type: TR_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: TR_DELETE_FAIL,
      payload: message,
    });
  }
};
