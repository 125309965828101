import AdContainer from '../../components/adcontainer/AdContainer';
import UserList from '../../components/users/UserList';

const UserListScreen = () => {
  return (
    <AdContainer>
      <UserList />
    </AdContainer>
  );
};

export default UserListScreen;
