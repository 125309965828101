import CSEdit from '../../components/cybersecurity/CSEdit';
import AdContainer from '../../components/adcontainer/AdContainer';

const CSEditScreen = () => {
  return (
    <AdContainer>
      <CSEdit />
    </AdContainer>
  );
};

export default CSEditScreen;
