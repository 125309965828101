import AdContainer from '../../components/adcontainer/AdContainer';
import SuccessStoriesList from '../../components/successstories/SuccessStoriesList';

const SuccessStoriesListScreen = () => {
  return (
    <AdContainer>
      <SuccessStoriesList />
    </AdContainer>
  );
};

export default SuccessStoriesListScreen;
