import axios from 'axios';
import {
  CONTACT_ADD_REQUEST,
  CONTACT_ADD_SUCCESS,
  CONTACT_ADD_FAIL,
  NEW_CONTACT_LIST_REQUEST,
  NEW_CONTACT_LIST_SUCCESS,
  NEW_CONTACT_LIST_FAIL,
  ARCHIVED_CONTACT_LIST_REQUEST,
  ARCHIVED_CONTACT_LIST_SUCCESS,
  ARCHIVED_CONTACT_LIST_FAIL,
  CONTACT_DETAILS_REQUEST,
  CONTACT_DETAILS_SUCCESS,
  CONTACT_DETAILS_FAIL,
  CONTACT_UPDATE_REQUEST,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAIL,
} from '../constants/contactusConstants';
import { logout } from './userActions';

export const addContact =
  (
    businessname,
    name,
    address,
    city,
    state,
    zipcode,
    phone,
    fax,
    email,
    feedback,
    archive
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTACT_ADD_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        '/api/contact-us',
        {
          businessname,
          name,
          address,
          city,
          state,
          zipcode,
          phone,
          fax,
          email,
          feedback,
          archive,
        },
        config
      );

      dispatch({
        type: CONTACT_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: CONTACT_ADD_FAIL,
        payload: message,
      });
    }
  };

export const listNewContacts = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEW_CONTACT_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/contact-us/contacts/new', config);

    dispatch({
      type: NEW_CONTACT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: NEW_CONTACT_LIST_FAIL,
      payload: message,
    });
  }
};

export const listArchivedContacts = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ARCHIVED_CONTACT_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      '/api/contact-us/contacts/archived',
      config
    );

    dispatch({
      type: ARCHIVED_CONTACT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: ARCHIVED_CONTACT_LIST_FAIL,
      payload: message,
    });
  }
};

export const getContactDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTACT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/contact-us/${id}`, config);

    dispatch({
      type: CONTACT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: CONTACT_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateContact = (contact) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTACT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/contact-us/${contact._id}`,
      contact,
      config
    );

    dispatch({
      type: CONTACT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: CONTACT_UPDATE_FAIL,
      payload: message,
    });
  }
};
