import {
  EVENT_ADD_REQUEST,
  EVENT_ADD_SUCCESS,
  EVENT_ADD_FAIL,
  EVENT_ADD_RESET,
  EVENTS_LIST_REQUEST,
  EVENTS_LIST_SUCCESS,
  EVENTS_LIST_FAIL,
  EVENTS_LIST_RESET,
  EVENT_DETAILS_REQUEST,
  EVENT_DETAILS_SUCCESS,
  EVENT_DETAILS_FAIL,
  EVENT_DETAILS_RESET,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAIL,
  EVENT_UPDATE_RESET,
  EVENT_DELETE_REQUEST,
  EVENT_DELETE_SUCCESS,
  EVENT_DELETE_FAIL,
} from '../constants/eventConstants';

export const eventAddReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_ADD_REQUEST:
      return { loading: true };
    case EVENT_ADD_SUCCESS:
      return { loading: false, newEvent: action.payload };
    case EVENT_ADD_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const eventsListReducer = (state = { events: [] }, action) => {
  switch (action.type) {
    case EVENTS_LIST_REQUEST:
      return { loading: true };
    case EVENTS_LIST_SUCCESS:
      return { loading: false, events: action.payload };
    case EVENTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case EVENTS_LIST_RESET:
      return { events: [] };
    default:
      return state;
  }
};

export const eventDetailsReducer = (state = { event: {} }, action) => {
  switch (action.type) {
    case EVENT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case EVENT_DETAILS_SUCCESS:
      return { loading: false, event: action.payload };
    case EVENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_DETAILS_RESET:
      return { event: {} };
    default:
      return state;
  }
};

export const eventUpdateReducer = (state = { event: {} }, action) => {
  switch (action.type) {
    case EVENT_UPDATE_REQUEST:
      return { loading: true };
    case EVENT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case EVENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_UPDATE_RESET:
      return { event: {} };
    default:
      return state;
  }
};

export const eventDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_DELETE_REQUEST:
      return { loading: true };
    case EVENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EVENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
