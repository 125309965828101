import AdContainer from '../../components/adcontainer/AdContainer';
import FaqAdd from '../../components/faqs/FaqAdd';

const FaqAddScreen = () => {
  return (
    <AdContainer>
      <FaqAdd />{' '}
    </AdContainer>
  );
};

export default FaqAddScreen;
