import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { listCNews } from '../../../redux/actions/contractingnewsActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './contractingnews.css';

const ContractingNews = () => {
  const dispatch = useDispatch();

  const cnewsList = useSelector((state) => state.cnewsList);
  const { loading, error, cnews } = cnewsList;

  useEffect(() => {
    dispatch(listCNews());
  }, []);

  let sno = 0;

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img src='assets/images/construction01.jpg' alt='About Banner' />
          </div>
          <h2 className='innerpagetitle'>Contracting News</h2>

          <div className='innerpagecontent'>
            {cnews.map((cnewsItem) => (
              <div className='thenews' key={cnewsItem._id}>
                <div className='thenewsitem'>
                  <div className='thesno'>{(sno += 1)}.</div>
                  <div className='thenewsheading'>
                    <Link to={`/contracting-news/${cnewsItem._id}`}>
                      {cnewsItem.heading} - dated{' '}
                      {moment(cnewsItem.createdAt).format('MMMM Do YYYY')}.
                    </Link>
                  </div>
                </div>
                <div
                  className='thenewscontent'
                  dangerouslySetInnerHTML={{
                    __html: cnewsItem.contractingnewscontent
                      .split(' ')
                      .slice(0, 50)
                      .join(' '),
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ContractingNews;
