import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { listFaqs, deleteFaq } from '../../../redux/actions/faqActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const FaqList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const faqList = useSelector((state) => state.faqList);
  const { loading, error, faqs } = faqList;

  const faqDelete = useSelector((state) => state.faqDelete);
  const { success: successDelete } = faqDelete;

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteFaq(id));
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listFaqs());
    } else {
      navigate('/admin');
    }
  }, [dispatch, navigate, successDelete, userInfo]);

  let sno = 0;

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>FAQ List</h2>
          <Link to='/admin/faqs/add'>
            <button>
              <i className='fa fa-plus'></i> Add New FAQ
            </button>
          </Link>
        </div>
        <div className='screenlist'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Query</th>
                  <th>Response</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {faqs.map((faq) => (
                  <tr key={faq._id}>
                    <td>{(sno += 1)}</td>
                    <td>{faq.thequery}</td>
                    <td
                      dangerouslySetInnerHTML={{ __html: faq.theresponse }}
                    ></td>
                    <td>
                      <Link to={`/admin/faqs/${faq._id}/edit`}>
                        <button className='small'>
                          <i className='fa fa-edit'></i>
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className='small'
                        onClick={() => deleteHandler(faq._id)}
                      >
                        <i className='fa fa-trash'></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqList;
