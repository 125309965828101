import { useState } from 'react';
import LogoBar from '../logobar/LogoBar';
import NavBar from '../navbar/NavBar';
import Footer from '../footer/Footer';

import './fecontainer.css';

const FeContainer = (props) => {
  const [bannerModal, setBannerModal] = useState(true);

  return (
    <div>
      {/* <div className={bannerModal ? 'bannermodal open' : 'bannermodal'}>
        <div>
          <img src='/assets/images/PTACDAY_LOGO_V1.jpg' alt='something' />
        </div>
        <button
          className='modalCloseButton'
          onClick={() => setBannerModal(false)}
        >
          Close
        </button>
      </div> */}
      <LogoBar />
      <NavBar />
      {props.children}
      <Footer />
    </div>
  );
};

export default FeContainer;
