import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

import { getAbout, updateAbout } from '../../../redux/actions/aboutActions';

import { ABOUT_UPDATE_RESET } from '../../../redux/constants/aboutConstants';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const About = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pagetitle, setPageTitle] = useState('');
  const [pagecontent, setPageContent] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const aboutGet = useSelector((state) => state.aboutGet);
  const { loading, error, aboutData } = aboutGet;

  const aboutUpdate = useSelector((state) => state.aboutUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = aboutUpdate;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateAbout({ pagetitle, pagecontent }));
  };

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: ABOUT_UPDATE_RESET });
      navigate('/admin/screens');
    } else {
      if (
        typeof aboutData !== 'undefined' &&
        Object.keys(aboutData).length === 0
      ) {
        dispatch(getAbout());
      }

      if (
        typeof aboutData !== 'undefined' &&
        Object.keys(aboutData).length > 0
      ) {
        setPageTitle(aboutData.pagetitle);
        setPageContent(aboutData.pagecontent);
      }
    }
  }, [dispatch, navigate, aboutData, successUpdate]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Edit About</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='dataitems'>
              <div>
                <label htmlFor='pagetitle'>Page Title</label>
                <input
                  type='text'
                  id='pagetitle'
                  placeholder='Enter page title'
                  required
                  value={pagetitle}
                  onChange={(e) => setPageTitle(e.target.value)}
                ></input>
              </div>
              <div>
                <label htmlFor='pagecontent'>Page Content</label>
                <CKEditor
                  editor={Editor}
                  data={pagecontent}
                  config={EDITOR_CONFIGURATION}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setPageContent(data);
                  }}
                />
              </div>
              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default About;
