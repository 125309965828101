import { useRef } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

const NavBar = () => {
  const menuRef = useRef();
  const menuMainRef = useRef();
  const subMenuRef = useRef();
  const goBackRef = useRef();
  const menuTriggerRef = useRef();
  const closeMenuRef = useRef();
  const menuOverlayRef = useRef();
  const currentMenuTitleRef = useRef();
  const mobileMenuHeadRef = useRef();
  const menuItemHasChildrenRef = useRef();

  let subMenu;

  const handleMenuMainClick = (e) => {
    if (!menuRef.current.classList.contains('active')) {
      return;
    }
    if (e.nativeEvent.path[1].classList.contains('menu-item-has-children')) {
      const hasChildren = e.nativeEvent.path[1];
      showSubMenu(hasChildren);
    }
  };

  const toggleMenu = () => {
    menuRef.current.classList.toggle('active');
    menuOverlayRef.current.classList.toggle('active');
  };

  const hideSubMenu = () => {
    subMenuRef.current.style.animation = 'slideRight 0.5s ease forwards';
    setTimeout(() => {
      subMenuRef.current.classList.remove('active');
    }, 300);
    currentMenuTitleRef.current.innerHTML = '';
    mobileMenuHeadRef.current.classList.remove('active');
  };

  const showSubMenu = (hasChildren) => {
    subMenu = hasChildren.querySelector('.sub-menu');
    subMenu.classList.add('active');
    subMenu.style.animation = 'slideLeft 0.5s ease forwards';
    const menuTitle =
      hasChildren.querySelector('i').parentNode.childNodes[0].textContent;
    currentMenuTitleRef.current.innerHTML = menuTitle;
    mobileMenuHeadRef.current.classList.add('active');
  };

  window.onresize = function () {
    if (this.innerWidth > 991) {
      if (menuRef.current.classList.contains('active')) {
        toggleMenu();
      }
    }
  };

  return (
    <div className='maingrid'>
      <nav className='navbar'>
        <div className='container'>
          <div className='row v-center'>
            <div className='navbar-item item-left'>
              {/* <div className='logo'>
              <a href='/'>MyStore</a>
            </div> */}
            </div>

            <div className='navbar-item item-center'>
              <div
                className='menu-overlay'
                ref={menuOverlayRef}
                onClick={toggleMenu}
              ></div>
              <nav className='menu' ref={menuRef}>
                <div className='mobile-menu-head' ref={mobileMenuHeadRef}>
                  <div
                    className='go-back'
                    ref={goBackRef}
                    onClick={hideSubMenu}
                  >
                    <i className='fa fa-angle-left'></i>
                  </div>

                  <div
                    className='current-menu-title'
                    ref={currentMenuTitleRef}
                  ></div>

                  <div
                    className='mobile-menu-close'
                    ref={closeMenuRef}
                    onClick={toggleMenu}
                  >
                    &times;
                  </div>
                </div>
                <ul
                  className='menu-main'
                  ref={menuMainRef}
                  onClick={handleMenuMainClick}
                >
                  <li>
                    <a href='/'>Home</a>
                  </li>

                  <li
                    className='menu-item-has-children'
                    ref={menuItemHasChildrenRef}
                  >
                    <a href='#/'>
                      About <i className='fas fa-angle-down'></i>
                    </a>
                    <div
                      className='sub-menu single-column-menu'
                      ref={subMenuRef}
                    >
                      <ul>
                        <li>
                          <Link to='/about'>About Us</Link>
                        </li>
                        <li>
                          <Link to='/services'>Services</Link>
                        </li>
                        <li>
                          <Link to='/new-client-application'>
                            New Client Application
                          </Link>
                        </li>
                        <li>
                          <Link to='/bid-opportunities'>Bid Opportunities</Link>
                        </li>
                        <li>
                          <Link to='/upcoming-events'>Upcoming Events</Link>
                        </li>
                        <li>
                          <Link to='/technical-resources'>
                            Technical Resources
                          </Link>
                        </li>
                        <li>
                          <Link to='/faq'>FAQ</Link>
                        </li>
                        <li>
                          <Link to='/library'>Library</Link>
                        </li>
                        <li>
                          <Link to='/success-stories'>Success Stories</Link>
                        </li>
                        <li>
                          <Link to='/news'>News</Link>
                        </li>
                        <li>
                          <Link to='/contracting-news'>Contracting News</Link>
                        </li>

                        <li>
                          <Link to='/collabrative-partners'>
                            Collabrative Partners
                          </Link>
                        </li>
                        <li>
                          <a
                            href='https://www.dcsa.mil/'
                            target='_blank'
                            rel='noreferrer'
                          >
                            FOCI Compliance
                          </a>
                        </li>
                        <li>
                          <Link to='/cybersecurity'>
                            Small Business Cybersecurity
                          </Link>
                        </li>
                        <li>
                          <a
                            href='https://www.businessdefense.gov'
                            target='_blank'
                            rel='noreferrer'
                          >
                            Defense Production Act Title III
                          </a>
                        </li>

                        <li>
                          <Link to='/customer-feedback'>Customer Feedback</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href='https://www.ms.gov/dfa/contract_bid_search'
                      target='_blank'
                      rel='noreferrer'
                    >
                      MAGIC
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://agencybidbank.mississippi.org/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Agency Bid Bank
                    </a>
                  </li>
                  <li
                    className='menu-item-has-children'
                    ref={menuItemHasChildrenRef}
                  >
                    <a href='contact-us'>
                      Contact <i className='fas fa-angle-down'></i>
                    </a>
                    <div
                      className='sub-menu single-column-menu'
                      ref={subMenuRef}
                    >
                      <ul>
                        <li>
                          <Link to='/find-us'>Find Us</Link>
                        </li>
                        <li>
                          <Link to='/contact-us'>Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>

            <div className='navbar-item item-right'>
              <div
                className='mobile-menu-trigger'
                ref={menuTriggerRef}
                onClick={toggleMenu}
              >
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
