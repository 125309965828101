import NewClientApplication from '../components/newclient/NewClientApplication';

import FeContainer from '../components/fecontainer/FeContainer';

const NewClientApplicationPage = () => {
  return (
    <FeContainer>
      <NewClientApplication />
    </FeContainer>
  );
};

export default NewClientApplicationPage;
