import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listLibraryItems } from '../../../redux/actions/libraryActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './library.css';

const Library = () => {
  const dispatch = useDispatch();

  const libraryList = useSelector((state) => state.libraryList);
  const { loading, error, libraryitems } = libraryList;

  useEffect(() => {
    dispatch(listLibraryItems());
  }, [dispatch]);

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img src='/assets/images/library.jpg' alt='About Banner' />
          </div>
          <h2 className='innerpagetitle'>Library</h2>

          <div className='innerpagecontent'>
            <div className='librarygrid'>
              <>
                <div className='libset'>
                  <h2 className='libhead'>Resources</h2>
                  {libraryitems.map((libraryitem) => (
                    <div className='liblink' key={libraryitem._id}>
                      {libraryitem.category === 'RESOURCES' ? (
                        <a
                          href={libraryitem.resource}
                          target='_blank'
                          rel='noreferrer'
                          download
                        >
                          <i className='fa fa-arrow-circle-right'></i>{' '}
                          {libraryitem.title} <i className='fa fa-download'></i>{' '}
                        </a>
                      ) : null}
                    </div>
                  ))}
                </div>

                <div className='libset'>
                  <h2 className='libhead'>Model Contracting</h2>
                  {libraryitems.map((libraryitem) => (
                    <div className='liblink' key={libraryitem._id}>
                      {libraryitem.category === 'MODEL CONTRACTING' ? (
                        <a
                          href={libraryitem.resource}
                          target='_blank'
                          rel='noreferrer'
                          download
                        >
                          <i className='fa fa-arrow-circle-right'></i>{' '}
                          {libraryitem.title} <i className='fa fa-download'></i>{' '}
                        </a>
                      ) : null}
                    </div>
                  ))}
                </div>

                <div className='libset'>
                  <h2 className='libhead'>SBA 8(a) and HubZone</h2>
                  {libraryitems.map((libraryitem) => (
                    <div className='liblink' key={libraryitem._id}>
                      {libraryitem.category === 'SBA 8(A) AND HUBZONE' ? (
                        <a
                          href={libraryitem.resource}
                          target='_blank'
                          rel='noreferrer'
                          download
                        >
                          <i className='fa fa-arrow-circle-right'></i>{' '}
                          {libraryitem.title} <i className='fa fa-download'></i>{' '}
                        </a>
                      ) : null}
                    </div>
                  ))}
                </div>

                <div className='libset'>
                  <h2 className='libhead'>NASA Industry Day</h2>
                  {libraryitems.map((libraryitem) => (
                    <div className='liblink' key={libraryitem._id}>
                      {libraryitem.category === 'NASA INDUSTRY DAY' ? (
                        <a
                          href={libraryitem.resource}
                          target='_blank'
                          rel='noreferrer'
                          download
                        >
                          <i className='fa fa-arrow-circle-right'></i>{' '}
                          {libraryitem.title} <i className='fa fa-download'></i>{' '}
                        </a>
                      ) : null}
                    </div>
                  ))}
                </div>
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Library;
