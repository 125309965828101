export const HOMEPAGE_DATA_REQUEST = 'HOMEPAGE_DATA_REQUEST';
export const HOMEPAGE_DATA_SUCCESS = 'HOMEPAGE_DATA_SUCCESS';
export const HOMEPAGE_DATA_FAIL = 'HOMEPAGE_DATA_FAIL';

export const HOMEPAGE_DETAILS_SUCCESS = 'HOMEPAGE_DETAILS_SUCCESS';
export const HOMEPAGE_DETAILS_REQUEST = 'HOMEPAGE_DETAILS_REQUEST';
export const HOMEPAGE_DETAILS_FAIL = 'HOMEPAGE_DETAILS_FAIL';
export const HOMEPAGE_DETAILS_RESET = 'HOMEPAGE_DETAILS_RESET';

export const HOMEPAGE_UPDATE_REQUEST = 'HOMEPAGE_UPDATE_REQUEST';
export const HOMEPAGE_UPDATE_SUCCESS = 'HOMEPAGE_UPDATE_SUCCESS';
export const HOMEPAGE_UPDATE_FAIL = 'HOMEPAGE_UPDATE_FAIL';
export const HOMEPAGE_UPDATE_RESET = 'HOMEPAGE_UPDATE_RESET';
