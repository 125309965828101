import SuccessStoryNew from '../components/successstorynew/SuccessStoryNew';

import FeContainer from '../components/fecontainer/FeContainer';

const SuccessStoryNewPage = () => {
  return (
    <FeContainer>
      <SuccessStoryNew />
    </FeContainer>
  );
};

export default SuccessStoryNewPage;
