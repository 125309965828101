import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { addBO } from '../../../redux/actions/bidopportunitiesActions';
import { BO_ADD_RESET } from '../../../redux/constants/bidopportunitesConstants';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const BidOpportunitesAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const [bidtype, setBidtype] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  const boAdd = useSelector((state) => state.boAdd);
  const { loading, error, newBO } = boAdd;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addBO(bidtype, title, link));
  };

  useEffect(() => {
    dispatch({ type: BO_ADD_RESET });
    if (newBO) {
      navigate('/admin/bidopportunities');
    }
  }, [dispatch, navigate, newBO]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Add New Bid Opportunity</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='dataitems'>
              <div>
                <label htmlFor='bidtype'>Bid Type (General or Federal)</label>
                <input
                  type='text'
                  id='bidtype'
                  placeholder='Enter New Bid Opportunity'
                  required
                  onChange={(e) => setBidtype(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='title'>Bid Opportunity Title</label>
                <input
                  type='text'
                  id='title'
                  placeholder='Enter New Bid Opportunity Title'
                  required
                  onChange={(e) => setTitle(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='link'>Bid Link</label>
                <input
                  type='text'
                  id='link'
                  placeholder='Enter New Bid Opportunity Link'
                  required
                  onChange={(e) => setLink(e.target.value)}
                ></input>
              </div>

              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default BidOpportunitesAdd;
