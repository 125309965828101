import CollabrativePartners from '../components/collabrativepartners/CollabrativePartners';
import FeContainer from '../components/fecontainer/FeContainer';

const CollabrativePartnersPage = () => {
  return (
    <FeContainer>
      <CollabrativePartners />
    </FeContainer>
  );
};

export default CollabrativePartnersPage;
