import AdContainer from '../../components/adcontainer/AdContainer';
import UpcomingEventEdit from '../../components/upcomingevents/UpcomingEventEdit';

const UpcomingEventEditScreen = () => {
  return (
    <AdContainer>
      <UpcomingEventEdit />
    </AdContainer>
  );
};

export default UpcomingEventEditScreen;
