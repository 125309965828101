import AdContainer from '../../components/adcontainer/AdContainer';
import LibraryAdd from '../../components/library/LibraryAdd';

const LibraryAddScreen = () => {
  return (
    <AdContainer>
      <LibraryAdd />
    </AdContainer>
  );
};

export default LibraryAddScreen;
