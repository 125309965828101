import FeContainer from '../components/fecontainer/FeContainer';
import NewsDetails from '../components/news/NewsDetails';

const NewsDetailsPage = () => {
  return (
    <FeContainer>
      <NewsDetails />
    </FeContainer>
  );
};

export default NewsDetailsPage;
