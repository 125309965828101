import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import DOMPurify from 'dompurify';

import { getNewsDetails } from '../../../redux/actions/newsActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './news.css';

const NewsDetails = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const newsId = params.id;

  const newsDetails = useSelector((state) => state.newsDetails);
  const { loading, error, news } = newsDetails;

  useEffect(() => {
    if (news._id !== newsId) dispatch(getNewsDetails(newsId));
  }, [dispatch, newsId]);

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img src='/assets/images/construction01.jpg' alt='About Banner' />
          </div>
          <h2 className='innerpagetitle'>Detailed News</h2>
          <div className='innerpagecontent'>
            <h3 className='detailsnewsheading'>{news.heading}</h3>
            <h3 className='detailsnewsdate'>
              Dated : {moment(news.createdAt).format('MMMM Do YYYY')}.
            </h3>{' '}
            <p
              className='detailsnewscontent'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(news.newscontent),
              }}
            ></p>
            <Link to='/news'>
              <button className='backtonews'>News List</button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default NewsDetails;
