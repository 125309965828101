export const SERVICES_DATA_REQUEST = 'SERVICES_DATA_REQUEST';
export const SERVICES_DATA_SUCCESS = 'SERVICES_DATA_SUCCESS';
export const SERVICES_DATA_FAIL = 'SERVICES_DATA_FAIL';

export const SERVICES_DETAILS_SUCCESS = 'SERVICES_DETAILS_SUCCESS';
export const SERVICES_DETAILS_REQUEST = 'SERVICES_DETAILS_REQUEST';
export const SERVICES_DETAILS_FAIL = 'SERVICES_DETAILS_FAIL';
export const SERVICES_DETAILS_RESET = 'SERVICES_DETAILS_RESET';

export const SERVICES_UPDATE_REQUEST = 'SERVICES_UPDATE_REQUEST';
export const SERVICES_UPDATE_SUCCESS = 'SERVICES_UPDATE_SUCCESS';
export const SERVICES_UPDATE_FAIL = 'SERVICES_UPDATE_FAIL';
export const SERVICES_UPDATE_RESET = 'SERVICES_UPDATE_RESET';
