import axios from 'axios';

import {
  EVENT_ADD_REQUEST,
  EVENT_ADD_SUCCESS,
  EVENT_ADD_FAIL,
  EVENTS_LIST_REQUEST,
  EVENTS_LIST_SUCCESS,
  EVENTS_LIST_FAIL,
  EVENT_DETAILS_REQUEST,
  EVENT_DETAILS_SUCCESS,
  EVENT_DETAILS_FAIL,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAIL,
  EVENT_DELETE_REQUEST,
  EVENT_DELETE_SUCCESS,
  EVENT_DELETE_FAIL,
} from '../constants/eventConstants';

import { logout } from './userActions';

export const addEvent =
  (
    eventtitle,
    mptapcenter,
    starttime,
    endtime,
    eventvenue,
    addressline1,
    addressline2,
    addressline3,
    addressline4,
    state,
    city,
    zip,
    eventdetails,
    eventwebsite,
    eventcost,
    contactperson,
    contactphone,
    contactemail,
    eventtags,
    resource
  ) =>
  async (dispatch, getState) => {
    console.log('addNews action');
    try {
      dispatch({
        type: EVENT_ADD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        '/api/events',
        {
          eventtitle,
          mptapcenter,
          starttime,
          endtime,
          eventvenue,
          addressline1,
          addressline2,
          addressline3,
          addressline4,
          state,
          city,
          zip,
          eventdetails,
          eventwebsite,
          eventcost,
          contactperson,
          contactphone,
          contactemail,
          eventtags,
          resource,
        },
        config
      );

      dispatch({
        type: EVENT_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === 'Not authorized, token failed') {
        dispatch(logout());
      }
      dispatch({
        type: EVENT_ADD_FAIL,
        payload: message,
      });
    }
  };

export const listEvents = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENTS_LIST_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get('/api/events', config);

    dispatch({
      type: EVENTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: EVENTS_LIST_FAIL,
      payload: message,
    });
  }
};

export const getEventDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/events/${id}`, config);

    dispatch({
      type: EVENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: EVENT_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateEvent = (event) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/events/${event._id}`, event, config);

    dispatch({
      type: EVENT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: EVENT_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const deleteEvent = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/events/${id}`, config);

    dispatch({ type: EVENT_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: EVENT_DELETE_FAIL,
      payload: message,
    });
  }
};
