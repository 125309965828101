import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  listTRs,
  deleteTR,
} from '../../../redux/actions/technicalresrourcesActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const TechnicalResourcesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const trList = useSelector((state) => state.trList);
  const { loading, error, trs } = trList;

  const trDelete = useSelector((state) => state.trDelete);
  const { success: successDelete } = trDelete;

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteTR(id));
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listTRs());
    } else {
      navigate('/admin');
    }
  }, [dispatch, navigate, successDelete, userInfo]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>Technical Resources</h2>
          <Link to='/admin/technical-resources/add'>
            <button>
              <i className='fa fa-plus'></i> Add New Technical Resource
            </button>
          </Link>
        </div>
        <div className='screenlist'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>Resource Type</th>
                  <th>Title</th>
                  <th>Link</th>
                  <th>Delete</th>
                </tr>
              </thead>

              <tbody>
                {trs.map((tr) => (
                  <tr key={tr._id}>
                    <>
                      <td>{tr.category}</td>
                      <td>{tr.title}</td>
                      <td>{tr.link}</td>
                      <td>
                        <button
                          className='small'
                          onClick={() => deleteHandler(tr._id)}
                        >
                          <i className='fa fa-trash'></i>
                        </button>
                      </td>
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default TechnicalResourcesList;
