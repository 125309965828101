import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { listFooterSuccessStories } from '../../../redux/actions/successstoryActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './footer.css';

const Footer = () => {
  const dispatch = useDispatch();

  const successStoryFooterList = useSelector(
    (state) => state.successStoryFooterList
  );
  const { loading, error, successstories } = successStoryFooterList;

  useEffect(() => {
    dispatch(listFooterSuccessStories());
  }, [dispatch]);

  return (
    <div className='maingrid'>
      <footer className='footer'>
        <div className='implinks'>
          <div className='title'>
            <h2>Important Links</h2>
          </div>
          <ul>
            <li>
              <a href='https://sam.gov' target='_blank' rel='noreferrer'>
                System of Award Management (SAM) Registration
              </a>
            </li>
            <li>
              <a href='https://www.dnb.com/' target='_blank' rel='noreferrer'>
                Dun & Bradstreet
              </a>
            </li>
            <li>
              <a href='https://www.sba.gov/' target='_blank' rel='noreferrer'>
                U.S. Small Business Administration
              </a>
            </li>
            <li>
              <a
                href='https://www.dla.mil/SmallBusiness/PTAP/'
                target='_blank'
                rel='noreferrer'
              >
                DLA Small Business
              </a>
            </li>
            <li>
              <a
                href='https://mississippi.org/'
                target='_blank'
                rel='noreferrer'
              >
                Mississippi Development Authority
              </a>
            </li>
            <li>
              <a
                href='https://www.aptac-us.org/'
                target='_blank'
                rel='noreferrer'
              >
                Association on Procurement Technical Assistance Centers (APTAC)
              </a>
            </li>
            <li>
              <a
                href='https://www.va.gov/osdbu/verification/'
                target='_blank'
                rel='noreferrer'
              >
                US Veteran Office of Small & Disadvantage Business
              </a>
            </li>
            <li>
              <a
                href='https://business.defense.gov/'
                target='_blank'
                rel='noreferrer'
              >
                Department of Defense (DOD) Office of Small Business
              </a>
            </li>
            <li>
              <a href='https://www.gsa.gov/' target='_blank' rel='noreferrer'>
                U.S General Services Administration
              </a>
            </li>
            <li>
              <a
                href='https://www.sos.ms.gov/'
                target='_blank'
                rel='noreferrer'
              >
                Secretary of State
              </a>
            </li>
          </ul>
        </div>

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <div className='successstories'>
            <div className='title'>
              <h2>Success Stories</h2>
            </div>
            <div className='storyheading'>
              {successstories.length !== 0
                ? successstories.map((successstory) => (
                    <div key={successstory._id} className='thesuccessstory'>
                      <h3>{successstory.businessname}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: successstory.issuedescription,
                        }}
                      />
                    </div>
                  ))
                : console.log('No Success Stories Available')}
            </div>
            <Link to='/success-stories'>
              <button className='buttonred'>Read More Success Stories</button>
            </Link>
          </div>
        )}

        <div className='contactaddress'>
          <div className='title'>
            <h2>Contact Address</h2>
          </div>
          <div className='mdaaddress'>
            <div className='storyheading'>
              Mississippi Development Authority
            </div>
            <p>P.O Box 849</p>
            <p>Jackson, MS 39205</p>
            <p>Call: (601) 359-3448</p>
            <p>Fax: (601) 359-5290</p>
          </div>

          <div className='socialicons'>
            <a
              href='https://www.facebook.com/MSPTAP/'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fa-brands fa-facebook'></i>
            </a>
            <a
              href='https://twitter.com/askmptap'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fa-brands fa-twitter-square'></i>
            </a>
            <a
              href='https://www.linkedin.com/in/msptac-ms-procurement-tech-assistance-prog-002a3920b/'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fa-brands fa-linkedin'></i>
            </a>
            <a
              href='https://www.youtube.com/watch?v=WjHoC55ScT0'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fa-brands fa-youtube'></i>
            </a>
            {/* <a
              href='https://www.youtube.com/watch?v=_mX88Sr18x8'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fa-brands fa-youtube'></i>
            </a> */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
