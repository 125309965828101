import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

import { addCNews } from '../../../redux/actions/contractingnewsActions';
import { CNEWS_ADD_RESET } from '../../../redux/constants/contractingnewsConstants';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const ContractingNewsAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const [heading, setHeading] = useState('');
  const [category, setCategory] = useState('');
  const [contractingnewscontent, setContractingNewsContent] = useState('');

  const cnewsAdd = useSelector((state) => state.cnewsAdd);
  const { loading, error, newCNews } = cnewsAdd;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addCNews(category, heading, contractingnewscontent));
  };

  useEffect(() => {
    dispatch({ type: CNEWS_ADD_RESET });
    if (newCNews) {
      navigate('/admin/news');
    }
  }, [dispatch, navigate, newCNews]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Add Contracting News</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='dataitems'>
              <div>
                <label htmlFor='cnewscategory'>Contracting News Category</label>
                <input
                  type='text'
                  id='cnewscategory'
                  placeholder='Enter contracting news category'
                  required
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                ></input>
              </div>
              <div>
                <label htmlFor='cnewsheading'>Contracting News Title</label>
                <input
                  type='text'
                  id='cnewsheading'
                  placeholder='Enter contracting news title'
                  required
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='newscontent'>Contracting News Content</label>
                <CKEditor
                  editor={Editor}
                  data={contractingnewscontent}
                  config={EDITOR_CONFIGURATION}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContractingNewsContent(data);
                  }}
                />
              </div>

              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContractingNewsAdd;
