import FeContainer from '../components/fecontainer/FeContainer';
import About from '../components/about/About';

const AboutPage = () => {
  return (
    <FeContainer>
      <About />
    </FeContainer>
  );
};

export default AboutPage;
