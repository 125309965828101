import FeContainer from '../components/fecontainer/FeContainer';
import CyberSecurity from '../components/cybersecurity/CyberSecurity';

const CyberSecurityPage = () => {
  return (
    <FeContainer>
      <CyberSecurity />
    </FeContainer>
  );
};

export default CyberSecurityPage;
