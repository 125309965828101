import axios from 'axios';
import {
  CS_DATA_REQUEST,
  CS_DATA_SUCCESS,
  CS_DATA_FAIL,
  CS_DETAILS_REQUEST,
  CS_DETAILS_SUCCESS,
  CS_DETAILS_FAIL,
  CS_UPDATE_REQUEST,
  CS_UPDATE_SUCCESS,
  CS_UPDATE_FAIL,
} from '../constants/cybersecurityConstants';

import { logout } from './userActions';

export const getCS = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CS_DATA_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/cybersecurity`, config);

    dispatch({
      type: CS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: CS_DATA_FAIL,
      payload: message,
    });
  }
};

export const getCSDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CS_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/cybersecurity', config);

    dispatch({
      type: CS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: CS_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateCS = (cybersecurity) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CS_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      '/api/cybersecurity',
      cybersecurity,
      config
    );

    dispatch({ type: CS_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: CS_UPDATE_FAIL,
      payload: message,
    });
  }
};
