import CollabrativePartners from '../../components/collabrativepartners/CollabrativePartners';
import AdContainer from '../../components/adcontainer/AdContainer';

const CollabrativePartnersScreen = () => {
  return (
    <AdContainer>
      <CollabrativePartners />
    </AdContainer>
  );
};

export default CollabrativePartnersScreen;
