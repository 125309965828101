import { BsArrowRightCircleFill } from 'react-icons/bs';

import './collabrativepartners.css';

const CollabrativePartners = () => {
  return (
    <div className='maingrid'>
      <div className='innerbannerimage'>
        <img
          src='/assets/images/teamwork-3213924_1920.jpg'
          alt='Collabrative Partners Banner'
        />
      </div>
      <h2 className='innerpagetitle'>Collabrative Partners</h2>
      <div className='innerpagecontent'>
        <div className='cpinnerpagecontent'>
          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>SBA MS Small Business Development Centers</p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>
              Women’s Business Centers (Jackson State University, Alcorn State
              University)
            </p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>
              Veteran Business Outreach Centers (Mississippi State, Columbus,
              MS)
            </p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>
              Manufacturing Extension Partnerships (Mississippi State
              University)
            </p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>
              MS Manufacturing Association/Manufacturing Extension Partnership
              (MEP)
            </p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>
              Minority Business Development Agency (MBDA) Jackson HBCUs
              (State-wide)
            </p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>Mississippi Enterprise for Technology (MSET)</p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>Innovate Mississippi (SBIR/STTR)</p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>MS Center for Innovation & Technology (MCITy)</p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>MS Defense Initiative (University of Southern Mississippi)</p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>University of Southern MS-Trent Lott Center</p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>
              City of Meridian, Office of Economic Development, Mayor’s Office
            </p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>Tennessee Tombigbee Waterway Development Authority</p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>Jackson State University- HBCU Entrepreneur’s Week</p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>Mississippi Department of Finance and Administration</p>
          </div>

          <div>
            <span>
              <strong>
                <BsArrowRightCircleFill />
              </strong>
            </span>
            <p>More...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollabrativePartners;
