import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import DOMPurify from 'dompurify';

import { getCNewsDetails } from '../../../redux/actions/contractingnewsActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './contractingnews.css';

const ContractingNewsDetails = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const cnewsId = params.id;

  const cnewsDetails = useSelector((state) => state.cnewsDetails);
  const { loading, error, cnews } = cnewsDetails;

  useEffect(() => {
    if (cnews._id !== cnewsId) dispatch(getCNewsDetails(cnewsId));
  }, [dispatch, cnewsId]);

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img src='/assets/images/construction01.jpg' alt='About Banner' />
          </div>
          <h2 className='innerpagetitle'>Detailed Contracting News</h2>
          <div className='innerpagecontent'>
            <h3 className='detailsnewsheading'>{cnews.heading}</h3>
            <h3 className='detailsnewsdate'>
              Dated : {moment(cnews.createdAt).format('MMMM Do YYYY')}.
            </h3>{' '}
            <p
              className='detailsnewscontent'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(cnews.contractingnewscontent),
              }}
            ></p>
            <Link to='/contracting-news'>
              <button className='backtonews'>Contracting News List</button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default ContractingNewsDetails;
