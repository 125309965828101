import AdContainer from '../../components/adcontainer/AdContainer';
import FaqEdit from '../../components/faqs/FaqEdit';

const FaqEditScreen = () => {
  return (
    <AdContainer>
      <FaqEdit />
    </AdContainer>
  );
};

export default FaqEditScreen;
