import AdContainer from '../../components/adcontainer/AdContainer';
import Services from '../../components/services/Services';

const ServicesScreen = () => {
  return (
    <AdContainer>
      <Services />
    </AdContainer>
  );
};

export default ServicesScreen;
