import TechnicalResources from '../components/technicalresources/TechnicalResources';

import FeContainer from '../components/fecontainer/FeContainer';

const TechnicalResourcesPage = () => {
  return (
    <FeContainer>
      <TechnicalResources />
    </FeContainer>
  );
};

export default TechnicalResourcesPage;
