import axios from 'axios';
import {
  CNEWS_ADD_REQUEST,
  CNEWS_ADD_SUCCESS,
  CNEWS_ADD_FAIL,
  CNEWS_LIST_REQUEST,
  CNEWS_LIST_SUCCESS,
  CNEWS_LIST_FAIL,
  CNEWS_DETAILS_REQUEST,
  CNEWS_DETAILS_SUCCESS,
  CNEWS_DETAILS_FAIL,
  CNEWS_UPDATE_REQUEST,
  CNEWS_UPDATE_SUCCESS,
  CNEWS_UPDATE_FAIL,
  CNEWS_DELETE_REQUEST,
  CNEWS_DELETE_SUCCESS,
  CNEWS_DELETE_FAIL,
} from '../constants/contractingnewsConstants';
import { logout } from './userActions';

export const addCNews =
  (category, heading, contractingnewscontent) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CNEWS_ADD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        '/api/contractingnews',
        { category, heading, contractingnewscontent },
        config
      );

      dispatch({
        type: CNEWS_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === 'Not authorized, token failed') {
        dispatch(logout());
      }
      dispatch({
        type: CNEWS_ADD_FAIL,
        payload: message,
      });
    }
  };

export const listCNews = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CNEWS_LIST_REQUEST,
    });

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/contractingnews', config);

    dispatch({
      type: CNEWS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: CNEWS_LIST_FAIL,
      payload: message,
    });
  }
};

export const getCNewsDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CNEWS_DETAILS_REQUEST,
    });

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // };

    // const { data } = await axios.get(`/api/contractingnews/${id}`, config);
    const { data } = await axios.get(`/api/contractingnews/${id}`);

    dispatch({
      type: CNEWS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: CNEWS_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateCNews = (contractingnews) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CNEWS_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/contractingnews/${contractingnews._id}`,
      contractingnews,
      config
    );

    dispatch({
      type: CNEWS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: CNEWS_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const deleteCNews = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CNEWS_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/contractingnews/${id}`, config);

    dispatch({ type: CNEWS_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: CNEWS_DELETE_FAIL,
      payload: message,
    });
  }
};
