import AdContainer from '../../components/adcontainer/AdContainer';
import RegisterNewUser from '../../components/users/RegisterNewUser';

const RegisterNewUserScreen = () => {
  return (
    <AdContainer>
      <RegisterNewUser />
    </AdContainer>
  );
};

export default RegisterNewUserScreen;
