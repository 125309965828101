import AdContainer from '../../components/adcontainer/AdContainer';
import UpcomingEventsAdd from '../../components/upcomingevents/UpcomingEventsAdd';

const UpcomingEventsAddScreen = () => {
  return (
    <AdContainer>
      <UpcomingEventsAdd />
    </AdContainer>
  );
};

export default UpcomingEventsAddScreen;
