import axios from 'axios';
import {
  OFFICE_LIST_REQUEST,
  OFFICE_LIST_SUCCESS,
  OFFICE_LIST_FAIL,
  OFFICE_DETAILS_REQUEST,
  OFFICE_DETAILS_SUCCESS,
  OFFICE_DETAILS_FAIL,
  OFFICE_UPDATE_REQUEST,
  OFFICE_UPDATE_SUCCESS,
  OFFICE_UPDATE_FAIL,
} from '../constants/officeConstants';

import { logout } from './userActions';

export const listOffices = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: OFFICE_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/offices', config);

    dispatch({
      type: OFFICE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: OFFICE_LIST_FAIL,
      payload: message,
    });
  }
};

export const getOfficeDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: OFFICE_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/offices/${id}`, config);

    dispatch({
      type: OFFICE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: OFFICE_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateOffice = (office) => async (dispatch, getState) => {
  console.log('Going to update news');
  try {
    dispatch({
      type: OFFICE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/offices/${office._id}`,
      office,
      config
    );

    dispatch({
      type: OFFICE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: OFFICE_UPDATE_FAIL,
      payload: message,
    });
  }
};
