import axios from 'axios';

import {
  SUCCESSSTORY_ADD_REQUEST,
  SUCCESSSTORY_ADD_SUCCESS,
  SUCCESSSTORY_ADD_FAIL,
  SUCCESSSTORY_LIST_REQUEST,
  SUCCESSSTORY_LIST_SUCCESS,
  SUCCESSSTORY_LIST_FAIL,
  SUCCESSSTORY_FOOTER_LIST_REQUEST,
  SUCCESSSTORY_FOOTER_LIST_SUCCESS,
  SUCCESSSTORY_FOOTER_LIST_FAIL,
  SUCCESSSTORY_DETAILS_REQUEST,
  SUCCESSSTORY_DETAILS_SUCCESS,
  SUCCESSSTORY_DETAILS_FAIL,
  SUCCESSSTORY_UPDATE_REQUEST,
  SUCCESSSTORY_UPDATE_SUCCESS,
  SUCCESSSTORY_UPDATE_FAIL,
  SUCCESSSTORY_DELETE_REQUEST,
  SUCCESSSTORY_DELETE_SUCCESS,
  SUCCESSSTORY_DELETE_FAIL,
} from '../constants/successstoryConstants';

import { logout } from './userActions';

export const addSuccessStory =
  (
    businessname,
    pointofcontact,
    businessaddress,
    businesscity,
    businessstate,
    businesszip,
    businessphone,
    businessfax,
    businessemail,
    procurementcontractaward,
    procurementcontractissue,
    issuedescription,
    noofjobs,
    authorizedtopublish,
    successstoryattest,
    showsuccessstory,
    footersuccessstory
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SUCCESSSTORY_ADD_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        '/api/successstories',
        {
          businessname,
          pointofcontact,
          businessaddress,
          businesscity,
          businessstate,
          businesszip,
          businessphone,
          businessfax,
          businessemail,
          procurementcontractaward,
          procurementcontractissue,
          issuedescription,
          noofjobs,
          authorizedtopublish,
          successstoryattest,
          showsuccessstory,
          footersuccessstory,
        },
        config
      );

      dispatch({
        type: SUCCESSSTORY_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === 'Not authorized, token failed') {
        dispatch(logout());
      }
      dispatch({
        type: SUCCESSSTORY_ADD_FAIL,
        payload: message,
      });
    }
  };

export const listSuccessStories = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUCCESSSTORY_LIST_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get('/api/successstories', config);

    dispatch({
      type: SUCCESSSTORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: SUCCESSSTORY_LIST_FAIL,
      payload: message,
    });
  }
};

export const listFooterSuccessStories = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUCCESSSTORY_FOOTER_LIST_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      '/api/successstories/successstory/footer',
      config
    );

    dispatch({
      type: SUCCESSSTORY_FOOTER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: SUCCESSSTORY_FOOTER_LIST_FAIL,
      payload: message,
    });
  }
};

export const getSuccessStoryDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUCCESSSTORY_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/successstories/${id}`, config);

    dispatch({
      type: SUCCESSSTORY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: SUCCESSSTORY_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateSuccessStory =
  (successstory) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUCCESSSTORY_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/successstories/${successstory._id}`,
        successstory,
        config
      );

      dispatch({
        type: SUCCESSSTORY_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === 'Not authorized, token failed') {
        dispatch(logout());
      }
      dispatch({
        type: SUCCESSSTORY_UPDATE_FAIL,
        payload: message,
      });
    }
  };

export const deleteSuccessStory = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUCCESSSTORY_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/successstories/${id}`, config);

    dispatch({ type: SUCCESSSTORY_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: SUCCESSSTORY_DELETE_FAIL,
      payload: message,
    });
  }
};
