import axios from 'axios';

import {
  FAQ_ADD_REQUEST,
  FAQ_ADD_SUCCESS,
  FAQ_ADD_FAIL,
  FAQ_LIST_FAIL,
  FAQ_LIST_REQUEST,
  FAQ_LIST_SUCCESS,
  FAQ_DETAILS_REQUEST,
  FAQ_DETAILS_SUCCESS,
  FAQ_DETAILS_FAIL,
  FAQ_DETAILS_RESET,
  FAQ_UPDATE_REQUEST,
  FAQ_UPDATE_SUCCESS,
  FAQ_UPDATE_FAIL,
  FAQ_DELETE_REQUEST,
  FAQ_DELETE_SUCCESS,
  FAQ_DELETE_FAIL,
} from '../constants/faqConstants';
import { logout } from './userActions';

export const addFaq = (thequery, theresponse) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FAQ_ADD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      '/api/faqs',
      { thequery, theresponse },
      config
    );

    dispatch({
      type: FAQ_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FAQ_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listFaqs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FAQ_LIST_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/faqs`, config);

    dispatch({
      type: FAQ_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: FAQ_LIST_FAIL,
      payload: message,
    });
  }
};

export const updateFaq = (faq) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FAQ_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/faqs/${faq._id}`, faq, config);

    dispatch({ type: FAQ_UPDATE_SUCCESS, payload: data });

    dispatch({ type: FAQ_DETAILS_SUCCESS, payload: data });

    dispatch({ type: FAQ_DETAILS_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: FAQ_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const getFaqDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FAQ_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/faqs/${id}`, config);

    dispatch({
      type: FAQ_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: FAQ_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const deleteFaq = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FAQ_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/faqs/${id}`, config);

    dispatch({ type: FAQ_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: FAQ_DELETE_FAIL,
      payload: message,
    });
  }
};
