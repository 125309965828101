import AdContainer from '../../components/adcontainer/AdContainer';
import BidOpportunitiesList from '../../components/bidopportunities/BidOpportunitiesList';

const BidOpportunitiesListScreen = () => {
  return (
    <AdContainer>
      <BidOpportunitiesList />
    </AdContainer>
  );
};

export default BidOpportunitiesListScreen;
