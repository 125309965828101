import AdContainer from '../../components/adcontainer/AdContainer';
import CustomerFeedbackDetails from '../../components/customerfeedback/CustomerFeedbackDetails';

const CustomerFeedbackDetailsScreen = () => {
  return (
    <AdContainer>
      <CustomerFeedbackDetails />
    </AdContainer>
  );
};

export default CustomerFeedbackDetailsScreen;
