import AdContainer from '../../components/adcontainer/AdContainer';
import NewsList from '../../components/news/NewsList';

const NewsListScreen = () => {
  return (
    <AdContainer>
      <NewsList />
    </AdContainer>
  );
};

export default NewsListScreen;
