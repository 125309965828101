import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { listEvents } from '../../../redux/actions/eventActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './upcomingevents.css';

const UpcomingEvents = () => {
  const dispatch = useDispatch();

  const eventsList = useSelector((state) => state.eventsList);
  const { loading, error, events } = eventsList;

  useEffect(() => {
    dispatch(listEvents());
  }, [dispatch]);

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img
              src='/assets/images/calendar-3045963_1280.jpg'
              alt='About Banner'
            />
          </div>
          <h2 className='innerpagetitle'>Upcoming Events</h2>
          <div className='innerpagecontent'>
            {events.map((event) => (
              <div key={event._id}>
                <div className='eventcontainer'>
                  <div className='eventtitle'>Event Title</div>
                  <div className='eventdetail'>{event.eventtitle}</div>
                  <div className='eventtitle'>Event Schedule</div>
                  <div className='eventdetail'>
                    {moment(event.starttime).format('MMMM Do YYYY, h:mm a')}{' '}
                    {event.endtime !== null
                      ? ' to' + moment(event.endtime).format('h:mm a')
                      : null}
                    {/* to {moment(event.endtime).format('h:mm a')} */}
                  </div>
                  <div className='eventtitle'>Event Venue</div>
                  <div className='eventdetail'>{event.eventvenue}</div>
                  <div className='eventtitle'>Event Cost</div>
                  <div className='eventdetail'>
                    {event.eventcost === 0 ? 'Free' : event.eventcost}
                  </div>
                  <div className='eventtitle'>Event Details</div>
                  <div
                    className='eventdetail'
                    dangerouslySetInnerHTML={{
                      __html: event.eventdetails
                        .split(' ')
                        .slice(0, 35)
                        .join(' '),
                    }}
                  ></div>
                  <div className='eventdetailbutton'>
                    <Link to={`/upcoming-event-details/${event._id}`}>
                      <button>Click for complete details</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default UpcomingEvents;
