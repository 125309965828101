import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  listLibraryItems,
  deleteLibraryItem,
} from '../../../redux/actions/libraryActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const LibraryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const libraryList = useSelector((state) => state.libraryList);
  const { loading, error, libraryitems } = libraryList;

  const libraryitemDelete = useSelector((state) => state.libraryitemDelete);
  const { success: successDelete } = libraryitemDelete;

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteLibraryItem(id));
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listLibraryItems());
    } else {
      navigate('/admin');
    }
  }, [dispatch, navigate, successDelete, userInfo]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>Library Items</h2>
          <Link to='/admin/library/add'>
            <button>
              <i className='fa fa-plus'></i> Add New Library Item
            </button>
          </Link>
        </div>
        <div className='screenlist'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Title</th>
                  <th>Delete</th>
                </tr>
              </thead>

              <tbody>
                {libraryitems.map((libraryitem) => (
                  <tr key={libraryitem._id}>
                    {libraryitem.category === 'RESOURCES' ? (
                      <>
                        <td>{libraryitem.category}</td>
                        <td>{libraryitem.title}</td>
                        <td>
                          <button
                            className='small'
                            onClick={() => deleteHandler(libraryitem._id)}
                          >
                            <i className='fa fa-trash'></i>
                          </button>
                        </td>
                      </>
                    ) : null}
                  </tr>
                ))}

                {libraryitems.map((libraryitem) => (
                  <tr key={libraryitem._id}>
                    {libraryitem.category === 'MODEL CONTRACTING' ? (
                      <>
                        <td>{libraryitem.category}</td>
                        <td>{libraryitem.title}</td>
                        <td>
                          <button
                            className='small'
                            onClick={() => deleteHandler(libraryitem._id)}
                          >
                            <i className='fa fa-trash'></i>
                          </button>
                        </td>
                      </>
                    ) : null}
                  </tr>
                ))}

                {libraryitems.map((libraryitem) => (
                  <tr key={libraryitem._id}>
                    {libraryitem.category === 'SBA 8(A) AND HUBZONE' ? (
                      <>
                        <td>{libraryitem.category}</td>
                        <td>{libraryitem.title}</td>
                        <td>
                          <button
                            className='small'
                            onClick={() => deleteHandler(libraryitem._id)}
                          >
                            <i className='fa fa-trash'></i>
                          </button>
                        </td>
                      </>
                    ) : null}
                  </tr>
                ))}

                {libraryitems.map((libraryitem) => (
                  <tr key={libraryitem._id}>
                    {libraryitem.category === 'NASA INDUSTRY DAY' ? (
                      <>
                        <td>{libraryitem.category}</td>
                        <td>{libraryitem.title}</td>
                        <td>
                          <button
                            className='small'
                            onClick={() => deleteHandler(libraryitem._id)}
                          >
                            <i className='fa fa-trash'></i>
                          </button>
                        </td>
                      </>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default LibraryList;
