import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  listBOs,
  deleteBO,
} from '../../../redux/actions/bidopportunitiesActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './bidopportunities.css';

const BidOpportunitiesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const boList = useSelector((state) => state.boList);
  const { loading, error, bos } = boList;

  const boDelete = useSelector((state) => state.boDelete);
  const { success: successDelete } = boDelete;

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteBO(id));
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listBOs());
    } else {
      navigate('/admin');
    }
  }, [dispatch, navigate, userInfo]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>Bid Opportunities</h2>
          <Link to='/admin/bidopportunities/add'>
            <button>
              <i className='fa fa-plus'></i> Add New Bid Opportunity
            </button>
          </Link>
        </div>
        <div className='screenlist'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>Bid Type</th>
                  <th>Title</th>
                  <th>Link</th>
                  <th>Delete</th>
                </tr>
              </thead>

              <tbody>
                {bos.map((bo) => (
                  <tr key={bo._id}>
                    <>
                      <td>{bo.bidtype}</td>
                      <td>{bo.title}</td>
                      <td>{bo.link}</td>
                      <td>
                        <button
                          className='small'
                          onClick={() => deleteHandler(bo._id)}
                        >
                          <i className='fa fa-trash'></i>
                        </button>
                      </td>
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default BidOpportunitiesList;
