import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { listEvents, deleteEvent } from '../../../redux/actions/eventActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const UpcomingEventsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const eventsList = useSelector((state) => state.eventsList);
  const { loading, error, events } = eventsList;

  const eventDelete = useSelector((state) => state.eventDelete);
  const { success: successDelete } = eventDelete;

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteEvent(id));
    }
  };

  useEffect(() => {
    // dispatch({ type: EVENT_ADD_RESET });
    if (userInfo && userInfo.isAdmin) {
      dispatch(listEvents());
    } else {
      navigate('/admin');
    }
  }, [dispatch, navigate, successDelete, userInfo]);

  let sno = 0;

  console.log(events);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>Upcoming Events List</h2>
          <Link to='/admin/events/add'>
            <button>
              <i className='fa fa-plus'></i> Add New Event
            </button>
          </Link>
        </div>
        <div className='screenlist'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Event Title</th>
                  <th>Event Venue</th>
                  <th>Event Date and Time</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {events.map((event) => (
                  <tr key={event._id}>
                    <td>{(sno += 1)}</td>
                    <td>{event.eventtitle}</td>
                    <td>{event.eventvenue}</td>
                    <td>
                      {moment(event.starttime).format('MMMM Do YYYY, h:mm a')}
                    </td>
                    <td>
                      <Link to={`/admin/events/${event._id}/edit`}>
                        <button className='small'>
                          <i className='fa fa-edit'></i>
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className='small'
                        onClick={() => deleteHandler(event._id)}
                      >
                        <i className='fa fa-trash'></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpcomingEventsList;
