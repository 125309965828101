import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Frontend Screens
import {
  AboutPage,
  BidOpportunitiesPage,
  ContactUsPage,
  ContractingNewsDetailsPage,
  ContractingNewsPage,
  CustomerFeedbackPage,
  FAQPage,
  FederalBidsPage,
  FindUsPage,
  HomePage,
  LibraryPage,
  NewClientApplicationPage,
  NewsDetailsPage,
  NewsPage,
  ServicesPage,
  SuccessStoriesPage,
  SuccessStoryNewPage,
  TechnicalResourcesPage,
  UpcomingEventDetailsPage,
  UpcomingEventsPage,
  CollabrativePartnersPage,
  // FociPage,
  CyberSecurityPage,
  // DpaPage,
} from './frontend/pages';

// Admin Screens
import {
  AboutScreen,
  BidOpportunitiesAddScreen,
  BidOpportunitiesListScreen,
  ContactRequestListScreen,
  ContractingNewsAddScreen,
  ContractingNewsEditScreen,
  ContractingNewsListScreen,
  CustomerFeedbackArchivedScreen,
  CustomerFeedbackDetailsScreen,
  CustomerFeedbackListScreen,
  FaqAddScreen,
  FaqEditScreen,
  FaqListScreen,
  LibraryAddScreen,
  LibraryListScreen,
  NewPasswordScreen,
  NewsAddScreen,
  NewsEditScreen,
  NewsListScreen,
  OfficesListScreen,
  RegisterNewUserScreen,
  ResetPasswordScreen,
  ScreenListScreen,
  ServicesScreen,
  SigninScreen,
  SuccessStoriesAddScreen,
  SuccessStoriesEditScreen,
  SuccessStoriesListScreen,
  TechnicalResourcesAddScreen,
  TechnicalResourcesListScreen,
  UpcomingEventsAddScreen,
  UpcomingEventsListScreen,
  UpcomingEventEditScreen,
  UserListScreen,
  CollabrativePartnersScreen,
  CSEditScreen,
} from './admin/screens';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/bid-opportunities' element={<BidOpportunitiesPage />} />
        <Route path='/services' element={<ServicesPage />} />
        <Route
          path='/new-client-application'
          element={<NewClientApplicationPage />}
        />
        <Route path='/upcoming-events' element={<UpcomingEventsPage />} />
        <Route
          path='/upcoming-event-details/:id'
          element={<UpcomingEventDetailsPage />}
        />
        <Route
          path='/technical-resources'
          element={<TechnicalResourcesPage />}
        />
        <Route path='/faq' element={<FAQPage />} />
        <Route path='/library' element={<LibraryPage />} />
        <Route path='/success-stories' element={<SuccessStoriesPage />} />
        <Route
          path='/success-stories/success-story-new'
          element={<SuccessStoryNewPage />}
        />
        <Route path='/news' element={<NewsPage />} />
        <Route path='/news/:id' element={<NewsDetailsPage />} />
        <Route path='/contracting-news' element={<ContractingNewsPage />} />
        <Route
          path='/contracting-news/:id'
          element={<ContractingNewsDetailsPage />}
        />

        <Route
          path='/collabrative-partners'
          element={<CollabrativePartnersPage />}
        />
        {/* <Route path='/foci-compliant' element={<FociPage />} /> */}
        <Route path='/cybersecurity' element={<CyberSecurityPage />} />
        {/* <Route path='/defense-production-act-title-iii' element={<DpaPage />} /> */}

        <Route path='/customer-feedback' element={<CustomerFeedbackPage />} />
        <Route path='/federal-bids' element={<FederalBidsPage />} />
        <Route path='/find-us' element={<FindUsPage />} />
        <Route path='/contact-us' element={<ContactUsPage />} />
        {/* <Route path='/foci' element={<FociPage />} /> */}

        {/* Admin Screens */}
        <Route path='/admin/screens' element={<ScreenListScreen />} />
        <Route path='/admin' element={<SigninScreen />} />
        <Route path='/admin/users' element={<UserListScreen />} />
        <Route
          path='/admin/register-new-user'
          element={<RegisterNewUserScreen />}
        />
        <Route path='/admin/reset-password' element={<ResetPasswordScreen />} />
        <Route path='/admin/new-password/:id' element={<NewPasswordScreen />} />
        <Route path='/admin/about/edit' element={<AboutScreen />} />
        <Route path='/admin/services/edit' element={<ServicesScreen />} />
        <Route path='/admin/offices' element={<OfficesListScreen />} />
        <Route
          path='/admin/bidopportunities'
          element={<BidOpportunitiesListScreen />}
        />
        <Route
          path='/admin/bidopportunities/add'
          element={<BidOpportunitiesAddScreen />}
        />
        <Route path='/admin/faqs' element={<FaqListScreen />} />
        <Route path='/admin/faqs/add' element={<FaqAddScreen />} />
        <Route path='/admin/faqs/:id/edit' element={<FaqEditScreen />} />
        <Route
          path='/admin/successstories'
          element={<SuccessStoriesListScreen />}
        />
        <Route
          path='/admin/successstories/add'
          element={<SuccessStoriesAddScreen />}
        />
        <Route
          path='/admin/successstory/:id/edit'
          element={<SuccessStoriesEditScreen />}
        />
        <Route path='/admin/library' element={<LibraryListScreen />} />
        <Route path='/admin/library/add' element={<LibraryAddScreen />} />
        <Route path='/admin/news' element={<NewsListScreen />} />
        <Route path='/admin/news/add' element={<NewsAddScreen />} />
        <Route path='/admin/news/:id/edit' element={<NewsEditScreen />} />
        <Route
          path='/admin/contracting-news'
          element={<ContractingNewsListScreen />}
        />
        <Route
          path='/admin/contracting-news/add'
          element={<ContractingNewsAddScreen />}
        />
        <Route
          path='/admin/contracting-news/:id/edit'
          element={<ContractingNewsEditScreen />}
        />
        <Route
          path='/admin/contact-us/new'
          element={<ContactRequestListScreen />}
        />
        <Route path='/admin/events' element={<UpcomingEventsListScreen />} />
        <Route path='/admin/events/add' element={<UpcomingEventsAddScreen />} />
        <Route
          path='/admin/events/:id/edit'
          element={<UpcomingEventEditScreen />}
        />
        <Route
          path='/admin/technical-resources'
          element={<TechnicalResourcesListScreen />}
        />
        <Route
          path='/admin/technical-resources/add'
          element={<TechnicalResourcesAddScreen />}
        />
        <Route
          path='/admin/customer-feedback/new'
          element={<CustomerFeedbackListScreen />}
        />
        <Route
          path='/admin/customer-feedback/archived'
          element={<CustomerFeedbackArchivedScreen />}
        />
        <Route
          path='/admin/customer-feedback/:id/feedback-details'
          element={<CustomerFeedbackDetailsScreen />}
        />

        <Route
          path='/admin/collabrative-partners/edit'
          element={<CollabrativePartnersScreen />}
        />

        <Route path='/admin/cybersecurity/edit' element={<CSEditScreen />} />
      </Routes>
    </Router>
  );
};

export default App;
