import axios from 'axios';

import {
  BO_ADD_REQUEST,
  BO_ADD_SUCCESS,
  BO_ADD_FAIL,
  BO_LIST_REQUEST,
  BO_LIST_SUCCESS,
  BO_LIST_FAIL,
  BO_DELETE_REQUEST,
  BO_DELETE_SUCCESS,
  BO_DELETE_FAIL,
} from '../constants/bidopportunitesConstants';

import { logout } from './userActions';

export const addBO = (bidtype, title, link) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BO_ADD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      '/api/bidopportunities',
      { bidtype, title, link },
      config
    );

    dispatch({
      type: BO_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BO_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listBOs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BO_LIST_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/bidopportunities`, config);

    dispatch({
      type: BO_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: BO_LIST_FAIL,
      payload: message,
    });
  }
};

export const deleteBO = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BO_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/bidopportunities/${id}`, config);

    dispatch({ type: BO_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: BO_DELETE_FAIL,
      payload: message,
    });
  }
};
