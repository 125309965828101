export const EVENT_ADD_REQUEST = 'EVENT_ADD_REQUEST';
export const EVENT_ADD_SUCCESS = 'EVENT_ADD_SUCCESS';
export const EVENT_ADD_FAIL = 'EVENT_ADD_FAIL';
export const EVENT_ADD_RESET = 'EVENT_ADD_RESET';

export const EVENTS_LIST_REQUEST = 'EVENTS_LIST_REQUEST';
export const EVENTS_LIST_SUCCESS = 'EVENTS_LIST_SUCCESS';
export const EVENTS_LIST_FAIL = 'EVENTS_LIST_FAIL';
export const EVENTS_LIST_RESET = 'EVENTS_LIST_RESET';

export const EVENT_DETAILS_REQUEST = 'EVENT_DETAILS_REQUEST';
export const EVENT_DETAILS_SUCCESS = 'EVENT_DETAILS_SUCCESS';
export const EVENT_DETAILS_FAIL = 'EVENT_DETAILS_FAIL';
export const EVENT_DETAILS_RESET = 'EVENT_DETAILS_RESET';

export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST';
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS';
export const EVENT_UPDATE_FAIL = 'EVENT_UPDATE_FAIL';
export const EVENT_UPDATE_RESET = 'EVENT_UPDATE_RESET';

export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST';
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
export const EVENT_DELETE_FAIL = 'EVENT_DELETE_FAIL';
