export const FAQ_ADD_REQUEST = 'FAQ_ADD_REQUEST';
export const FAQ_ADD_SUCCESS = 'FAQ_ADD_SUCCESS';
export const FAQ_ADD_FAIL = 'FAQ_ADD_FAIL';
export const FAQ_ADD_RESET = 'FAQ_ADD_RESET';

export const FAQ_LIST_REQUEST = 'FAQ_LIST_REQUEST';
export const FAQ_LIST_SUCCESS = 'FAQ_LIST_SUCCESS';
export const FAQ_LIST_FAIL = 'FAQ_LIST_FAIL';
export const FAQ_LIST_RESET = 'FAQ_LIST_RESET';

export const FAQ_DETAILS_REQUEST = 'FAQ_DETAILS_REQUEST';
export const FAQ_DETAILS_SUCCESS = 'FAQ_DETAILS_SUCCESS';
export const FAQ_DETAILS_FAIL = 'FAQ_DETAILS_FAIL';
export const FAQ_DETAILS_RESET = 'FAQ_DETAILS_RESET';

export const FAQ_UPDATE_REQUEST = 'FAQ_UPDATE_REQUEST';
export const FAQ_UPDATE_SUCCESS = 'FAQ_UPDATE_SUCCESS';
export const FAQ_UPDATE_FAIL = 'FAQ_UPDATE_FAIL';
export const FAQ_UPDATE_RESET = 'FAQ_UPDATE_RESET';

export const FAQ_DELETE_REQUEST = 'FAQ_DELETE_REQUEST';
export const FAQ_DELETE_SUCCESS = 'FAQ_DELETE_SUCCESS';
export const FAQ_DELETE_FAIL = 'FAQ_DELETE_FAIL';
