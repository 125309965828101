import AdContainer from '../../components/adcontainer/AdContainer';
import ScreenList from '../../components/screenlist/ScreenList';

const ScreenListScreen = () => {
  return (
    <AdContainer>
      <ScreenList />
    </AdContainer>
  );
};

export default ScreenListScreen;
