import {
  TR_ADD_REQUEST,
  TR_ADD_SUCCESS,
  TR_ADD_FAIL,
  TR_ADD_RESET,
  TR_LIST_FAIL,
  TR_LIST_REQUEST,
  TR_LIST_SUCCESS,
  TR_DETAILS_REQUEST,
  TR_DETAILS_SUCCESS,
  TR_DETAILS_FAIL,
  TR_DETAILS_RESET,
  TR_UPDATE_REQUEST,
  TR_UPDATE_SUCCESS,
  TR_UPDATE_FAIL,
  TR_UPDATE_RESET,
  TR_DELETE_REQUEST,
  TR_DELETE_SUCCESS,
  TR_DELETE_FAIL,
} from '../constants/technicalresourcesConstants';

export const trAddReducer = (state = {}, action) => {
  switch (action.type) {
    case TR_ADD_REQUEST:
      return { loading: true };
    case TR_ADD_SUCCESS:
      return { loading: false, newTR: action.payload };
    case TR_ADD_FAIL:
      return { loading: false, error: action.payload };
    case TR_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const trListReducer = (state = { trs: [] }, action) => {
  switch (action.type) {
    case TR_LIST_REQUEST:
      return { loading: true };
    case TR_LIST_SUCCESS:
      return { loading: false, trs: action.payload };
    case TR_LIST_FAIL:
      return { loading: false, error: action.payload };
    // case TR_LIST_RESET:
    //   return { trs: [] };
    default:
      return state;
  }
};

export const trUpdateReducer = (state = { tr: {} }, action) => {
  switch (action.type) {
    case TR_UPDATE_REQUEST:
      return { loading: true };
    case TR_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case TR_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TR_UPDATE_RESET:
      return {
        tr: {},
      };
    default:
      return state;
  }
};

export const trDetailsReducer = (state = { tr: {} }, action) => {
  switch (action.type) {
    case TR_DETAILS_REQUEST:
      return { ...state, loading: true };
    case TR_DETAILS_SUCCESS:
      return { loading: false, tr: action.payload };
    case TR_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case TR_DETAILS_RESET:
      return { tr: {} };
    default:
      return state;
  }
};

export const trDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TR_DELETE_REQUEST:
      return { loading: true };
    case TR_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TR_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
