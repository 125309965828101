import LogoBar from '../logobar/LogoBar';
import NavBar from '../navbar/NavBar';
import Footer from '../footer/Footer';

const FeContainer = (props) => {
  return (
    <div>
      <LogoBar />
      <NavBar />
      {props.children}
      <Footer />
    </div>
  );
};

export default FeContainer;
