import AdContainer from '../../components/adcontainer/AdContainer';
import ContractingNewsAdd from '../../components/news/ContractingNewsAdd';

const ContractingNewsAddScreen = () => {
  return (
    <AdContainer>
      <ContractingNewsAdd />
    </AdContainer>
  );
};

export default ContractingNewsAddScreen;
