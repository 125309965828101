import AdContainer from '../../components/adcontainer/AdContainer';
import LibraryList from '../../components/library/LibraryList';

const LibraryListScreen = () => {
  return (
    <AdContainer>
      <LibraryList />
    </AdContainer>
  );
};

export default LibraryListScreen;
