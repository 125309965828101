import AdContainer from '../../components/adcontainer/AdContainer';
import CustomerFeedbackList from '../../components/customerfeedback/CustomerFeedbackList';

const CustomerFeedbackListScreen = () => {
  return (
    <AdContainer>
      <CustomerFeedbackList />{' '}
    </AdContainer>
  );
};

export default CustomerFeedbackListScreen;
