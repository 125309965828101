import {
  OFFICE_LIST_REQUEST,
  OFFICE_LIST_SUCCESS,
  OFFICE_LIST_FAIL,
  OFFICE_DETAILS_REQUEST,
  OFFICE_DETAILS_SUCCESS,
  OFFICE_DETAILS_FAIL,
  OFFICE_DETAILS_RESET,
  OFFICE_UPDATE_REQUEST,
  OFFICE_UPDATE_SUCCESS,
  OFFICE_UPDATE_FAIL,
  OFFICE_UPDATE_RESET,
} from '../constants/officeConstants';

export const officeListReducer = (state = { offices: [] }, action) => {
  switch (action.type) {
    case OFFICE_LIST_REQUEST:
      return { loading: true };
    case OFFICE_LIST_SUCCESS:
      return { loading: false, offices: action.payload };
    case OFFICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    // case FAQ_LIST_RESET:
    //   return { faqs: [] };
    default:
      return state;
  }
};

export const officeUpdateReducer = (state = { office: {} }, action) => {
  switch (action.type) {
    case OFFICE_UPDATE_REQUEST:
      return { loading: true };
    case OFFICE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case OFFICE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case OFFICE_UPDATE_RESET:
      return {
        office: {},
      };
    default:
      return state;
  }
};

export const officeDetailsReducer = (state = { office: {} }, action) => {
  switch (action.type) {
    case OFFICE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case OFFICE_DETAILS_SUCCESS:
      return { loading: false, office: action.payload };
    case OFFICE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case OFFICE_DETAILS_RESET:
      return { office: {} };
    default:
      return state;
  }
};
