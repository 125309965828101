import AdContainer from '../../components/adcontainer/AdContainer';
import UpcomingEventsList from '../../components/upcomingevents/UpcomingEventsList';

const UpcomingEventsListScreen = () => {
  return (
    <AdContainer>
      <UpcomingEventsList />
    </AdContainer>
  );
};

export default UpcomingEventsListScreen;
