import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { pageListReducer } from './reducers/pageReducers';

import { homepageGetReducer } from './reducers/homepageReducers';

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from './reducers/userReducers';

import {
  aboutGetReducer,
  aboutDetailsReducer,
  aboutUpdateReducer,
} from './reducers/aboutReducers';

import {
  cpGetReducer,
  cpUpdateReducer,
} from './reducers/collabrativepartnersReducers';

import {
  csGetReducer,
  csDetailsReducer,
  csUpdateReducer,
} from './reducers/cybersecurityReducers';

import {
  servicesGetReducer,
  servicesDetailsReducer,
  servicesUpdateReducer,
} from './reducers/servicesReducers';

import {
  faqListReducer,
  faqAddReducer,
  faqUpdateReducer,
  faqDetailsReducer,
  faqDeleteReducer,
} from './reducers/faqReducers';

import {
  trListReducer,
  trAddReducer,
  trUpdateReducer,
  trDetailsReducer,
  trDeleteReducer,
} from './reducers/technicalresourcesReducers';

import {
  eventAddReducer,
  eventsListReducer,
  eventDetailsReducer,
  eventUpdateReducer,
  eventDeleteReducer,
} from './reducers/eventReducers';

import {
  newsAddReducer,
  newsListReducer,
  newsDetailsReducer,
  newsUpdateReducer,
  newsDeleteReducer,
} from './reducers/newsReducers';

import {
  cnewsAddReducer,
  cnewsListReducer,
  cnewsDetailsReducer,
  cnewsUpdateReducer,
  cnewsDeleteReducer,
} from './reducers/contractingnewsReducers';

import {
  libraryAddReducer,
  libraryListReducer,
  libraryitemUpdateReducer,
  libraryitemDetailsReducer,
  libraryitemDeleteReducer,
} from './reducers/libraryReducers';

import {
  boAddReducer,
  boListReducer,
  boDeleteReducer,
} from './reducers/bidopportunitiesReducers';

import {
  successStoryAddReducer,
  successStoryListReducer,
  successStoryFooterListReducer,
  successStoryDetailsReducer,
  successStoryUpdateReducer,
  successStoryDeleteReducer,
} from './reducers/successstoryReducers';

import {
  officeListReducer,
  officeUpdateReducer,
  officeDetailsReducer,
} from './reducers/officeReducers';

import {
  contactAddReducer,
  newContactListReducer,
  archivedContactListReducer,
  contactDetailsReducer,
  contactUpdateReducer,
} from './reducers/contactusReducers';

import {
  feedbackAddReducer,
  newFeedbackListReducer,
  archivedFeedbackListReducer,
  feedbackDetailsReducer,
  feedbackUpdateReducer,
} from './reducers/customerfeedbackReducers';

const reducer = combineReducers({
  pageList: pageListReducer,

  homepageGet: homepageGetReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,

  feedbackAdd: feedbackAddReducer,
  newFeedbackList: newFeedbackListReducer,
  archivedFeedbackList: archivedFeedbackListReducer,
  feedbackDetails: feedbackDetailsReducer,
  feedbackUpdate: feedbackUpdateReducer,

  contactAdd: contactAddReducer,
  newContactList: newContactListReducer,
  archivedContactList: archivedContactListReducer,
  contactDetails: contactDetailsReducer,
  contactUpdate: contactUpdateReducer,

  aboutGet: aboutGetReducer,
  aboutDetails: aboutDetailsReducer,
  aboutUpdate: aboutUpdateReducer,

  cpGet: cpGetReducer,
  cpUpdate: cpUpdateReducer,

  csGet: csGetReducer,
  csDetails: csDetailsReducer,
  csUpdate: csUpdateReducer,

  servicesGet: servicesGetReducer,
  servicesDetails: servicesDetailsReducer,
  servicesUpdate: servicesUpdateReducer,

  faqAdd: faqAddReducer,
  faqList: faqListReducer,
  faqUpdate: faqUpdateReducer,
  faqDetails: faqDetailsReducer,
  faqDelete: faqDeleteReducer,

  trList: trListReducer,
  trAdd: trAddReducer,
  trUpdate: trUpdateReducer,
  trDetails: trDetailsReducer,
  trDelete: trDeleteReducer,

  newsAdd: newsAddReducer,
  newsList: newsListReducer,
  newsDetails: newsDetailsReducer,
  newsUpdate: newsUpdateReducer,
  newsDelete: newsDeleteReducer,

  cnewsAdd: cnewsAddReducer,
  cnewsList: cnewsListReducer,
  cnewsDetails: cnewsDetailsReducer,
  cnewsUpdate: cnewsUpdateReducer,
  cnewsDelete: cnewsDeleteReducer,

  eventAdd: eventAddReducer,
  eventsList: eventsListReducer,
  eventDetails: eventDetailsReducer,
  eventUpdate: eventUpdateReducer,
  eventDelete: eventDeleteReducer,

  libraryList: libraryListReducer,
  libraryAdd: libraryAddReducer,
  libraryitemUpdate: libraryitemUpdateReducer,
  libraryitemDetails: libraryitemDetailsReducer,
  libraryitemDelete: libraryitemDeleteReducer,

  boAdd: boAddReducer,
  boList: boListReducer,
  boDelete: boDeleteReducer,

  successStoryAdd: successStoryAddReducer,
  successStoryList: successStoryListReducer,
  successStoryFooterList: successStoryFooterListReducer,
  successStoryDetails: successStoryDetailsReducer,
  successStoryUpdate: successStoryUpdateReducer,
  successStoryDelete: successStoryDeleteReducer,

  officeList: officeListReducer,
  officeUpdate: officeUpdateReducer,
  officeDetails: officeDetailsReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
