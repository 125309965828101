export const NEWS_ADD_REQUEST = 'NEWS_ADD_REQUEST';
export const NEWS_ADD_SUCCESS = 'NEWS_ADD_SUCCESS';
export const NEWS_ADD_FAIL = 'NEWS_ADD_FAIL';
export const NEWS_ADD_RESET = 'NEWS_ADD_RESET';

export const NEWS_LIST_REQUEST = 'NEWS_LIST_REQUEST';
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_SUCCESS';
export const NEWS_LIST_FAIL = 'NEWS_LIST_FAIL';
export const NEWS_LIST_RESET = 'NEWS_LIST_RESET';

export const NEWS_DETAILS_REQUEST = 'NEWS_DETAILS_REQUEST';
export const NEWS_DETAILS_SUCCESS = 'NEWS_DETAILS_SUCCESS';
export const NEWS_DETAILS_FAIL = 'NEWS_DETAILS_FAIL';
export const NEWS_DETAILS_RESET = 'NEWS_DETAILS_RESET';

export const NEWS_UPDATE_REQUEST = 'NEWS_UPDATE_REQUEST';
export const NEWS_UPDATE_SUCCESS = 'NEWS_UPDATE_SUCCESS';
export const NEWS_UPDATE_FAIL = 'NEWS_UPDATE_FAIL';
export const NEWS_UPDATE_RESET = 'NEWS_UPDATE_RESET';

export const NEWS_DELETE_REQUEST = 'NEWS_DELETE_REQUEST';
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS';
export const NEWS_DELETE_FAIL = 'NEWS_DELETE_FAIL';
