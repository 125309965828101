import {
  BO_ADD_REQUEST,
  BO_ADD_SUCCESS,
  BO_ADD_FAIL,
  BO_ADD_RESET,
  BO_LIST_REQUEST,
  BO_LIST_SUCCESS,
  BO_LIST_FAIL,
  BO_DELETE_REQUEST,
  BO_DELETE_SUCCESS,
  BO_DELETE_FAIL,
} from '../constants/bidopportunitesConstants';

export const boAddReducer = (state = {}, action) => {
  switch (action.type) {
    case BO_ADD_REQUEST:
      return { loading: true };
    case BO_ADD_SUCCESS:
      return { loading: false, newBO: action.payload };
    case BO_ADD_FAIL:
      return { loading: false, error: action.payload };
    case BO_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const boListReducer = (state = { bos: [] }, action) => {
  switch (action.type) {
    case BO_LIST_REQUEST:
      return { loading: true };
    case BO_LIST_SUCCESS:
      return { loading: false, bos: action.payload };
    case BO_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const boDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BO_DELETE_REQUEST:
      return { loading: true };
    case BO_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BO_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
