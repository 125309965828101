import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

// import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import { CKEditor } from '@ckeditor/ckeditor5-react';

import { addContact } from '../../../redux/actions/contactusActions';

// import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

const ContactUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [businessname, setBusinessname] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');

  const contactAdd = useSelector((state) => state.contactAdd);
  const { loading, error, newContact } = contactAdd;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      addContact(
        businessname,
        name,
        address,
        city,
        state,
        zipcode,
        phone,
        fax,
        email,
        feedback
      )
    );
  };

  useEffect(() => {
    if (newContact) {
      navigate('/');
    }
  });

  return (
    <div>
      <div className='maingrid'>
        <div className='innerbannerimage'>
          <img src='/assets/images/construction01.jpg' alt='About Banner' />
        </div>

        <h2 className='innerpagetitle'>Contact Us</h2>

        <div className='innerpagecontent'>
          <form className='form' id='addss' onSubmit={submitHandler}>
            <div className='dataitems'>
              <div>
                <label htmlFor='businessname'>Business Name</label>
                <input
                  type='text'
                  id='businessname'
                  placeholder='Enter Business Name'
                  required
                  // value={businessname}
                  onChange={(e) => setBusinessname(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='name'>Name of Contact Person</label>
                <input
                  type='text'
                  id='name'
                  placeholder='Enter name of Contact Person'
                  required
                  // value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='address'>Business Address</label>
                <CKEditor
                  editor={Editor}
                  data={''}
                  config={EDITOR_CONFIGURATION}
                  onChange={(event, editor) => {
                    const addressdata = editor.getData();
                    setAddress(addressdata);
                  }}
                />
              </div>

              <div>
                <label htmlFor='city'>Name of City</label>
                <input
                  type='text'
                  id='city'
                  placeholder='Enter City'
                  required
                  // value={name}
                  onChange={(e) => setCity(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='state'>Name of State</label>
                <input
                  type='text'
                  id='state'
                  placeholder='Enter State'
                  required
                  // value={name}
                  onChange={(e) => setState(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='zipcode'>Zip Code</label>
                <input
                  type='text'
                  id='zipcode'
                  placeholder='Enter Zip Code'
                  required
                  // value={name}
                  onChange={(e) => setZipcode(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='phone'>Contact Phone Number</label>
                <input
                  type='text'
                  id='phone'
                  placeholder='Enter Contact Phone Number'
                  required
                  // value={name}
                  onChange={(e) => setPhone(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='fax'>Fax Number</label>
                <input
                  type='text'
                  id='fax'
                  placeholder='Enter Fax Number'
                  required
                  // value={name}
                  onChange={(e) => setFax(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='email'>Email Id</label>
                <input
                  type='email'
                  id='email'
                  placeholder='Enter Email Id'
                  required
                  // value={name}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor='address'>Your Feedback</label>
                <CKEditor
                  editor={Editor}
                  data={''}
                  config={EDITOR_CONFIGURATION}
                  onChange={(event, editor) => {
                    const feedbackdata = editor.getData();
                    setFeedback(feedbackdata);
                  }}
                />
              </div>
            </div>
            <div>
              <label />
              <button className='submitform' type='submit'>
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
