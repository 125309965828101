import AboutShort from '../components/home/aboutshort/AboutShort';
import MainBanner from '../components/home/mainbanner/MainBanner';
import MscpcOffices from '../components/home/mscpcoffices/MscpcOffices';
import NewsAndEvents from '../components/home/newsandevents/NewsAndEvents';
import SamGov from '../components/home/samgov/SamGov';

import FeModalContainer from '../components/fecontainer/FeModalContainer';
import Home from '../components/home/Home';

const HomePage = () => {
  return (
    <FeModalContainer>
      <Home />
      {/* <MainBanner />
      <AboutShort />
      <SamGov />
      <NewsAndEvents />
      <MscpcOffices /> */}
    </FeModalContainer>
  );
};

export default HomePage;
