import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { login } from '../../../redux/actions/userActions';
import './signin.css';

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  useEffect(() => {
    if (userInfo) {
      navigate('/admin/screens');
    }
  });

  return (
    <div className='maingrid'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Sign in</h2>
          </div>
          <div className='dataitems'>
            <div>
              <label htmlFor='email'>Email address</label>
              <input
                type='email'
                id='email'
                placeholder='Enter email'
                required
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='password'>Password</label>
              <input
                type='password'
                id='password'
                placeholder='Enter password'
                required
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>

            <div>
              <label />
              <button className='submitform' type='submit'>
                Sign In
              </button>
            </div>
          </div>
          <div>
            <label />
            <div>
              Forgot Password?{' '}
              <Link to={'/admin/reset-password'}>Reset Password</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signin;
