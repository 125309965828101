import {
  CONTACT_ADD_REQUEST,
  CONTACT_ADD_SUCCESS,
  CONTACT_ADD_FAIL,
  CONTACT_ADD_RESET,
  NEW_CONTACT_LIST_REQUEST,
  NEW_CONTACT_LIST_SUCCESS,
  NEW_CONTACT_LIST_FAIL,
  NEW_CONTACT_LIST_RESET,
  ARCHIVED_CONTACT_LIST_REQUEST,
  ARCHIVED_CONTACT_LIST_SUCCESS,
  ARCHIVED_CONTACT_LIST_FAIL,
  ARCHIVED_CONTACT_LIST_RESET,
  CONTACT_DETAILS_REQUEST,
  CONTACT_DETAILS_SUCCESS,
  CONTACT_DETAILS_FAIL,
  CONTACT_DETAILS_RESET,
  CONTACT_UPDATE_REQUEST,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAIL,
  CONTACT_UPDATE_RESET,
} from '../constants/contactusConstants';

export const contactAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_ADD_REQUEST:
      return { loading: true };
    case CONTACT_ADD_SUCCESS:
      return { loading: false, newContact: action.payload };
    case CONTACT_ADD_FAIL:
      return { loading: false, error: action.payload };
    case CONTACT_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const newContactListReducer = (state = { newContacts: [] }, action) => {
  switch (action.type) {
    case NEW_CONTACT_LIST_REQUEST:
      return { loading: true };
    case NEW_CONTACT_LIST_SUCCESS:
      return { loading: false, newContacts: action.payload };
    case NEW_CONTACT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case NEW_CONTACT_LIST_RESET:
      return { newContacts: [] };
    default:
      return state;
  }
};

export const archivedContactListReducer = (
  state = { archivedContacts: [] },
  action
) => {
  switch (action.type) {
    case ARCHIVED_CONTACT_LIST_REQUEST:
      return { loading: true };
    case ARCHIVED_CONTACT_LIST_SUCCESS:
      return { loading: false, archivedContacts: action.payload };
    case ARCHIVED_CONTACT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ARCHIVED_CONTACT_LIST_RESET:
      return { archivedContacts: [] };
    default:
      return state;
  }
};

export const contactDetailsReducer = (state = { contact: {} }, action) => {
  switch (action.type) {
    case CONTACT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case CONTACT_DETAILS_SUCCESS:
      return { loading: false, contact: action.payload };
    case CONTACT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CONTACT_DETAILS_RESET:
      return { contact: {} };
    default:
      return state;
  }
};

export const contactUpdateReducer = (state = { contact: {} }, action) => {
  switch (action.type) {
    case CONTACT_UPDATE_REQUEST:
      return { loading: true };
    case CONTACT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case CONTACT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CONTACT_UPDATE_RESET:
      return { contact: {} };
    default:
      return state;
  }
};
