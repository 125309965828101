import './adfooter.css';

const AdFooter = () => {
  return (
    <footer className='adfooter'>
      <h3>All Rights Reserved</h3>
    </footer>
  );
};

export default AdFooter;
