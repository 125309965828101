import FederalBids from '../components/bidopportunities/FederalBids';

import FeContainer from '../components/fecontainer/FeContainer';

const FederalBidsPage = () => {
  return (
    <FeContainer>
      <FederalBids />
    </FeContainer>
  );
};

export default FederalBidsPage;
