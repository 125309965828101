import AdContainer from '../../components/adcontainer/AdContainer';
import SuccessStoriesEdit from '../../components/successstories/SuccessStoriesEdit';

const SuccessStoriesEditScreen = () => {
  return (
    <AdContainer>
      <SuccessStoriesEdit />
    </AdContainer>
  );
};

export default SuccessStoriesEditScreen;
