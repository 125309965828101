import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  listCNews,
  deleteCNews,
} from '../../../redux/actions/contractingnewsActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const ContractingNewsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cnewsList = useSelector((state) => state.cnewsList);
  const { loading, error, cnews } = cnewsList;

  const cnewsDelete = useSelector((state) => state.cnewsDelete);
  const { success: successDelete } = cnewsDelete;

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteCNews(id));
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listCNews());
    } else {
      navigate('/admin');
    }
  }, [dispatch, navigate, successDelete, userInfo]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>Contracting News</h2>
          <Link to='/admin/contracting-news/add'>
            <button>
              <i className='fa fa-plus'></i> Add New Contracting News
            </button>
          </Link>
        </div>
        <div className='screenlist'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>Contracting News Headline</th>
                  <th>Contracting News Content</th>
                  <th>Contracting News Date</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {cnews.map((cnewsItem) => (
                  <tr key={cnewsItem._id}>
                    <td>{cnewsItem.heading}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: cnewsItem.contractingnewscontent,
                        // .split(' ')
                        // .slice(0, 20)
                        // .join(' '),
                      }}
                    ></td>
                    <td>
                      {' '}
                      {moment(cnewsItem.createdAt).format('MMMM Do YYYY')}
                    </td>
                    <td>
                      <Link
                        to={`/admin/contracting-news/${cnewsItem._id}/edit`}
                      >
                        <button className='small'>
                          <i className='fa fa-edit'></i>
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className='small'
                        onClick={() => deleteHandler(cnewsItem._id)}
                      >
                        <i className='fa fa-trash'></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContractingNewsList;
