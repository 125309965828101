export const OFFICE_LIST_REQUEST = 'OFFICE_LIST_REQUEST';
export const OFFICE_LIST_SUCCESS = 'OFFICE_LIST_SUCCESS';
export const OFFICE_LIST_FAIL = 'OFFICE_LIST_FAIL';
export const OFFICE_LIST_RESET = 'OFFICE_LIST_RESET';

export const OFFICE_DETAILS_REQUEST = 'OFFICE_DETAILS_REQUEST';
export const OFFICE_DETAILS_SUCCESS = 'OFFICE_DETAILS_SUCCESS';
export const OFFICE_DETAILS_FAIL = 'OFFICE_DETAILS_FAIL';
export const OFFICE_DETAILS_RESET = 'OFFICE_DETAILS_RESET';

export const OFFICE_UPDATE_REQUEST = 'OFFICE_UPDATE_REQUEST';
export const OFFICE_UPDATE_SUCCESS = 'OFFICE_UPDATE_SUCCESS';
export const OFFICE_UPDATE_FAIL = 'OFFICE_UPDATE_FAIL';
export const OFFICE_UPDATE_RESET = 'OFFICE_UPDATE_RESET';
