import Library from '../components/library/Library';

import FeContainer from '../components/fecontainer/FeContainer';

const LibraryPage = () => {
  return (
    <FeContainer>
      <Library />
    </FeContainer>
  );
};

export default LibraryPage;
