import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { addLibraryItem } from '../../../redux/actions/libraryActions';
import { LIBRARY_ADD_RESET } from '../../../redux/constants/libraryConstants';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const LibraryAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');
  const [resource, setResource] = useState('');
  const [uploading, setUploading] = useState(false);

  const libraryAdd = useSelector((state) => state.libraryAdd);
  const { loading, error, newLibraryItem } = libraryAdd;

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setUploading(true);

    try {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const { data } = await axios.post('/api/upload', formData, config);
      console.log(data);
      setResource(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addLibraryItem(category, title, resource));
  };

  useEffect(() => {
    dispatch({ type: LIBRARY_ADD_RESET });
    if (newLibraryItem) {
      navigate('/admin/library');
    }
  }, [dispatch, navigate, newLibraryItem]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <form className='form' id='signinform' onSubmit={submitHandler}>
          <div>
            <h2>Add New Library Item</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='dataitems'>
              <div>
                <label htmlFor='category'>Category</label>
                <input
                  type='text'
                  id='category'
                  placeholder='Enter Category'
                  required
                  onChange={(e) =>
                    setCategory(e.target.value.trim().toUpperCase())
                  }
                ></input>
              </div>

              <div>
                <label htmlFor='title'>Title</label>
                <input
                  type='text'
                  id='title'
                  placeholder='Enter Title'
                  required
                  onChange={(e) => setTitle(e.target.value)}
                ></input>
              </div>

              <div>
                <label>Attachment</label>
                <input
                  type='text'
                  required
                  value={resource}
                  onChange={(e) => setResource(e.target.value)}
                />
              </div>

              <div>
                <label>Choose File</label>
                <input type='file' value={''} onChange={uploadFileHandler} />
              </div>

              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default LibraryAdd;
