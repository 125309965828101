import LogoBar from '../../../frontend/components/logobar/LogoBar';
import AdFooter from '../adfooter/AdFooter';
import AdNavbar from '../adnavbar/AdNavbar';

const AdContainer = (props) => {
  return (
    <div>
      <LogoBar />
      <AdNavbar />
      {props.children}
      <AdFooter />
    </div>
  );
};

export default AdContainer;
