import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { listNews, deleteNews } from '../../../redux/actions/newsActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const NewsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const newsList = useSelector((state) => state.newsList);
  const { loading, error, news } = newsList;

  const newsDelete = useSelector((state) => state.newsDelete);
  const { success: successDelete } = newsDelete;

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteNews(id));
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listNews());
    } else {
      navigate('/admin');
    }
  }, [dispatch, navigate, successDelete, userInfo]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>News</h2>
          <Link to='/admin/news/add'>
            <button>
              <i className='fa fa-plus'></i> Add New News Item
            </button>
          </Link>
        </div>
        <div className='screenlist'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>News Headline</th>
                  <th>News Content</th>
                  <th>News Date</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {news.map((newsItem) => (
                  <tr key={newsItem._id}>
                    <td>{newsItem.heading}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: newsItem.newscontent,
                        // .split(' ')
                        // .slice(0, 20)
                        // .join(' '),
                      }}
                    ></td>
                    <td>
                      {' '}
                      {moment(newsItem.createdAt).format('MMMM Do YYYY')}
                    </td>
                    <td>
                      <Link to={`/admin/news/${newsItem._id}/edit`}>
                        <button className='small'>
                          <i className='fa fa-edit'></i>
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className='small'
                        onClick={() => deleteHandler(newsItem._id)}
                      >
                        <i className='fa fa-trash'></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsList;
