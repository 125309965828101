import axios from 'axios';
import {
  PAGES_LIST_REQUEST,
  PAGES_LIST_SUCCESS,
  PAGES_LIST_FAIL,
} from '../constants/pageConstants';

import { logout } from './userActions';

export const listPages = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PAGES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/pages`, config);

    dispatch({
      type: PAGES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: PAGES_LIST_FAIL,
      payload: message,
    });
  }
};
