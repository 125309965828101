export const SUCCESSSTORY_ADD_REQUEST = 'SUCCESSSTORY_ADD_REQUEST';
export const SUCCESSSTORY_ADD_SUCCESS = 'SUCCESSSTORY_ADD_SUCCESS';
export const SUCCESSSTORY_ADD_FAIL = 'SUCCESSSTORY_ADD_FAIL';
export const SUCCESSSTORY_ADD_RESET = 'SUCCESSSTORY_ADD_RESET';

export const SUCCESSSTORY_LIST_REQUEST = 'SUCCESSSTORY_LIST_REQUEST';
export const SUCCESSSTORY_LIST_SUCCESS = 'SUCCESSSTORY_LIST_SUCCESS';
export const SUCCESSSTORY_LIST_FAIL = 'SUCCESSSTORY_LIST_FAIL';
export const SUCCESSSTORY_LIST_RESET = 'SUCCESSSTORY_LIST_RESET';

export const SUCCESSSTORY_FOOTER_LIST_REQUEST =
  'SUCCESSSTORY_FOOTER_LIST_REQUEST';
export const SUCCESSSTORY_FOOTER_LIST_SUCCESS =
  'SUCCESSSTORY_FOOTER_LIST_SUCCESS';
export const SUCCESSSTORY_FOOTER_LIST_FAIL = 'SUCCESSSTORY_FOOTER_LIST_FAIL';
export const SUCCESSSTORY_FOOTER_LIST_RESET = 'SUCCESSSTORY_FOOTER_LIST_RESET';

export const SUCCESSSTORY_DETAILS_REQUEST = 'SUCCESSSTORY_DETAILS_REQUEST';
export const SUCCESSSTORY_DETAILS_SUCCESS = 'SUCCESSSTORY_DETAILS_SUCCESS';
export const SUCCESSSTORY_DETAILS_FAIL = 'SUCCESSSTORY_DETAILS_FAIL';
export const SUCCESSSTORY_DETAILS_RESET = 'SUCCESSSTORY_DETAILS_RESET';

export const SUCCESSSTORY_UPDATE_REQUEST = 'SUCCESSSTORY_UPDATE_REQUEST';
export const SUCCESSSTORY_UPDATE_SUCCESS = 'SUCCESSSTORY_UPDATE_SUCCESS';
export const SUCCESSSTORY_UPDATE_FAIL = 'SUCCESSSTORY_UPDATE_FAIL';
export const SUCCESSSTORY_UPDATE_RESET = 'SUCCESSSTORY_UPDATE_RESET';

export const SUCCESSSTORY_DELETE_REQUEST = 'SUCCESSSTORY_DELETE_REQUEST';
export const SUCCESSSTORY_DELETE_SUCCESS = 'SUCCESSSTORY_DELETE_SUCCESS';
export const SUCCESSSTORY_DELETE_FAIL = 'SUCCESSSTORY_DELETE_FAIL';
