import AdContainer from '../../components/adcontainer/AdContainer';
import Signin from '../../components/users/Signin';

const SigninScreen = () => {
  return (
    <AdContainer>
      <Signin />
    </AdContainer>
  );
};

export default SigninScreen;
