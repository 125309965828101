import ContractingNews from '../components/contractingnews/ContractingNews';

import FeContainer from '../components/fecontainer/FeContainer';

const ContractingNewsPage = () => {
  return (
    <FeContainer>
      <ContractingNews />
    </FeContainer>
  );
};

export default ContractingNewsPage;
