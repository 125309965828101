import AdContainer from '../../components/adcontainer/AdContainer';
import OfficesList from '../../components/offices/OfficesList';

const OfficesListScreen = () => {
  return (
    <AdContainer>
      <OfficesList />
    </AdContainer>
  );
};

export default OfficesListScreen;
