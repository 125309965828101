import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from '../../../redux/actions/userActions';

import './adnavbar.css';

const AdNavbar = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div className='maingrid'>
      <div className='adminnavbar'>
        {userInfo && userInfo.isAdmin ? (
          <>
            <div className='menu-options'>
              <div className='adminpages'>
                <Link to='/admin/screens'>Screens</Link>
              </div>
              <div className='adminpages'>
                <Link to='/admin/users'>Users</Link>
              </div>
            </div>
            <div className='other-options'>
              <div className='adminpages'>
                <Link to='/admin' onClick={logoutHandler}>
                  Logout
                </Link>
              </div>
              <h3>Hello {userInfo.name}</h3>
            </div>
          </>
        ) : (
          <div className='login'>
            <Link to='/admin'>Login</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdNavbar;
