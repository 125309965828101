import {
  SUCCESSSTORY_ADD_REQUEST,
  SUCCESSSTORY_ADD_SUCCESS,
  SUCCESSSTORY_ADD_FAIL,
  SUCCESSSTORY_ADD_RESET,
  SUCCESSSTORY_LIST_REQUEST,
  SUCCESSSTORY_LIST_SUCCESS,
  SUCCESSSTORY_LIST_FAIL,
  SUCCESSSTORY_LIST_RESET,
  SUCCESSSTORY_FOOTER_LIST_REQUEST,
  SUCCESSSTORY_FOOTER_LIST_SUCCESS,
  SUCCESSSTORY_FOOTER_LIST_FAIL,
  SUCCESSSTORY_FOOTER_LIST_RESET,
  SUCCESSSTORY_DETAILS_REQUEST,
  SUCCESSSTORY_DETAILS_SUCCESS,
  SUCCESSSTORY_DETAILS_FAIL,
  SUCCESSSTORY_DETAILS_RESET,
  SUCCESSSTORY_UPDATE_REQUEST,
  SUCCESSSTORY_UPDATE_SUCCESS,
  SUCCESSSTORY_UPDATE_FAIL,
  SUCCESSSTORY_UPDATE_RESET,
  SUCCESSSTORY_DELETE_REQUEST,
  SUCCESSSTORY_DELETE_SUCCESS,
  SUCCESSSTORY_DELETE_FAIL,
} from '../constants/successstoryConstants';

export const successStoryAddReducer = (state = {}, action) => {
  switch (action.type) {
    case SUCCESSSTORY_ADD_REQUEST:
      return { loading: true };
    case SUCCESSSTORY_ADD_SUCCESS:
      return { loading: false, successstory: action.payload };
    case SUCCESSSTORY_ADD_FAIL:
      return { loading: false, error: action.payload };
    case SUCCESSSTORY_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const successStoryListReducer = (
  state = { successstories: [] },
  action
) => {
  switch (action.type) {
    case SUCCESSSTORY_LIST_REQUEST:
      return { loading: true };
    case SUCCESSSTORY_LIST_SUCCESS:
      return { loading: false, successstories: action.payload };
    case SUCCESSSTORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    case SUCCESSSTORY_LIST_RESET:
      return { successstories: [] };
    default:
      return state;
  }
};

export const successStoryFooterListReducer = (
  state = { successstories: [] },
  action
) => {
  switch (action.type) {
    case SUCCESSSTORY_FOOTER_LIST_REQUEST:
      return { loading: true };
    case SUCCESSSTORY_FOOTER_LIST_SUCCESS:
      return { loading: false, successstories: action.payload };
    case SUCCESSSTORY_FOOTER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case SUCCESSSTORY_FOOTER_LIST_RESET:
      return { successstories: [] };
    default:
      return state;
  }
};

export const successStoryDetailsReducer = (
  state = { successstory: {} },
  action
) => {
  switch (action.type) {
    case SUCCESSSTORY_DETAILS_REQUEST:
      return { ...state, loading: true };
    case SUCCESSSTORY_DETAILS_SUCCESS:
      return { loading: false, successstory: action.payload };
    case SUCCESSSTORY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case SUCCESSSTORY_DETAILS_RESET:
      return { successstory: {} };
    default:
      return state;
  }
};

export const successStoryUpdateReducer = (
  state = { successstory: {} },
  action
) => {
  switch (action.type) {
    case SUCCESSSTORY_UPDATE_REQUEST:
      return { loading: true };
    case SUCCESSSTORY_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case SUCCESSSTORY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SUCCESSSTORY_UPDATE_RESET:
      return { successstory: {} };
    default:
      return state;
  }
};

export const successStoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SUCCESSSTORY_DELETE_REQUEST:
      return { loading: true };
    case SUCCESSSTORY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SUCCESSSTORY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
