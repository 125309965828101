import AdContainer from '../../components/adcontainer/AdContainer';
import TechnicalResourcesList from '../../components/technicalresources/TechnicalResourcesList';

const TechnicalResourcesListScreen = () => {
  return (
    <AdContainer>
      <TechnicalResourcesList />
    </AdContainer>
  );
};

export default TechnicalResourcesListScreen;
