import CustomerFeedback from '../components/customerfeedback/CustomerFeedback';

import FeContainer from '../components/fecontainer/FeContainer';

const CustomerFeedbackPage = () => {
  return (
    <FeContainer>
      <CustomerFeedback />
    </FeContainer>
  );
};

export default CustomerFeedbackPage;
