import BidOpportunities from '../components/bidopportunities/BidOpportunities';

import FeContainer from '../components/fecontainer/FeContainer';

const BidOpportunitiesPage = () => {
  return (
    <FeContainer>
      <BidOpportunities />
    </FeContainer>
  );
};

export default BidOpportunitiesPage;
