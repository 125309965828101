import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { listBOs } from '../../../redux/actions/bidopportunitiesActions';
import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './bidopportunities.css';

const BidOpportunities = () => {
  const [generalbids, setGeneralbids] = useState('');

  const dispatch = useDispatch();

  const boList = useSelector((state) => state.boList);
  const { loading, error, bos } = boList;

  let gbids = [];

  if (bos) {
    gbids = bos.filter((bo) => {
      return bo.bidtype === 'General';
    });
  }

  useEffect(() => {
    dispatch(listBOs());
  }, [dispatch]);

  return (
    <div className='maingrid'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <div className='innerbannerimage'>
            <img
              src='/assets/images/bid-opportunities.jpg'
              alt='About Banner'
            />
          </div>
          <h2 className='innerpagetitle'>Bid Opportunities</h2>
          <div className='bidops'>
            <Link to='/federal-bids/'>
              <div className='bobutton'>Federal Bids</div>
            </Link>

            {gbids.map((gbid) => (
              <div key={gbid._id}>
                <a href={gbid.link} target='_blank' rel='noreferrer'>
                  <div className='bobutton'>{gbid.title}</div>
                </a>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BidOpportunities;
