import About from '../../components/about/About';
import AdContainer from '../../components/adcontainer/AdContainer';

const AboutScreen = () => {
  return (
    <AdContainer>
      <About />
    </AdContainer>
  );
};

export default AboutScreen;
