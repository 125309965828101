import axios from 'axios';

import {
  SERVICES_DATA_REQUEST,
  SERVICES_DATA_SUCCESS,
  SERVICES_DATA_FAIL,
  SERVICES_DETAILS_REQUEST,
  SERVICES_DETAILS_SUCCESS,
  SERVICES_DETAILS_FAIL,
  SERVICES_UPDATE_REQUEST,
  SERVICES_UPDATE_SUCCESS,
  SERVICES_UPDATE_FAIL,
} from '../constants/servicesConstants';

import { logout } from './userActions';

export const getServices = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICES_DATA_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // };

    const { data } = await axios.get(`/api/services`, config);

    dispatch({
      type: SERVICES_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: SERVICES_DATA_FAIL,
      payload: message,
    });
  }
};

export const getServicesDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICES_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/services', config);
    // const { data } = await axios.get(`/api/about/${id}`, config);

    dispatch({
      type: SERVICES_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: SERVICES_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateServices = (services) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICES_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/services`, services, config);

    dispatch({ type: SERVICES_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: SERVICES_UPDATE_FAIL,
      payload: message,
    });
  }
};
