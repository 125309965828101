import ContractingNewsDetails from '../components/contractingnews/ContractingNewsDetails';
import FeContainer from '../components/fecontainer/FeContainer';

const ContractingNewsDetailsPage = () => {
  return (
    <FeContainer>
      <ContractingNewsDetails />
    </FeContainer>
  );
};

export default ContractingNewsDetailsPage;
