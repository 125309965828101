import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

// import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import { CKEditor } from '@ckeditor/ckeditor5-react';

// import EDITOR_CONFIGURATION from '../../../EditorConfiguration';

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { CKEditor } from '@ckeditor/ckeditor5-react';

import { addSuccessStory } from '../../../redux/actions/successstoryActions';
import { SUCCESSSTORY_ADD_RESET } from '../../../redux/constants/successstoryConstants';

import './successstorynew.css';

const SuccessStoryNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [businessname, setBusinessName] = useState('');
  const [pointofcontact, setPointOfContact] = useState('');
  const [businessaddress, setBusinessAddress] = useState('');
  const [businesscity, setBusinessCity] = useState('');
  const [businessstate, setBusinessState] = useState('');
  const [businesszip, setBusinessZip] = useState('');
  const [businessphone, setBusinessPhone] = useState('');
  const [businessfax, setBusinessFax] = useState('');
  const [businessemail, setBusinessEmail] = useState('');
  const [procurementcontractaward, setProcurementContractAward] =
    useState(false);
  const [procurementcontractissue, setProcurementContractIssue] =
    useState(false);
  const [issuedescription, setIssueDescription] = useState('');
  const [noofjobs, setNoOfJobs] = useState('');
  const [authorizedtopublish, setAuthorizedToPublish] = useState(true);
  const [successstoryattest, setSuccessStoryAttest] = useState(true);
  const [showsuccessstory, setShowSuccessStory] = useState(false);

  const successStoryAdd = useSelector((state) => state.successStoryAdd);
  const { loading, error, successstory } = successStoryAdd;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      addSuccessStory(
        businessname,
        pointofcontact,
        businessaddress,
        businesscity,
        businessstate,
        businesszip,
        businessphone,
        businessfax,
        businessemail,
        procurementcontractaward,
        procurementcontractissue,
        issuedescription,
        noofjobs,
        authorizedtopublish,
        successstoryattest,
        showsuccessstory
      )
    );
  };

  useEffect(() => {
    dispatch({ type: SUCCESSSTORY_ADD_RESET });
    if (successstory) {
      navigate('/success-stories');
    }
  }, [dispatch, navigate, successstory]);

  return (
    <div className='maingrid'>
      <div className='innerbannerimage'>
        <img src='/assets/images/construction01.jpg' alt='About Banner' />
      </div>
      <h2 className='innerpagetitle'>Add your own success story</h2>
      <div className='innerpagecontent'>
        <form className='form' id='addss' onSubmit={submitHandler}>
          <div className='dataitems'>
            <div>
              <label htmlFor='businessname'>
                Business Name <span>*</span>
              </label>
              <input
                type='text'
                id='businessname'
                placeholder='Enter Business Name'
                required
                value={businessname}
                onChange={(e) => setBusinessName(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='pointofcontact'>
                Point of Contact <span>*</span>
              </label>
              <input
                type='text'
                id='pointofcontact'
                placeholder='Enter Point of Contact'
                required
                value={pointofcontact}
                onChange={(e) => setPointOfContact(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='businessaddress'>Business Address</label>
              <CKEditor
                editor={Editor}
                data={businessaddress}
                config={EDITOR_CONFIGURATION}
                onChange={(event, editor) => {
                  const addressdata = editor.getData();
                  setBusinessAddress(addressdata);
                }}
              />
            </div>

            <div>
              <label htmlFor='businesscity'>
                Business City <span>*</span>
              </label>
              <input
                type='text'
                id='businesscity'
                placeholder='Business City'
                required
                value={businesscity}
                onChange={(e) => setBusinessCity(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='businessstate'>
                Business State <span>*</span>
              </label>
              <input
                type='text'
                id='businessstate'
                placeholder='Business State'
                required
                value={businessstate}
                onChange={(e) => setBusinessState(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='businesszip'>
                Business Zip <span>*</span>
              </label>
              <input
                type='text'
                id='businesszip'
                placeholder='Business Zip'
                required
                value={businesszip}
                onChange={(e) => setBusinessZip(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='businessphone'>
                Business Phone <span>*</span>
              </label>
              <input
                type='text'
                id='businessphone'
                placeholder='Business Phone'
                required
                value={businessphone}
                onChange={(e) => setBusinessPhone(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='businessfax'>Business Fax</label>
              <input
                type='text'
                id='businessfax'
                placeholder='Business Fax'
                value={businessfax}
                onChange={(e) => setBusinessFax(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='businessemail'>
                Business Email <span>*</span>
              </label>
              <input
                type='text'
                id='businessemail'
                placeholder='Business Email'
                required
                value={businessemail}
                onChange={(e) => setBusinessEmail(e.target.value)}
              ></input>
            </div>

            <div className='clause'>
              <h4>
                Please indicate the basis of the success story and provide the
                applicable information:
              </h4>
            </div>

            <div>
              <label htmlFor='procurementcontractaward'>
                1. Based on procurement/contract award? Check box for Yes
              </label>
              <input
                type='checkbox'
                id='procurementcontractaward'
                checked={procurementcontractaward}
                onChange={(e) => setProcurementContractAward(e.target.checked)}
              />
            </div>

            <div>
              <label htmlFor='procurementcontractissue'>
                2. Based on procurement/contract issue? Check box for Yes
              </label>
              <input
                type='checkbox'
                id='procurementcontractissue'
                checked={procurementcontractissue}
                onChange={(e) => setProcurementContractIssue(e.target.checked)}
              />
            </div>

            <div>
              <label htmlFor='issuedescription'>
                Please describe the issue(s) the APEX assisted your company in
                resolving.
              </label>
              <CKEditor
                editor={Editor}
                data={issuedescription}
                config={EDITOR_CONFIGURATION}
                onChange={(event, editor) => {
                  const issueDescription = editor.getData();
                  setIssueDescription(issueDescription);
                }}
              />
            </div>

            <div>
              <label htmlFor='noofjobs'>
                3. How many jobs were generated or retained as results of the
                APEX's Assistance"
              </label>
              <input
                type='number'
                id='noofjobs'
                placeholder='No. of jobs due to APEX Assistance'
                value={noofjobs}
                onChange={(e) => setNoOfJobs(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor='authorizedtopublish'>
                4.(i) The APEX is authorized to publish the content of the
                success on its website and /or other literature. Remove check to
                withdraw permission to publish
              </label>
              <input
                type='checkbox'
                id='authorizedtopublish'
                checked={authorizedtopublish}
                onChange={(e) => setAuthorizedToPublish(e.target.checked)}
              />
            </div>

            <div>
              <label htmlFor='successstoryattest'>
                4.(ii) I attest that the success story is true and that the
                success resulted from substantial effort from the APEX. Remove
                check to withdraw attestation.
              </label>
              <input
                type='checkbox'
                id='successstoryattest'
                checked={successstoryattest}
                onChange={(e) => setSuccessStoryAttest(e.target.checked)}
              />
            </div>
          </div>
          <div>
            <label />
            <button className='submitform' type='submit'>
              Submit Success Story
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SuccessStoryNew;
