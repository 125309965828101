import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getFeedbackDetails,
  updateFeedback,
} from '../../../redux/actions/customerfeedbackActions';
import { FEEDBACK_UPDATE_RESET } from '../../../redux/constants/customerfeedbackConstants';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

import './customerfeedbackdetails.css';

const CustomerFeedbackDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const feedbackId = params.id;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const [archive, setArchive] = useState(false);

  const feedbackDetails = useSelector((state) => state.feedbackDetails);
  const { loading, error, feedback } = feedbackDetails;

  const feedbackUpdate = useSelector((state) => state.feedbackUpdate);
  const {
    // loading: loadingUpdate,
    // error: errorUpdate,
    success: successUpdate,
  } = feedbackUpdate;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateFeedback({ _id: feedbackId, archive }));
  };

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: FEEDBACK_UPDATE_RESET });
      feedback._id = 0;
      navigate('/admin/customer-feedback/new');
    } else {
      if (feedback._id !== feedbackId) {
        dispatch(getFeedbackDetails(feedbackId));
      } else {
        setArchive(feedback.archive);
      }
    }
  }, [dispatch, navigate, feedbackId, feedback, successUpdate]);

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>
            View Feedback Details / Edit Archive Status
          </h2>
          {/* <Link to='/admin/news/add'>
            <button>
              <i className='fa fa-plus'></i>Back to Feedback List
            </button>
          </Link> */}
        </div>
        <div className='feedbackcontainer'>
          <div className='feedbacktitle'>Business Name</div>
          <div className='feedbackdetail'>{feedback.businessname}</div>
          <div className='feedbacktitle'>Contact Name</div>
          <div className='feedbackdetail'>{feedback.pointofcontact}</div>
          {feedback.businessaddress ? (
            <>
              <div className='feedbacktitle'>Contact Address</div>
              <div
                className='feedbackdetail'
                dangerouslySetInnerHTML={{
                  __html: feedback.businessaddress,
                }}
              ></div>
            </>
          ) : null}

          {feedback.businesscity ? (
            <>
              <div className='feedbacktitle'>City</div>
              <div
                className='feedbackdetail'
                dangerouslySetInnerHTML={{
                  __html: feedback.businesscity,
                }}
              ></div>
            </>
          ) : null}

          {feedback.businessstate ? (
            <>
              <div className='feedbacktitle'>State</div>
              <div
                className='feedbackdetail'
                dangerouslySetInnerHTML={{
                  __html: feedback.businessstate,
                }}
              ></div>
            </>
          ) : null}

          {feedback.businesszip ? (
            <>
              <div className='feedbacktitle'>Zip Code</div>
              <div
                className='feedbackdetail'
                dangerouslySetInnerHTML={{
                  __html: feedback.businesszip,
                }}
              ></div>
            </>
          ) : null}

          <div className='feedbacktitle'>Business Phone</div>
          <div className='feedbackdetail'>{feedback.businessphone}</div>

          {feedback.businessfax ? (
            <>
              <div className='feedbacktitle'>Fax</div>
              <div
                className='feedbackdetail'
                dangerouslySetInnerHTML={{
                  __html: feedback.businessfax,
                }}
              ></div>
            </>
          ) : null}

          <div className='feedbacktitle'>Email</div>
          <div className='feedbackdetail'>{feedback.businessemail}</div>

          <div className='feedbacktitle'>
            1. The APEX services assisted my business in indentifying and
            accessing new government and commercial contracting opportunities.
          </div>
          <div className='feedbackdetail'>{feedback.mptapassisted}</div>

          <div className='feedbacktitle'>
            2. The APEX counselors/advisors are responsive and flexible in
            meeting my company's needs.
          </div>
          <div className='feedbackdetail'>
            {feedback.mptapcounsellorsresponsive}
          </div>

          <div className='feedbacktitle'>
            3. The APEX counselors/advisers are available when my company needs
            them.
          </div>
          <div className='feedbackdetail'>
            {feedback.mptapcounsellorsavailable}
          </div>

          {feedback.mptapcomments ? (
            <>
              <div className='feedbacktitle'>
                Comments on how we may improve our customer service..
              </div>
              <div
                className='feedbackdetail'
                dangerouslySetInnerHTML={{
                  __html: feedback.mptapcomments,
                }}
              ></div>
            </>
          ) : null}

          <div className='feedbacktitle'>
            1. The APEX website is a valuable "One-stop" resource for
            procurement and small business information.
          </div>
          <div className='feedbackdetail'>{feedback.websitevaluable}</div>

          <div className='feedbacktitle'>
            2. The APEX website is logically designed and easy to navigate.
          </div>
          <div className='feedbackdetail'>{feedback.websitelogical}</div>

          <div className='feedbacktitle'>
            3. The APEX website is available when my business needs it.
          </div>
          <div className='feedbackdetail'>{feedback.websiteavailable}</div>

          {feedback.websitecomments ? (
            <>
              <div className='feedbacktitle'>
                What additional information or features should be added to the
                website?
              </div>
              <div
                className='feedbackdetail'
                dangerouslySetInnerHTML={{
                  __html: feedback.websitecomments,
                }}
              ></div>
            </>
          ) : null}

          {feedback.seminarinstrumental ? (
            <>
              <div className='feedbacktitle'>
                1. The seminars/workshops offered by the APEX have been
                instrumental in increasing the knowledge and/or productivity of
                my company.
              </div>
              <div className='feedbackdetail'>
                {feedback.seminarinstrumental}
              </div>
            </>
          ) : null}

          {feedback.seminargoodtiming ? (
            <>
              <div className='feedbacktitle'>
                2. The seminars and workshops offered by the APEX are scheduled
                at a reasonable time and/or location.
              </div>
              <div className='feedbackdetail'>{feedback.seminargoodtiming}</div>
            </>
          ) : null}

          {feedback.seminarcomments ? (
            <>
              <div className='feedbacktitle'>
                What seminar and/or workshop topic do you suggest for future
                training sessions?
              </div>
              <div
                className='feedbackdetail'
                dangerouslySetInnerHTML={{
                  __html: feedback.seminarcomments,
                }}
              ></div>
            </>
          ) : null}

          {feedback.mptapbidmatchinguseful ? (
            <>
              <div className='feedbacktitle'>
                1. The APEX Bid Management Program notifies my company of
                procurement opportunities that are useful to my company
              </div>
              <div className='feedbackdetail'>
                {feedback.mptapbidmatchinguseful}
              </div>
            </>
          ) : null}

          {feedback.mptapbidmatchingtailored ? (
            <>
              <div className='feedbacktitle'>
                2. The APEX bid notifications are tailored to my products and
                services.
              </div>
              <div className='feedbackdetail'>
                {feedback.mptapbidmatchingtailored}
              </div>
            </>
          ) : null}

          {feedback.mptapbidmatchinggivetime ? (
            <>
              <div className='feedbacktitle'>
                3. The APEX bid notifications provide sufficient lead-time for
                my company to make a bid/no-bid decision.
              </div>
              <div className='feedbackdetail'>
                {feedback.mptapbidmatchinggivetime}
              </div>
            </>
          ) : null}

          {feedback.mptapbidmatchingcomments ? (
            <>
              <div className='feedbacktitle'>
                How may we improve our bid notification services?
              </div>
              <div
                className='feedbackdetail'
                dangerouslySetInnerHTML={{
                  __html: feedback.mptapbidmatchingcomments,
                }}
              ></div>
            </>
          ) : null}
          <div className='feedbacktitle'>
            How would you rate our services overall?
          </div>
          <div className='feedbackdetail'>{feedback.servicerating}</div>

          {feedback.overallcomments ? (
            <>
              <div className='feedbacktitle'>
                Please provide any additional comments on the quality of our
                services.
              </div>
              <div
                className='feedbackdetail'
                dangerouslySetInnerHTML={{
                  __html: feedback.overallcomments,
                }}
              ></div>
            </>
          ) : null}

          <form className='form' id='addss' onSubmit={submitHandler}>
            <div className='dataitems'>
              <div>
                <label htmlFor='togglearchivesttus'>
                  Toggle Archive Status
                </label>

                <input
                  type='checkbox'
                  name='togglearchivesttus'
                  checked={archive}
                  onChange={(e) => setArchive(e.target.checked)}
                />
              </div>

              <div>
                <label />
                <button className='submitform' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerFeedbackDetails;
