import AdContainer from '../../components/adcontainer/AdContainer';
import ResetPassword from '../../components/users/ResetPassword';

const ResetPasswordScreen = () => {
  return (
    <AdContainer>
      <ResetPassword />
    </AdContainer>
  );
};

export default ResetPasswordScreen;
