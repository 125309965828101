import {
  CNEWS_ADD_REQUEST,
  CNEWS_ADD_SUCCESS,
  CNEWS_ADD_FAIL,
  CNEWS_ADD_RESET,
  CNEWS_LIST_REQUEST,
  CNEWS_LIST_SUCCESS,
  CNEWS_LIST_FAIL,
  CNEWS_LIST_RESET,
  CNEWS_DETAILS_REQUEST,
  CNEWS_DETAILS_SUCCESS,
  CNEWS_DETAILS_FAIL,
  CNEWS_DETAILS_RESET,
  CNEWS_UPDATE_REQUEST,
  CNEWS_UPDATE_SUCCESS,
  CNEWS_UPDATE_FAIL,
  CNEWS_UPDATE_RESET,
  CNEWS_DELETE_REQUEST,
  CNEWS_DELETE_SUCCESS,
  CNEWS_DELETE_FAIL,
} from '../constants/contractingnewsConstants';

export const cnewsAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CNEWS_ADD_REQUEST:
      return { loading: true };
    case CNEWS_ADD_SUCCESS:
      return { loading: false, newCNews: action.payload };
    case CNEWS_ADD_FAIL:
      return { loading: false, error: action.payload };
    case CNEWS_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const cnewsListReducer = (state = { cnews: [] }, action) => {
  switch (action.type) {
    case CNEWS_LIST_REQUEST:
      return { loading: true };
    case CNEWS_LIST_SUCCESS:
      return { loading: false, cnews: action.payload };
    case CNEWS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CNEWS_LIST_RESET:
      return { cnews: [] };
    default:
      return state;
  }
};

export const cnewsDetailsReducer = (state = { cnews: {} }, action) => {
  switch (action.type) {
    case CNEWS_DETAILS_REQUEST:
      return { ...state, loading: true };
    case CNEWS_DETAILS_SUCCESS:
      return { loading: false, cnews: action.payload };
    case CNEWS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CNEWS_DETAILS_RESET:
      return { cnews: {} };
    default:
      return state;
  }
};

export const cnewsUpdateReducer = (state = { cnews: {} }, action) => {
  switch (action.type) {
    case CNEWS_UPDATE_REQUEST:
      return { loading: true };
    case CNEWS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case CNEWS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CNEWS_UPDATE_RESET:
      return { cnews: {} };
    default:
      return state;
  }
};

export const cnewsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CNEWS_DELETE_REQUEST:
      return { loading: true };
    case CNEWS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CNEWS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
