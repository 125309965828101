import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { listNewFeedbacks } from '../../../redux/actions/customerfeedbackActions';

import { logout } from '../../../redux/actions/userActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const CustomerFeedbackList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo || !userInfo.isAdmin) {
    dispatch(logout());
  }

  const newFeedbackList = useSelector((state) => state.newFeedbackList);
  const { loading, error, newFeedbacks } = newFeedbackList;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listNewFeedbacks());
    } else {
      navigate('/admin');
    }
  }, [dispatch, navigate, userInfo]);
  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>New Customer Feedback</h2>
          <Link to='/admin/customer-feedback/archived'>
            <button>
              <i className='fa fa-plus'></i> Archived Feedbacks
            </button>
          </Link>
        </div>
        <div className='screenlist'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>Business Name</th>
                  <th>Contact Person</th>
                  <th>Phone No</th>
                  <th>Email</th>
                  <th>See Details</th>
                </tr>
              </thead>
              <tbody>
                {newFeedbacks.map((newFeedback) => (
                  <tr key={newFeedback._id}>
                    <td>{newFeedback.businessname}</td>
                    <td>{newFeedback.pointofcontact}</td>
                    <td>{newFeedback.businessphone}</td>
                    <td>{newFeedback.businessemail}</td>
                    <td>
                      <Link
                        to={`/admin/customer-feedback/${newFeedback._id}/feedback-details`}
                      >
                        <button className='small'>
                          <i className='fa fa-edit'></i>
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerFeedbackList;
