import {
  CP_DATA_REQUEST,
  CP_DATA_SUCCESS,
  CP_DATA_FAIL,
  CP_DETAILS_REQUEST,
  CP_DETAILS_SUCCESS,
  CP_DETAILS_FAIL,
  CP_DETAILS_RESET,
  CP_UPDATE_REQUEST,
  CP_UPDATE_SUCCESS,
  CP_UPDATE_FAIL,
  CP_UPDATE_RESET,
} from '../constants/collabrativepartnersConstants';

export const cpGetReducer = (state = { cpData: {} }, action) => {
  switch (action.type) {
    case CP_DATA_REQUEST:
      return { loading: true };
    case CP_DATA_SUCCESS:
      return { loading: false, cpData: action.payload };
    case CP_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const cpUpdateReducer = (state = { cp: {} }, action) => {
  switch (action.type) {
    case CP_UPDATE_REQUEST:
      return { loading: true };
    case CP_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case CP_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CP_UPDATE_RESET:
      return {
        cp: {},
      };
    default:
      return state;
  }
};
