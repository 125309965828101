import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { listOffices } from '../../../redux/actions/officeActions';

import Loader from '../../../helpers/loader/Loader';
import Message from '../../../helpers/message/Message';

const OfficesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const officeList = useSelector((state) => state.officeList);
  const { loading, error, offices } = officeList;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOffices());
    } else {
      navigate('/admin');
    }
  }, [dispatch, navigate, userInfo]);

  let sno = 0;

  return (
    <div className='maingrid listscreen'>
      <div className='innerpagecontent'>
        <div className='admin-heading-bar'>
          <h2 className='adminpagetitle'>Offices List</h2>
        </div>
        <div className='screenlist'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Office Code</th>
                  <th>Office Name</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {offices.map((office) => (
                  <tr key={office._id}>
                    <td>{(sno += 1)}</td>
                    <td>{office.officecode}</td>
                    <td>{office.officename}</td>
                    {/* <td
                  dangerouslySetInnerHTML={{ __html: office.pagecontent }}
                ></td> */}
                    <td>
                      <Link to={`/admin/offices/${office._id}/edit`}>
                        <button className='small'>
                          <i className='fa fa-edit'></i>
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfficesList;
