import {
  HOMEPAGE_DATA_REQUEST,
  HOMEPAGE_DATA_SUCCESS,
  HOMEPAGE_DATA_FAIL,
} from '../constants/homepageConstants';

export const homepageGetReducer = (state = { homepageData: {} }, action) => {
  switch (action.type) {
    case HOMEPAGE_DATA_REQUEST:
      return { loading: true };
    case HOMEPAGE_DATA_SUCCESS:
      return { loading: false, homepageData: action.payload };
    case HOMEPAGE_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
