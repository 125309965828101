import News from '../components/news/News';

import FeContainer from '../components/fecontainer/FeContainer';

const NewsPage = () => {
  return (
    <FeContainer>
      <News />
    </FeContainer>
  );
};

export default NewsPage;
