import './samgov.css';

const SamGov = () => {
  return (
    <div className='maingrid'>
      <div className='samgov'>
        <div className='samgovlogo'>
          <a href='https://sam.gov' target='_blank' rel='noreferrer'>
            <img src='assets/images/samgovlogo.png' alt='' />
          </a>
        </div>
        <div className='havequestion'>
          <h3>Have Questions about SAM.gov? Contact a Procurement Advisor.</h3>
        </div>
      </div>
    </div>
  );
};

export default SamGov;
