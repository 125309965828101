import AdContainer from '../../components/adcontainer/AdContainer';
import NewPassword from '../../components/users/NewPassword';

const NewPasswordScreen = () => {
  return (
    <AdContainer>
      <NewPassword />
    </AdContainer>
  );
};

export default NewPasswordScreen;
